import { DeepReadonly, Nullish, tryNormalizeNullableUuid } from "my-util"

import AbstractNotification,
       { AbstractNotificationOptionsBase,
         AbstractNotificationCopyOptions,
         AbstractNotificationCreationOptions } from "./AbstractNotification"

import NotificationType,
       { NewInviteApplicationNotificationType,
         NEW_INVITE_APPLICATION_NOTIFICATION_TYPE } from "./NotificationType"

export interface NewInviteApplicationNotificationOptionsBase extends AbstractNotificationOptionsBase {
    inviteApplicationId?: string | null
}

export interface NewInviteApplicationNotificationCreationOptions
    extends
        Omit<AbstractNotificationCreationOptions<NotificationType>, "type">,
        NewInviteApplicationNotificationOptionsBase
{}

export interface NewInviteApplicationNotificationCopyOptions
    extends
        Omit<AbstractNotificationCopyOptions, "type">,
        Nullish<NewInviteApplicationNotificationOptionsBase>
{}

export default class NewInviteApplicationNotification extends AbstractNotification<
    NewInviteApplicationNotificationType,
    NewInviteApplicationNotificationCopyOptions
> {
    static createOrPass(
        arg?: NewInviteApplicationNotification
            | DeepReadonly<NewInviteApplicationNotificationCreationOptions>
            | null
    ): NewInviteApplicationNotification {
        return arg instanceof NewInviteApplicationNotification
            ? arg
            : new NewInviteApplicationNotification(arg ?? {})
    }

    // Fields

    readonly inviteApplicationId: string | null

    // Constructor

    constructor(options: DeepReadonly<NewInviteApplicationNotificationCreationOptions> = {}) {
        super({ ...options, type: NEW_INVITE_APPLICATION_NOTIFICATION_TYPE })

        this.inviteApplicationId = tryNormalizeNullableUuid(options.inviteApplicationId)
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<NewInviteApplicationNotificationCopyOptions> = {},
    ): NewInviteApplicationNotification {
        return new NewInviteApplicationNotification({
            // Recipient ID

            recipientId: "recipientId" in options
                ? options.recipientId
                : this.recipientId,

            // Content

            inviteApplicationId: "inviteApplicationId" in options
                ? options.inviteApplicationId
                : this.inviteApplicationId,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
