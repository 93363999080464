import { Copyable } from "model"
import { DeepReadonly, Immutable, IsImmutable, map } from "my-util"
import { ViolationType } from "validation"

export interface ProductViolationsOptionsBase {
    name?: ViolationType | null
    cnfeaCodes?: Iterable<ViolationType | undefined | null> | null
}

export interface ProductViolationsCreationOptions extends ProductViolationsOptionsBase {}
export interface ProductViolationsCopyOptions extends ProductViolationsOptionsBase {}

export default class ProductViolations
    extends
        Copyable<ProductViolationsCopyOptions>

    implements
        Immutable
{
    static createOrPass(
        arg?: ProductViolations
            | DeepReadonly<ProductViolationsCreationOptions>
            | null,
    ): ProductViolations {
        return arg instanceof ProductViolations
            ? arg
            : new ProductViolations(arg ?? {})
    }

    // Fields

    readonly [IsImmutable] = true

    readonly name: ViolationType | null
    readonly cnfeaCodes: readonly (ViolationType | null)[]

    private cachedAreCnfeaCodesValid: boolean | null = null

    // Constructor

    constructor(options: DeepReadonly<ProductViolationsCreationOptions> = {}) {
        super()

        this.name = options.name ?? null
        this.cnfeaCodes = map(options.cnfeaCodes ?? [], code => code ?? null)
    }

    // Validity check

    get isNameValid(): boolean {
        return this.name == null
    }

    get areCnfeaCodesValid(): boolean {
        if (this.cachedAreCnfeaCodesValid == null)
            this.cachedAreCnfeaCodesValid = this.cnfeaCodes.every(code => code == null)

        return this.cachedAreCnfeaCodesValid
    }

    get isValid(): boolean {
        return this.isNameValid && this.areCnfeaCodesValid
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<ProductViolationsCopyOptions> = {},
    ): ProductViolations {
        return new ProductViolations({
            name: "name" in options
                ? options.name
                : this.name,

            cnfeaCodes: options.cnfeaCodes ?? this.cnfeaCodes,
        })
    }
}
