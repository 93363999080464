export function getOrComputeMapItem<K, V>(
    map: Map<K, V>,
    key: K,
    compute: (key: K) => V,
): V {
    let value = map.get(key)

    if (value == null)
        map.set(key, value = compute(key))

    return value
}

export function mergedMaps<K, V>(...maps: ReadonlyMap<K, V>[]): Map<K, V> {
    const merged = new Map<K, V>()

    for (const map of maps)
        for (const [key, value] of map.entries())
            merged.set(key, value)

    return merged
}

export function mapWithSet<K, V>(map: ReadonlyMap<K, V>, key: K, value: V): Map<K, V> {
    const withSet = new Map(map)

    withSet.set(key, value)

    return withSet
}
