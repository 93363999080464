import { ForwardedRef, forwardRef } from "react"
import { getTransferGroupColors, getTransferGroupName, TransferGroup  } from "model"
import ColorLegend from "ui/ui/ColorLegend"

export interface TransferGroupColorLegendProps {
    onClick?: (group: TransferGroup) => void
    group: TransferGroup

    showLegend?: boolean
}

const TransferGroupColorLegend = forwardRef((
    {
        onClick, group,
        showLegend,
    }: Readonly<TransferGroupColorLegendProps>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    const innerOnClick = onClick != null
        ? () => onClick(group)
        : undefined

    const legend = showLegend
        ? getTransferGroupName(group)
        : undefined

    const { color } = getTransferGroupColors(group)

    return <ColorLegend onClick={innerOnClick}
                        legend={legend}
                        color={color}
                        ref={ref}/>
})

TransferGroupColorLegend.displayName = "TransferGroupColorLegend"

export default TransferGroupColorLegend
