import Decimal from "decimal.js"

import AbstractModelObject,
       { AbstractModelObjectCopyOptions,
         AbstractModelObjectCreationOptions } from "model/AbstractModelObject"

import { createOrPassDecimal, DeepReadonly, tryNormalizeNullableUuid } from "my-util"

export interface TransferProviderOptionsBase {
    userId?: string | null
    providerId?: string | null

    percent?: Decimal.Value | null
}

export interface TransferProviderCreationOptions
    extends
        AbstractModelObjectCreationOptions,
        TransferProviderOptionsBase
{}

export interface TransferProviderCopyOptions
    extends
        AbstractModelObjectCopyOptions,
        TransferProviderOptionsBase
{}

export default class TransferProvider extends AbstractModelObject<TransferProviderCopyOptions> {
    static createOrPass(
        arg?: TransferProvider
            | DeepReadonly<TransferProviderCreationOptions>
            | null,
    ): TransferProvider {
        return arg instanceof TransferProvider
            ? arg
            : new TransferProvider(arg ?? {})
    }

    // Fields

    // - Targets

    readonly userId: string | null
    readonly providerId: string | null

    // - Percent

    readonly percent: Decimal

    // Constructor

    constructor(options: DeepReadonly<TransferProviderCreationOptions> = {}) {
        super(options)

        // Targets

        this.userId = tryNormalizeNullableUuid(options.userId)
        this.providerId = tryNormalizeNullableUuid(options.providerId)

        // Percent

        this.percent = createOrPassDecimal(options.percent)
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<TransferProviderCopyOptions> = {},
    ): TransferProvider {
        return new TransferProvider({
            // Targets

            userId: "userId" in options
                ? options.userId
                : this.userId,

            providerId: "providerId" in options
                ? options.providerId
                : this.providerId,

            // Percent

            percent: options.percent ?? this.percent,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
