import assert from "assert"
import { ReactNode, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { z } from "zod"

import { SUCCESS_MFA_RESPONSE_STATUS,
         JsonPatch, deleteUserById, VerificationNeededMfaResponse,
         UserUpdateResponseSchema, UserUpdateResponse, patchUserById } from "api"

import { ACTIVE_USER_STATUS, BLOCKED_USER_STATUS,
         MANAGER_USER_ROLE, UNVERIFIED_USER_STATUS, User, UserStatus } from "model"

import { EN_NAME_INPUT_REGEX, RU_NAME_INPUT_REGEX,
         trimToNull, capitalizedText, isNullOrBlank, dateToDateTimeString } from "my-util"

import { normalizeEmail, normalizePhone } from "normalize"
import { validateEmail, validateEnName, validatePhone, validateRuName } from "validation"

import { UserContext } from "ui/context"
import { useStateWithDeps } from "ui/hook"
import { MAIN_PAGE_PATH } from "ui/page/sections/MainPage/path"
import { MfaModal } from "ui/component"

import { FormControls, Limit, Form, EmailInput, Output,
         DiLangOutput, ActionModal, UserSpecializationOutput,
         FormControlsButton, UserRoleSelect, PhoneInput, Flex,
         PhoneOutput, EmailOutput, UserRoleOutput, DiLangInput,
         UserSpecializationSelect} from "ui/ui"

const MAX_WIDTH = "400px"

export interface BasicInfoTabProps {
    onUserChange?: (user: User) => void
    user: User

    mobile?: boolean
}

export default function BasicInfoTab(
    {
        onUserChange, user,
        mobile,
    }: Readonly<BasicInfoTabProps>,
) {
    const [t] = useTranslation()

    const navigate = useNavigate()

    const [localUser, setLocalUser] = useContext(UserContext)

    // State

    // - Editing

    const [editing, setEditing] = useState(false)
    const editingSelf = localUser?.id === user.id

    // -- Names

    const [name, setName] = useStateWithDeps(() => ({ en: user.enName, ru: user.ruName }), [user])
    const enNameInvalid = validateEnName(name.en) != null
    const ruNameInvalid = validateRuName(name.ru) != null

    // -- Message targets

    const [phone, setPhone] = useStateWithDeps(() => user.phone, [user])
    const phoneNullOrBlank = isNullOrBlank(phone)
    const phoneInvalid = !phoneNullOrBlank && validatePhone(phone!) != null

    const [email, setEmail] = useStateWithDeps(() => user.email, [user])
    const emailNullOrBlank = isNullOrBlank(email)
    const emailInvalid = !emailNullOrBlank && validateEmail(email!) != null

    // -- Other

    const [role, setRole] = useStateWithDeps(() => user.role, [user])
    const [specialization, setSpecialization] = useStateWithDeps(() => user.specialization, [user])

    // - Loading

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined as unknown)

    // - Modals

    const [showDeletionModal, setShowDeletionModal] = useState(false)
    const [showBlockModal, setShowBlockModal] = useState(false)

    // - MFA

    const [mfaResponse, setMfaResponse] = useState(
        undefined as VerificationNeededMfaResponse | undefined,
    )

    // Rendering

    return <>
        {renderForm()}
        {renderModals()}
    </>

    function renderForm(): ReactNode {
        return <Form loading={loading}
                     height="100%">
            <Flex justify="space-between"
                  align="start"
                  height="100%">
                {editing
                    ? renderFieldInputs()
                    : renderFieldOutputs()
                }

                {renderFormControls()}
            </Flex>
        </Form>
    }

    function renderFieldInputs() {
        return <Flex align="start">
            <DiLangInput onChange={(en, ru) => setName({ en, ru })}
                         enValue={name.en}
                         ruValue={name.ru}

                         postProcess={capitalizedText}

                         label={t("domain.users.labels.name")}
                         placeholder={editingSelf
                            ? t("domain.users.placeholders.your.name")
                            : t("domain.users.placeholders.name")
                         }

                         loading={loading}
                         enInvalid={enNameInvalid}
                         ruInvalid={ruNameInvalid}

                         enRegex={EN_NAME_INPUT_REGEX}
                         ruRegex={RU_NAME_INPUT_REGEX}

                         maxWidth={MAX_WIDTH}/>

            <Limit maxWidth={MAX_WIDTH}>
                <Flex align="start">
                    <PhoneInput onChange={setPhone}
                                value={phone ?? undefined}

                                label={t("messageTargets.labels.phone")}
                                placeholder={editingSelf
                                    ? t("messageTargets.placeholders.your.phone")
                                    : t("messageTargets.placeholders.phone")
                                }

                                loading={loading}
                                invalid={phoneInvalid}/>

                    <EmailInput onChange={setEmail}
                                value={email ?? undefined}

                                label={t("messageTargets.labels.email")}
                                placeholder={editingSelf
                                    ? t("messageTargets.placeholders.your.email")
                                    : t("messageTargets.placeholders.email")
                                }

                                loading={loading}
                                invalid={emailInvalid}/>

                    <UserRoleSelect onSelect={setRole}
                                    selected={role}

                                    label={t("domain.userRights.labels.role")}

                                    readonly={!localUser?.isManager}
                                    loading={loading}

                                    hideAdmin={!localUser?.isAdmin}
                                    hideManager={!localUser?.isManager}
                                    hideAgent={!localUser?.isAgent}/>

                    {role === MANAGER_USER_ROLE &&
                        <UserSpecializationSelect onSelect={setSpecialization}
                                                  selected={specialization}

                                                  label={t("domain.userRights.labels.specialization")}

                                                  readonly={!localUser?.isAdmin}
                                                  loading={loading}/>
                    }
                </Flex>
            </Limit>
        </Flex>
    }

    function renderFieldOutputs() {
        return <Flex align="start">
            <DiLangOutput enValue={name.en}
                          ruValue={name.ru}

                          label={t("domain.users.labels.name")}

                          maxWidth={MAX_WIDTH}/>

            <Limit maxWidth={MAX_WIDTH}>
                <Flex align="start">
                    {phone != null &&
                        <PhoneOutput label={t("messageTargets.labels.phone")}
                                     phone={phone}/>
                    }

                    {email != null &&
                        <EmailOutput label={t("messageTargets.labels.email")}
                                     email={email}/>
                    }

                    <UserRoleOutput label={t("domain.userRights.labels.role")}
                                    role={role}/>

                    {role === MANAGER_USER_ROLE &&
                        <UserSpecializationOutput label={t("domain.userRights.labels.specialization")}
                                                  specialization={specialization}/>
                    }

                    <Output label={t("domain.users.labels.createdAt.moment")}>
                        {dateToDateTimeString(user.createdAt)}
                    </Output>
                </Flex>
            </Limit>

            {(phone == null || email == null) &&
                t("misc.messages.unfilledFieldsHidden")
            }
        </Flex>
    }

    // - Form controls

    function renderFormControls(): ReactNode {
        const saveDisabled =
            enNameInvalid ||
            ruNameInvalid ||

            (phoneNullOrBlank && emailNullOrBlank) ||
            phoneInvalid ||
            emailInvalid

        return <FormControls buttons={renderFormControlsButtons(saveDisabled)}

                             loading={loading}
                             error={error}
                             apiErrorMessageMapping={{
                                 409: t("messageTargets.messages.errors.occupied.allMessageTargets"),
                             }}

                             mobile={mobile}

                             wrap/>
    }

    function renderFormControlsButtons(saveDisabled?: boolean): FormControlsButton[] {
        return editing
            ? renderFormControlsEditingButtons(saveDisabled)
            : renderFormControlsViewingButtons()
    }

    function renderFormControlsEditingButtons(saveDisabled?: boolean): FormControlsButton[] {
        return [
            {
                position: "left",
                text: t("misc.buttons.save"),
                type: "submit",
                disabled: saveDisabled,
                onClick: onSave,
            },

            {
                position: "left",
                text: t("misc.buttons.cancel"),
                buttonStyle: "outline",

                onClick() {
                    setEditing(false)
                    setError(false)

                    resetFields()
                },
            },
        ]
    }

    function renderFormControlsViewingButtons(): FormControlsButton[] {
        assert(user != null)

        const cannotModify = (() => {
            if (user.id === localUser?.id)
                return false

            if (localUser?.isAdmin)
                return false

            if (user.isAdmin)
                return true

            if (localUser?.hasRightToManageUsers)
                return false

            return true
        })()

        return [
            // Left

            {
                hidden: cannotModify,
                position: "left",
                text: t("misc.buttons.edit"),

                onClick() {
                    setEditing(true)
                    setError(false)
                },
            },

            {
                hidden: cannotModify,
                position: "left",
                text: t("misc.buttons.delete"),
                buttonStyle: "outline",
                critical: true,
                onClick() { setShowDeletionModal(true) },
            },

            {
                hidden: cannotModify || user.status !== ACTIVE_USER_STATUS,
                position: "left",
                text: t("misc.buttons.block"),
                buttonStyle: "outline",
                critical: true,
                onClick() { setShowBlockModal(true) },
            },

            {
                hidden: cannotModify || user.status !== UNVERIFIED_USER_STATUS,
                position: "left",
                text: t("misc.buttons.verify"),
                buttonStyle: "outline",
                onClick: onMakeActive,
            },

            {
                hidden: cannotModify || user.status !== BLOCKED_USER_STATUS,
                position: "left",
                text: t("misc.buttons.unblock"),
                buttonStyle: "outline",
                onClick: onMakeActive,
            },

            // Right

            {
                position: "right",
                text: t("misc.buttons.back"),
                buttonStyle: "outline",
                onClick: onBack,
            },
        ]
    }

    // - Modals

    function renderModals(): ReactNode {
        return <>
            {renderMfaModalIfShown()}
            {renderDeletionModalIfShown()}
            {renderBlockModalIfShown()}
        </>
    }

    function renderMfaModalIfShown(): ReactNode {
        if (mfaResponse == null)
            return

        return <MfaModal {...mfaResponse}

                         responseSchema={z.unknown()}

                         onSuccess={onSuccess}
                         onClose={() => setMfaResponse(undefined)}
                         closeOnSuccess

                         key={mfaResponse.messageTarget}/>

        function onSuccess(response: unknown) {
            const parsedResponse = UserUpdateResponseSchema.safeParse(response)

            if (!parsedResponse.success) {
                const { error } = parsedResponse

                if (error)
                    console.error(error)

                setError(t("errors.unspecific"))

                return
            }

            onUserUpdateResponse(parsedResponse.data)
        }
    }

    function renderDeletionModalIfShown(): ReactNode {
        if (!showDeletionModal)
            return null

        return <ActionModal onNo={() => setShowDeletionModal(false)}
                            onYes={onDelete}
                            loading={loading}
                            closeOnSuccess
                            critical>
            {editingSelf
                ? t("domain.users.messages.warnings.selfDeletion")
                : t("domain.users.messages.warnings.deletion")
            }
        </ActionModal>

        async function onDelete() {
            await deleteUserById(user.id)

            if (logoutIfEditingSelf())
                return

            onBack()
        }
    }

    function renderBlockModalIfShown(): ReactNode {
        if (!showBlockModal)
            return null

        return <ActionModal onNo={() => setShowBlockModal(false)}
                            onYes={onBlock}
                            loading={loading}
                            closeOnSuccess
                            critical>
            {editingSelf
                ? t("domain.users.messages.warnings.selfBlock")
                : t("domain.users.messages.warnings.block")
            }
        </ActionModal>

        async function onBlock() {
            await changeStatus(BLOCKED_USER_STATUS)

            if (logoutIfEditingSelf())
                return
        }
    }

    // Events

    async function onSave() {
        setLoading(true)

        try {
            const [enFirstname, enLastname, enPatronymic] = name.en
                .trim()
                .split(/\s+/)

            const [ruFirstname, ruLastname, ruPatronymic] = name.ru
                .trim()
                .split(/\s+/)

            const patches: JsonPatch[] = [
                // Message targets

                {
                    op: "replace",
                    path: "/phone",
                    value: phone != null
                        ? trimToNull(normalizePhone(phone))
                        : null,
                },

                {
                    op: "replace",
                    path: "/email",
                    value: email != null
                        ? trimToNull(normalizeEmail(email))
                        : null,
                },

                // Name

                // - En

                {
                    op: "replace",
                    path: "/enFirstname",
                    value: enFirstname,
                },

                {
                    op: "replace",
                    path: "/enLastname",
                    value: enLastname,
                },

                {
                    op: "replace",
                    path: "/enPatronymic",
                    value: enPatronymic ?? null,
                },

                // // - Ru

                {
                    op: "replace",
                    path: "/ruFirstname",
                    value: ruFirstname,
                },

                {
                    op: "replace",
                    path: "/ruLastname",
                    value: ruLastname,
                },

                {
                    op: "replace",
                    path: "/ruPatronymic",
                    value: ruPatronymic ?? null,
                },

                // - Other

                {
                    op: "replace",
                    path: "/role",
                    value: role,
                },

                {
                    op: "replace",
                    path: "/specialization",
                    value: specialization,
                },
            ]

            const response = await patchUserById(user.id, patches)

            onUserUpdateResponse(response)
            setError(undefined)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    function onUserUpdateResponse(response: UserUpdateResponse) {
        if (response.status !== SUCCESS_MFA_RESPONSE_STATUS) {
            // Opens MFA modal
            setMfaResponse(response)
            return
        }

        const newUser = new User(response.body)

        setEditing(false)
        resetFields(newUser)
        onUserChange?.(newUser)
    }

    async function onMakeActive() {
        setLoading(true)

        try {
            await changeStatus(ACTIVE_USER_STATUS)
            setError(undefined)
        } catch(error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    function onBack() {
        navigate(-1)
    }

    // Util

    async function changeStatus(newStatus: UserStatus) {
        const patches: JsonPatch[] = [
            {
                op: "replace",
                path: "/status",
                value: newStatus,
            },
        ]

        const response = await patchUserById(user.id, patches)

        onUserUpdateResponse(response)
    }

    function logoutIfEditingSelf(): boolean {
        if (!editingSelf)
            return false

        navigate(MAIN_PAGE_PATH)
        setLocalUser(null)

        return true
    }

    function resetFields(argUser: User = user) {
        setName({
            en: argUser.enName,
            ru: argUser.ruName,
        })

        setPhone(argUser.phone)
        setEmail(argUser.email)
        setRole(argUser.role)
        setSpecialization(argUser.specialization)
    }
}
