import { WithCreatorId } from "model/interfaces"

import AbstractModelObject,
       { AbstractModelObjectCopyOptions,
         AbstractModelObjectCreationOptions } from "model/AbstractModelObject"

import { collapseWhiteSpace, DeepReadonly, Nullable,
         Nullish, removeWhiteSpace, tryNormalizeNullableUuid } from "my-util"

export interface DocumentOptionsBase extends Nullish<WithCreatorId> {
    name: string
    mimeType?: string | null
    size?: number | null
    blob?: Blob | null
}

export interface DocumentCreationOptions
    extends
        AbstractModelObjectCreationOptions,
        DocumentOptionsBase
{}

export interface DocumentCopyOptions
    extends
        AbstractModelObjectCopyOptions,
        Nullish<DocumentOptionsBase>
{}

export default class Document
    extends
        AbstractModelObject<DocumentCopyOptions>

    implements
        Readonly<Nullable<WithCreatorId>>
{
    static createOrPass(arg: Document | DeepReadonly<DocumentCreationOptions>): Document {
        return arg instanceof Document
            ? arg
            : new Document(arg)
    }

    // Fields

    // - Creator ID

    readonly creatorId: string | null

    // - File

    readonly name: string
    readonly mimeType: string
    readonly size: number
    readonly blob: Blob | null

    // Constructor

    constructor(options: DeepReadonly<DocumentCreationOptions>) {
        super(options)

        this.creatorId = tryNormalizeNullableUuid(options.creatorId)
        this.name = collapseWhiteSpace(options.name)
        this.mimeType = removeWhiteSpace(options.mimeType ?? "text/plain")
        this.size = options.size ?? 0
        this.blob = options.blob ?? null
    }

    // Copy

    protected override createCopy(options: DeepReadonly<DocumentCopyOptions> = {}): Document {
        return new Document({
            // Creator ID

            creatorId: "creatorId" in options
                ? options.creatorId
                : this.creatorId,

            // File

            name: options.name ?? this.name,
            mimeType: options.mimeType ?? this.mimeType,
            size: options.size ?? this.size,

            blob: "blob" in options
                ? options.blob
                : this.blob,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
