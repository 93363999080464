import { ForwardedRef, forwardRef } from "react"
import { getNullableUserSpecializationName, UserSpecialization } from "model"
import Output, { OutputProps } from "ui/ui/Output"
import { DeepReadonly } from "my-util"

export interface UserSpecializationOutputProps extends Omit<OutputProps, "children"> {
    specialization?: UserSpecialization | null
}

const UserSpecializationOutput = forwardRef((
    props: DeepReadonly<UserSpecializationOutputProps>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <Output { ...props as UserSpecializationOutputProps }
            ref={ref}>
        {getNullableUserSpecializationName(props.specialization)}
    </Output>
)

UserSpecializationOutput.displayName = "UserSpecializationOutput"

export default UserSpecializationOutput
