export function retainMapKeys<K, V>(map: Map<K, V>, keys: Iterable<K>): Map<K, V> {
    const keySet = new Set(keys)
    const keysToDelete: K[] = []

    for (const key of map.keys())
        if (!keySet.has(key))
            keysToDelete.push(key)

    for (const key of keysToDelete)
        map.delete(key)

    return map
}
