import Decimal from "decimal.js"
import { z } from "zod"

const DecimalSchema = z.string().or(z.number()).transform((arg, ctx) => {
    const decimal = new Decimal(arg)

    if (decimal.isNaN()) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "not a valid decimal",
        })

        return z.NEVER
    }

    return decimal
})

export default DecimalSchema

export const DecimalValueSchema = z.never()
    .or(DecimalSchema)
    .or(z.string())
    .or(z.number())
