import ViolationType from "./ViolationType"

export interface ValidateNameOptions {
    name: string
    regex: RegExp
    minPartLength: number
    maxPartLength: number
}

export function validateName(
    {
        name,
        regex,
        minPartLength,
        maxPartLength,
    }: Readonly<ValidateNameOptions>,
): ViolationType | null {
    if (name.match(regex) == null)
        return "illegal-char"

    const allParts = name.trim().split(/\s+/)

    for (const part of allParts) {
        if (part.length > maxPartLength)
            return "too-long"

        if (part.length < minPartLength)
            return "too-short"
    }

    switch (allParts.length) {
        case 2:
        case 3:
            return null

        default:
            return "bad-syntax"
    }
}
