import { arrayOfNonNull, DeepReadonly, Nullish, tryNormalizeNullableUuid } from "my-util"

import AbstractNotification,
       { AbstractNotificationOptionsBase,
         AbstractNotificationCopyOptions,
         AbstractNotificationCreationOptions } from "./AbstractNotification"

import NotificationType,
       { NewProviderNotificationType,
         NEW_PROVIDER_NOTIFICATION_TYPE } from "./NotificationType"

export interface NewProviderNotificationOptionsBase extends AbstractNotificationOptionsBase {
    providerId?: string | null
}

export interface NewProviderNotificationCreationOptions
    extends
        Omit<AbstractNotificationCreationOptions<NotificationType>, "type">,
        NewProviderNotificationOptionsBase
{}

export interface NewProviderNotificationCopyOptions
    extends
        Omit<AbstractNotificationCopyOptions, "type">,
        Nullish<NewProviderNotificationOptionsBase>
{}

export default class NewProviderNotification extends AbstractNotification<
    NewProviderNotificationType,
    NewProviderNotificationCopyOptions
> {
    static createOrPass(
        arg?: NewProviderNotification
            | DeepReadonly<NewProviderNotificationCreationOptions>
            | null,
    ): NewProviderNotification {
        return arg instanceof NewProviderNotification
            ? arg
            : new NewProviderNotification(arg ?? {})
    }

    // Fields

    readonly providerId: string | null

    // Constructor

    constructor(options: DeepReadonly<NewProviderNotificationCreationOptions> = {}) {
        super({ ...options, type: NEW_PROVIDER_NOTIFICATION_TYPE })

        this.providerId = tryNormalizeNullableUuid(options.providerId)
    }

    // User IDs

    override get userIds(): string[] {
        return arrayOfNonNull(this.recipientId, this.providerId)
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<NewProviderNotificationCopyOptions> = {},
    ): NewProviderNotification {
        return new NewProviderNotification({
            // Recipient ID

            recipientId: "recipientId" in options
                ? options.recipientId
                : this.recipientId,

            // Content

            providerId: "providerId" in options
                ? options.providerId
                : this.providerId,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
