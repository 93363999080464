import Copyable from "model/Copyable"

import { DeepReadonly, Immutable, IsImmutable,
         Nullish, ReadonlyDate, removeWhiteSpace } from "my-util"

export interface LegalDocumentCreationOptions {
    number: string
    date: Date
    documentId?: string | null
}

export interface LegalDocumentCopyOptions extends Nullish<LegalDocumentCreationOptions> {}

export default class LegalDocument
    extends
        Copyable<LegalDocumentCopyOptions>

    implements
        Immutable
{
    static createOrPass(
        arg: LegalDocument | DeepReadonly<LegalDocumentCreationOptions>,
    ): LegalDocument {
            return arg instanceof LegalDocument
                ? arg
                : new LegalDocument(arg)
        }

    // Fields

    readonly [IsImmutable] = true

    readonly number: string
    readonly date: ReadonlyDate
    readonly documentId: string | null

    // Constructor

    constructor(options: DeepReadonly<LegalDocumentCreationOptions>) {
        super()

        this.number = removeWhiteSpace(options.number)
        this.date = new Date(options.date.getTime())
        this.documentId = options.documentId ?? null
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<LegalDocumentCopyOptions> = {}
    ): LegalDocument {
        return new LegalDocument({
            number: options.number ?? this.number,
            date: options.date ?? this.date,

            documentId: options.documentId !== undefined
                ? options.documentId
                : this.documentId,
        })
    }
}
