import { AnonymousUser, AnonymousUserCopyOptions, AnonymousUserCreationOptions } from "model/domain"

import { DeepReadonly, Nullish } from "my-util"

import AbstractNotification,
       { AbstractNotificationOptionsBase,
         AbstractNotificationCopyOptions,
         AbstractNotificationCreationOptions } from "./AbstractNotification"

import NotificationType,
       { NewChatMessagesNotificationType,
         NEW_CHAT_MESSAGES_NOTIFICATION_TYPE } from "./NotificationType"

export interface NewAnonymousChatMessagesNotificationOptionsBase
    extends
        AbstractNotificationOptionsBase
{
    count?: number | null
}

export interface NewAnonymousChatMessagesNotificationCreationOptions
    extends
        Omit<AbstractNotificationCreationOptions<NotificationType>, "type">,
        NewAnonymousChatMessagesNotificationOptionsBase
{
    from?: AnonymousUser | AnonymousUserCreationOptions | null
    count?: number | null
}

export interface NewAnonymousChatMessagesNotificationCopyOptions
    extends
        Omit<AbstractNotificationCopyOptions, "type">,
        Nullish<NewAnonymousChatMessagesNotificationOptionsBase>
{
    from?: AnonymousUser | AnonymousUserCopyOptions | null
}

export default class NewAnonymousChatMessagesNotification extends AbstractNotification<
    NewChatMessagesNotificationType,
    NewAnonymousChatMessagesNotificationCopyOptions
> {
    static createOrPass(
        arg?: NewAnonymousChatMessagesNotification
            | DeepReadonly<NewAnonymousChatMessagesNotificationCreationOptions>
            | null
    ): NewAnonymousChatMessagesNotification {
        return arg instanceof NewAnonymousChatMessagesNotification
            ? arg
            : new NewAnonymousChatMessagesNotification(arg ?? {})
    }

    // Fields

    readonly from: AnonymousUser | null
    readonly count: number

    // Constructor

    constructor(options: DeepReadonly<NewAnonymousChatMessagesNotificationCreationOptions> = {}) {
        super({ ...options, type: NEW_CHAT_MESSAGES_NOTIFICATION_TYPE })

        this.from = options.from != null
            ? AnonymousUser.createOrPass(options.from)
            : null

        this.count = options.count ?? 1
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<NewAnonymousChatMessagesNotificationCopyOptions> = {},
    ): NewAnonymousChatMessagesNotification {
        return new NewAnonymousChatMessagesNotification({
            // Recipient ID

            recipientId: "recipientId" in options
                ? options.recipientId
                : this.recipientId,

            // Content

            from: AnonymousUser.copyOrPass(this.from, options.from),
            count: options.count ?? this.count,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
