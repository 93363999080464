import AbstractModelObject,
       { AbstractModelObjectCopyOptions,
         AbstractModelObjectCreationOptions } from "model/AbstractModelObject"
import Copyable from "model/Copyable"
import { DeepReadonly, tryNormalizeNullableUuid } from "my-util"

export interface SignableDocumentOptionsBase {
    initialId?: string | null
    signedId?: string | null
    locked?: boolean | null
}

export interface SignableDocumentCreationOptions
    extends
        AbstractModelObjectCreationOptions,
        SignableDocumentOptionsBase
{}

export interface SignableDocumentCopyOptions
    extends
        AbstractModelObjectCopyOptions,
        SignableDocumentOptionsBase
{}

export default class SignableDocument extends AbstractModelObject<SignableDocumentCopyOptions> {
    static createOrPass(
        arg?: SignableDocument
            | DeepReadonly<SignableDocumentCreationOptions>
            | null,
    ): SignableDocument {
        return arg instanceof SignableDocument
            ? arg
            : new SignableDocument(arg ?? {})
    }

    // Fields

    readonly initialId: string | null
    readonly signedId: string | null
    readonly locked: boolean

    // Constructor

    constructor(options: DeepReadonly<SignableDocumentCreationOptions> = {}) {
        super(options)

        this.initialId = tryNormalizeNullableUuid(options.initialId)
        this.signedId = tryNormalizeNullableUuid(options.signedId)
        this.locked = options.locked ?? false
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<SignableDocumentCopyOptions> = {},
    ): Copyable<SignableDocumentCopyOptions> {
        return new SignableDocument({
            initialId: "initialId" in options
                ? options.initialId
                : this.initialId,

            signedId: "signedId" in options
                ? options.signedId
                : this.signedId,

            locked: options.locked ?? this.locked,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
