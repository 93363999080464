import { get, del, countEntities, patchEntity,
         isEntityExists, tryDeleteEntity, put, post } from "api/http/util"

import { IdsRequest, JsonPatch, UserRequest } from "api/request"
import { UserResponseSchema, UserUpdateResponse, UserUpdateResponseSchema } from "api/response"
import { User, UserRole } from "model"
import { DeepReadonly, joinSubpaths } from "my-util"

export const USERS_SUBPATH = "/users"

// Count

export async function countUsers(signal?: AbortSignal | null): Promise<number> {
    return countEntities(`${USERS_SUBPATH}/count`, signal)
}

export async function countByRole(role: UserRole, signal?: AbortSignal | null): Promise<number> {
    return countEntities(`${USERS_SUBPATH}/${role}`, signal)
}

export async function countMyUsers(signal?: AbortSignal | null): Promise<number> {
    return countEntities(`${USERS_SUBPATH}/my`, signal)
}

export async function countEffectiveAgents(signal?: AbortSignal | null): Promise<number> {
    return countEntities(`${USERS_SUBPATH}/effective-agents`, signal)
}

// Exists

export async function isUserExistsById(id: string, signal?: AbortSignal | null): Promise<boolean> {
    return isEntityExists(`${USERS_SUBPATH}/${id}/exists`, signal)
}

// Get all

export async function getAllUsers(signal?: AbortSignal | null): Promise<User[]> {
    return getUserArray([], signal)
}

export async function getAllUsersByRole(role: UserRole, signal?: AbortSignal | null): Promise<User[]> {
    return getUserArray([role], signal)
}

export async function getAllMyUsers(signal?: AbortSignal | null): Promise<User[]> {
    return getUserArray(["my"], signal)
}

export async function getAllEffectiveAgents(signal?: AbortSignal | null): Promise<User[]> {
    return getUserArray(["effective-agents"], signal)
}

async function getUserArray(subpaths: string[], signal?: AbortSignal | null): Promise<User[]> {
    return (await get({
        subpath: joinSubpaths(USERS_SUBPATH, ...subpaths),
        schema: UserResponseSchema.array(),
        signal,
    })).map(response => new User(response))
}

// Bulk get

export async function getAllUsersByIds(
    request: DeepReadonly<IdsRequest>,
    signal?: AbortSignal | null,
): Promise<User[]> {
    return (await post({
        subpath: `${USERS_SUBPATH}/bulk-get`,
        schema: UserResponseSchema.array(),
        body: request,
        signal,
    })).map(response => new User(response))
}

// Get one

export async function getUserById(id: string, signal?: AbortSignal | null): Promise<User> {
    return new User(await get({
        subpath: `${USERS_SUBPATH}/${id}`,
        schema: UserResponseSchema,
        signal,
    }))
}

export async function getMe(signal?: AbortSignal | null): Promise<User> {
    return new User(await get({
        subpath: `${USERS_SUBPATH}/me`,
        schema: UserResponseSchema,
        signal,
    }))
}

// Delete all

export async function clearUsers(signal?: AbortSignal | null) {
    return del({
        subpath: USERS_SUBPATH,
        signal,
    })
}

export async function deleteAllEffectiveAgents(signal?: AbortSignal | null) {
    return del({
        subpath: `${USERS_SUBPATH}/effective-agents`,
        signal,
    })
}

// Delete one

export async function deleteUserById(id: string, signal?: AbortSignal | null) {
    return del({
        subpath: `${USERS_SUBPATH}/${id}`,
        signal,
    })
}

export async function tryDeleteUserById(id: string, signal?: AbortSignal | null): Promise<boolean> {
    return tryDeleteEntity(`${USERS_SUBPATH}/${id}`, signal)
}

export async function deleteMe(signal?: AbortSignal | null) {
    return del({
        subpath: `${USERS_SUBPATH}/me`,
        signal,
    })
}

// Patch

export async function patchUserById(
    id: string,
    patches: DeepReadonly<JsonPatch[]>,
    signal?: AbortSignal | null,
): Promise<UserUpdateResponse> {
    return patchEntity({
        subpath: `${USERS_SUBPATH}/${id}`,
        schema: UserUpdateResponseSchema,
        patches,
        signal,
    })
}

// Put

export async function putUserById(
    id: string,
    request: Readonly<UserRequest>,
    signal?: AbortSignal | null,
): Promise<UserUpdateResponse> {
    return put({
        subpath: `${USERS_SUBPATH}/${id}`,
        schema: UserUpdateResponseSchema,
        body: request,
        signal,
    })
}
