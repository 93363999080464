import { TransferStatus } from "model/domain"
import { DeepReadonly, Nullish, tryNormalizeNullableUuid } from "my-util"

import AbstractNotification,
       { AbstractNotificationOptionsBase,
         AbstractNotificationCopyOptions,
         AbstractNotificationCreationOptions } from "./AbstractNotification"

import NotificationType,
       { TransferStatusChangedNotificationType,
         TRANSFER_STATUS_CHANGED_NOTIFICATION_TYPE } from "./NotificationType"

export interface TransferStatusChangedNotificationOptionsBase
    extends
        AbstractNotificationOptionsBase
{
    transferId?: string | null
    from: TransferStatus
    to: TransferStatus
}

export interface TransferStatusChangedNotificationCreationOptions
    extends
        Omit<AbstractNotificationCreationOptions<NotificationType>, "type">,
        TransferStatusChangedNotificationOptionsBase
{}

export interface TransferStatusChangedNotificationCopyOptions
    extends
        Omit<AbstractNotificationCopyOptions, "type">,
        Nullish<TransferStatusChangedNotificationOptionsBase>
{}

export default class TransferStatusChangedNotification extends AbstractNotification<
    TransferStatusChangedNotificationType,
    TransferStatusChangedNotificationCopyOptions
> {
    static createOrPass(
        arg: TransferStatusChangedNotification
           | DeepReadonly<TransferStatusChangedNotificationCreationOptions>
    ): TransferStatusChangedNotification {
        return arg instanceof TransferStatusChangedNotification
            ? arg
            : new TransferStatusChangedNotification(arg)
    }

    // Fields

    readonly transferId: string | null
    readonly from: TransferStatus
    readonly to: TransferStatus

    // Constructor

    constructor(options: DeepReadonly<TransferStatusChangedNotificationCreationOptions>) {
        super({ ...options, type: TRANSFER_STATUS_CHANGED_NOTIFICATION_TYPE })

        this.transferId = tryNormalizeNullableUuid(options.transferId)
        this.from = options.from
        this.to = options.to
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<TransferStatusChangedNotificationCopyOptions> = {},
    ): TransferStatusChangedNotification {
        return new TransferStatusChangedNotification({
            // Recipient ID

            recipientId: "recipientId" in options
                ? options.recipientId
                : this.recipientId,

            // Content

            transferId: "transferId" in options
                ? options.transferId
                : this.transferId,

            from: options.from ?? this.from,
            to: options.to ?? this.to,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
