import Decimal from "decimal.js"
import { ForwardedRef, forwardRef, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CurrencyRateMessage, patchTransferById } from "api"
import { Money, Transfer, WAITING_PAYMENT_TRANSFER_STATUS } from "model"

import { createOrPassDecimal, dateWithDateSet,
         dateWithTimeSet, DeepReadonly, isNullOrBlank } from "my-util"

import { useDocumentPaste, useStateWithDeps } from "ui/hook"
import { DocumentListUpload, UiDocument } from "ui/component/document"
import { MAX_MEDIUM_TEXT_LENGTH } from "validation"

import { ErrorDisplay, Input, DecimalInput, Label,
         Flex, DateInput, MoneyInput, TimeInput, TextArea,
         DateChangeEvent, Required, Modal, TimeChangeEvent } from "ui/ui"

import style from "./style.module.css"

export interface TransferCurrencyRateSetModalProps {
    onClose?: () => void
    onCurrencyRateSet?: (transfer: Transfer) => void

    targetCurrency?: string
    transferId: string

    showAgentPercentInput?: boolean

    agentPercent?: Decimal.Value | null
    attorneyFee?: Decimal.Value | null

    width?: string
}

const TransferCurrencyRateSetModal = forwardRef((
    {
        onClose, onCurrencyRateSet,
        targetCurrency, transferId,
        showAgentPercentInput,
        agentPercent, attorneyFee,
        width,
    }: Readonly<TransferCurrencyRateSetModalProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    const lastPastedDocument = useDocumentPaste()

    // State

    const [moment, setMoment] = useState(new Date())
    const [money, setMoney] = useState(new Money({ currency: "RUB", amount: new Decimal(100) }))
    const [link, setLink] = useState("")
    const [documents, setDocuments] = useState([] as DeepReadonly<UiDocument[]>)

    const [innerAgentPercent, setInnerAgentPercent] = useStateWithDeps(
        () => createOrPassDecimal(agentPercent, 2.5),
        [agentPercent],
    )

    const [innerAttorneyFee, setInnerAttorneyFee] = useStateWithDeps(
        () => createOrPassDecimal(attorneyFee, 5_000),
        [attorneyFee],
    )

    const [comment, setComment] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined as unknown)

    // Paste handling

    useEffect(() => {
        if (lastPastedDocument != null)
            setDocuments([lastPastedDocument])
    }, [lastPastedDocument])

    // Render

    return <Modal
        header={t("domain.currencyRates.headers.set")}
        loading={loading}
        onClose={onInnerClose}
        width={width}
        buttons={[
            {
                onClick: onInnerClose,
                text: t("misc.buttons.cancel"),
                buttonStyle: "outline",
            },

            {
                onClick: onSetCurrencyRate,
                text: t("misc.buttons.ok"),
                type: "submit",

                disabled:
                    isNullOrBlank(link) ||
                    documents.some(({ status }) => status === "uploading"),
            },
        ]}
        ref={ref}
    >
        <div className={style.layout}>
            {/* Date */}

            <div className={style.label}>
                <Required>
                    <Label text={t("domain.currencyRates.labels.date")}/>
                </Required>
            </div>

            <div className={style.dateValue}>
                <DateInput onChange={onDateChange}
                           loading={loading}/>
            </div>

            {/* Time */}

            <div className={style.timeLabel}>
                <Required>
                    <Label text={t("datetime.labels.time")}/>
                </Required>
            </div>

            <div className={style.timeValue}>
                <TimeInput onChange={onTimeChange}
                           loading={loading}/>
            </div>

            {/* Currency rate */}

            <div className={style.label}>
                <Required>
                    <Label text={t("domain.transfers.labels.currencyRate")}/>
                </Required>
            </div>

            <div className={style.value}>
                <Flex direction="horizontal">
                    <MoneyInput onChange={setMoney}
                                value={money}
                                precision={4}
                                readonlyCurrency/>

                    <MoneyInput value={new Money({ currency: targetCurrency, amount: 1 })}
                                readonly/>
                </Flex>
            </div>

            {/* Evidence link */}

            <div className={style.label}>
                <Required>
                    <Label text={t("domain.currencyRates.labels.evidenceLink")}/>
                </Required>
            </div>

            <div className={style.value}>
                <Input placeholder={t("domain.currencyRates.placeholders.evidenceLink")}
                       onChange={setLink}
                       value={link}/>
            </div>

            {/* Evidence */}

            <div className={style.label}>
                <Label text={t("domain.currencyRates.labels.evidence")}/>
            </div>

            <div className={style.value}>
                <DocumentListUpload onChange={setDocuments}
                                    documents={documents}
                                    disabled={loading}/>
            </div>

            {/* Attorney fee */}

            <div className={style.label}>
                <Label text={t("domain.transfers.labels.attorneyFee")}/>
            </div>

            <div className={style.attorneyFee}>
                <DecimalInput onChange={setInnerAttorneyFee}
                              value={innerAttorneyFee}
                              right="₽"/>
            </div>

            {/* Agent percent */}

            {showAgentPercentInput && <>
                <div className={style.label}>
                    <Label text={t("domain.transfers.labels.agentPercent")}/>
                </div>

                <div className={style.agentPercentValue}>
                    <DecimalInput onChange={setInnerAgentPercent}
                                  value={innerAgentPercent}
                                  precision={4}
                                  right="%"/>
                </div>
            </>}

            {/* Comment */}

            <div className={style.label}>
                <Label text={t("domain.transfers.labels.comment")}/>
            </div>

            <div className={style.value}>
                <TextArea placeholder={t("domain.transfers.placeholders.comment")}

                          onChange={setComment}
                          value={comment}

                          resize="vertical"

                          loading={loading}

                          max={MAX_MEDIUM_TEXT_LENGTH}
                          showMax

                          canSubmit/>
            </div>

            {/* error */}

            {Boolean(error) &&
                <div className={style.error}>
                    <ErrorDisplay centerType="flex"
                                  error={error}/>
                </div>
            }
        </div>
    </Modal>

    // Events

    function onInnerClose() {
        if (!loading)
            onClose?.()
    }

    function onDateChange({ date }: DateChangeEvent) {
        setMoment(dateWithDateSet(moment, date))
    }

    function onTimeChange({ date }: TimeChangeEvent) {
        setMoment(dateWithTimeSet(moment, date))
    }

    async function onSetCurrencyRate() {
        setLoading(true)

        try {
            const transfer = await patchTransferById(transferId, [
                {
                    op: "replace",
                    path: "/status",
                    value: WAITING_PAYMENT_TRANSFER_STATUS,
                },

                {
                    op: "replace",
                    path: "/currencyRate",
                    value: {
                        moment: new Date(moment.getTime()),

                        money: {
                            currency: money.currency,
                            amount: money.amount.toFixed(4),
                        },

                        evidenceId: documents[0]?.document?.id,
                        evidenceLink: link,
                    } satisfies CurrencyRateMessage,
                },

                ...(showAgentPercentInput ? [{
                    op: "replace",
                    path: "/agentPercent",
                    value: innerAgentPercent.toFixed(4),
                } as const] : []),

                {
                    op: "replace",
                    path: "/attorneyFee",
                    value: innerAttorneyFee.toString(),
                },

                {
                    op: "replace",
                    path: "/adminComment",
                    value: comment,
                },
            ])

            onCurrencyRateSet?.(transfer)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
})

TransferCurrencyRateSetModal.displayName = "TransferCurrencyRateSetModal"

export default TransferCurrencyRateSetModal
