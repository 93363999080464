import getSymbolFromCurrency from "currency-symbol-map"
import Decimal from "decimal.js"
import Copyable from "model/Copyable"
import { createOrPassDecimal, formatDecimal, Immutable, IsImmutable } from "my-util"

export interface MoneyCreationOptions {
    amount?: Decimal.Value | null
    currency?: string
}

export interface MoneyCopyOptions extends MoneyCreationOptions {}

export default class Money
    extends
        Copyable<MoneyCopyOptions>

    implements
        Immutable
{
    static createOrPass(arg?: Money | Readonly<MoneyCreationOptions> | null): Money {
        return arg instanceof Money
            ? arg
            : new Money(arg ?? {})
    }

    // Fields

    readonly [IsImmutable] = true

    readonly amount: Decimal
    readonly currency: string

    // Constructor

    constructor({ amount, currency }: Readonly<MoneyCreationOptions> = {}) {
        super()

        this.amount = createOrPassDecimal(amount)
        this.currency = currency ?? "USD"
    }

    // Copy

    protected override createCopy(options: Readonly<MoneyCopyOptions> = {}): Money {
        return new Money({
            amount: options.amount ?? this.amount,
            currency: options.currency ?? this.currency,
        })
    }

    // To string

    toString(precision?: number): string {
        return getSymbolFromCurrency(this.currency)
            + " "
            + formatDecimal(this.amount, precision)
    }
}
