import { z } from "zod"
import { UuidSchema } from "my-util"
import { ChatResponseType } from "./type"
import { ChatResponseBaseSchema } from "./base"
import { AnonymousUserResponseSchema } from "../domain"
import { ModelObjectResponseSchema } from "../model-object"

export const AnonymousChatMessageResponseSchema = ChatResponseBaseSchema.extend({
    // Type

    type: z.literal("anonymous-message" satisfies ChatResponseType),

    // Creator

    creator: AnonymousUserResponseSchema.nullish(),

    // Payload

    text: z.string().nullish(),
    documentIds: UuidSchema.array().nullish(),

    // Meta

    readByIds: UuidSchema.array().nullish(),
    edited: z.coerce.boolean().nullish(),
}).merge(ModelObjectResponseSchema)

export type AnonymousChatMessageResponse = z.infer<typeof AnonymousChatMessageResponseSchema>
