import { getProviderById } from "api"
import { Provider } from "model"

import createStorageHook,
       { StorageHook, StorageHookActions, StorageHookValues } from "./createStorageHook"

export interface ProvidersHook extends StorageHook<Provider> {}
export interface ProvidersHookValues extends StorageHookValues<Provider> {}
export interface ProvidersHookActions extends StorageHookActions<Provider> {}

const useProviders = createStorageHook({
    loadById: getProviderById,
    // debug: true,
}) satisfies () => ProvidersHook

export default useProviders
