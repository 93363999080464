import { ForwardedRef, forwardRef } from "react"
import { User } from "model"
import { DeepReadonly } from "my-util"
import { createUserPagePath } from "ui/page/sections/users/UserPage/path"
import { Link, LinkProps } from "ui/ui"

export interface UserLinkProps extends Omit<LinkProps, "to"> {
    user: User

    showCompany?: boolean
}

const UserLink = forwardRef((
    props: DeepReadonly<UserLinkProps>,
    ref: ForwardedRef<HTMLAnchorElement>,
) => {
    const {
        user,
        showCompany,
        text, children,
    } = props

    return <Link { ...props }
                 to={createUserPagePath(user.id)}
                 ref={ref}>
        {children ?? text ?? (showCompany ? user.company.anyName ?? user.name : user.name)}
    </Link>
})

UserLink.displayName = "UserLink"

export default UserLink
