import { countEntities, patchEntity, get, put, del,
         BASE_URL, NetworkError, post, throwIfResponseNotOk} from "api/http/util"

import { IdsRequest, JsonPatch, TransferRequest } from "api/request"

import { TransfersConfigResponse, TransfersConfigResponseSchema,
         TransferAgentPaymentResponseSchema, TransferResponseSchema } from "api/response"

import { Transfer, TransferAgentPayment, TransferStatus } from "model"
import { DeepReadonly, joinSubpaths } from "my-util"

export const TRANSFERS_SUBPATH = "/transfers"

// Report

export async function getTransfersReport(
    request: DeepReadonly<IdsRequest>,
    signal?: AbortSignal | null,
): Promise<Blob> {
    let response: Response

    try {
        response = await fetch(`${BASE_URL}${TRANSFERS_SUBPATH}/report`, {
            method: "POST",
            body: JSON.stringify(request),
            headers: { "Content-Type": "application/json" },
            signal,
        })
    } catch (error) {
        throw new NetworkError("Network error", { cause: error })
    }

    await throwIfResponseNotOk(response)

    return await response.blob()
}

// Treaty

export function createTransferTreatyDownloadUrlById(id: string): string {
    return `${BASE_URL}${TRANSFERS_SUBPATH}/${id}/treaty`
}

// Agent payments

export async function getAllTransferAgentPayments(
    signal?: AbortSignal | null,
): Promise<TransferAgentPayment[]> {
    return (await get({
        subpath: `${TRANSFERS_SUBPATH}/payments`,
        schema: TransferAgentPaymentResponseSchema.array(),
        signal,
    })).map(response => new TransferAgentPayment(response))
}

export async function getAllMyTransferAgentPayments(
    signal?: AbortSignal | null,
): Promise<TransferAgentPayment[]> {
    return (await get({
        subpath: `${TRANSFERS_SUBPATH}/payments/my`,
        schema: TransferAgentPaymentResponseSchema.array(),
        signal,
    })).map(response => new TransferAgentPayment(response))
}

// Count

export async function countTransfers(signal?: AbortSignal | null): Promise<number> {
    return countEntities(`${TRANSFERS_SUBPATH}/count`, signal)
}

export async function countTransfersByStatusNot(status: TransferStatus, signal?: AbortSignal | null): Promise<number> {
    return countEntities(`${TRANSFERS_SUBPATH}/not-${status}/count`, signal)
}

export async function countTransfersByStatus(status: TransferStatus, signal?: AbortSignal | null): Promise<number> {
    return countEntities(`${TRANSFERS_SUBPATH}/${status}/count`, signal)
}

export async function countTransfersInWork(signal?: AbortSignal | null): Promise<number> {
    return countEntities(`${TRANSFERS_SUBPATH}/in-work/count`, signal)
}

export async function countMyTransfers(signal?: AbortSignal | null): Promise<number> {
    return countEntities(`${TRANSFERS_SUBPATH}/my/count`, signal)
}

export async function countMyTransfersByStatusNot(status: TransferStatus, signal?: AbortSignal | null): Promise<number> {
    return countEntities(`${TRANSFERS_SUBPATH}/my/not-${status}/count`, signal)
}

export async function countMyTransfersByStatus(status: TransferStatus, signal?: AbortSignal | null): Promise<number> {
    return countEntities(`${TRANSFERS_SUBPATH}/my/${status}/count`, signal)
}

export async function countMyTransfersInWork(signal?: AbortSignal | null): Promise<number> {
    return countEntities(`${TRANSFERS_SUBPATH}/my/in-work/count`, signal)
}

// Get one

export async function getTransferById(id: string, signal?: AbortSignal | null): Promise<Transfer> {
    return new Transfer(await get({
        subpath: `${TRANSFERS_SUBPATH}/${id}`,
        schema: TransferResponseSchema,
        signal,
    }))
}

// Get many

export async function getAllTransfers(signal?: AbortSignal | null): Promise<Transfer[]> {
    return getTransferArray([], signal)
}

export async function getAllTransfersByStatusNot(status: TransferStatus, signal?: AbortSignal | null): Promise<Transfer[]> {
    return getTransferArray(["not-" + status], signal)
}

export async function getAllTransfersByStatus(status: TransferStatus, signal?: AbortSignal | null): Promise<Transfer[]> {
    return getTransferArray([status], signal)
}

export async function getAllTransfersInWork(signal?: AbortSignal | null): Promise<Transfer[]> {
    return getTransferArray(["in-work"], signal)
}

export async function getAllMyTransfersByStatusNot(status: TransferStatus, signal?: AbortSignal | null): Promise<Transfer[]> {
    return getTransferArray(["my", "not-" + status], signal)
}

export async function getAllMyTransfersByStatus(status: TransferStatus, signal?: AbortSignal | null): Promise<Transfer[]> {
    return getTransferArray(["my", status], signal)
}

export async function getAllMyTransfersInWork(signal?: AbortSignal | null): Promise<Transfer[]> {
    return getTransferArray(["my", "in-work"], signal)
}

async function getTransferArray(subpaths: string[], signal?: AbortSignal | null): Promise<Transfer[]> {
    return (await get({
        subpath: joinSubpaths(TRANSFERS_SUBPATH, ...subpaths),
        schema: TransferResponseSchema.array(),
        signal,
    })).map(response => new Transfer(response))
}

export async function getAllMyTransfers(signal?: AbortSignal | null): Promise<Transfer[]> {
    return (await get({
        subpath: `${TRANSFERS_SUBPATH}/my`,
        schema: TransferResponseSchema.array(),
        signal,
    })).map(response => new Transfer(response))
}

// Delete one

export async function deleteTransferById(id: string, signal?: AbortSignal | null) {
    return del({
        subpath: `${TRANSFERS_SUBPATH}/${id}`,
        signal,
    })
}

// Patch

export async function patchTransferById(
    id: string,
    patches: DeepReadonly<JsonPatch[]>,
    signal?: AbortSignal | null
): Promise<Transfer> {
    return new Transfer(await patchEntity({
        subpath: `${TRANSFERS_SUBPATH}/${id}`,
        schema: TransferResponseSchema,
        patches,
        signal,
    }))
}

// Put

export async function putTransferById(
    id: string,
    request: DeepReadonly<TransferRequest>,
    signal?: AbortSignal | null,
): Promise<Transfer> {
    return new Transfer(await put({
        subpath: `${TRANSFERS_SUBPATH}/${id}`,
        schema: TransferResponseSchema,
        body: request,
        signal,
    }))
}

// Create

export async function createTransfer(
    request: DeepReadonly<TransferRequest>,
    signal?: AbortSignal | null,
): Promise<Transfer> {
    return new Transfer(await post({
        subpath: TRANSFERS_SUBPATH,
        schema: TransferResponseSchema,
        body: request,
        signal,
    }))
}

// Get config

export async function getTransfersConfig(signal?: AbortSignal | null): Promise<TransfersConfigResponse> {
    return get({
        subpath: `${TRANSFERS_SUBPATH}/config`,
        schema: TransfersConfigResponseSchema,
        signal,
    })
}
