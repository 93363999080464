import { ForwardedRef, forwardRef, ReactNode, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { plusIconUrl } from "image"
import { countInviteApplications, countInvites, countMyInvites } from "api"
import { useStateWithDeps } from "ui/hook"
import { UserContext } from "ui/context"
import { ALL_INVITE_APPLICATIONS_PAGE_PATH } from "ui/page/sections/clients/invites/applications/AllInviteApplicationsPage/path"
import { ALL_INVITES_PAGE_PATH } from "ui/page/sections/clients/invites/AllInvitesPage/path"
import { INVITE_CREATION_PAGE_PATH } from "ui/page/sections/clients/invites/InviteCreationPage/path"
import { Button, Clickable, CountDataRow, ErrorDisplay, Flex, Loading } from "ui/ui"
import Widget, { WidgetRef } from "../Widget"

export type InvitesWidgetCountType =
    | "all"
    | "my"

export interface InvitesWidgetProps {
    showSentCount?: boolean
    showApplicationCount?: boolean

    sentCountType?: InvitesWidgetCountType

    maxContentWidth?: string

    width?: string
    height?: string
}

const InvitesWidget = forwardRef((
    {
        showSentCount, showApplicationCount,
        sentCountType,
        maxContentWidth,
        width, height,
    }: Readonly<InvitesWidgetProps>,
    ref: ForwardedRef<WidgetRef>,
) => {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const navigate = useNavigate()

    // State

    const [sentCount, setSentCount] = useState(0)
    const [newCount, setNewCount] = useState(0)

    const [loadingSentCount, setLoadingSentCount] = useStateWithDeps(
        () => showSentCount,
        [showSentCount],
    )

    const [loadingApplicationsCount, setLoadingApplicationsCount] = useStateWithDeps(
        () => showApplicationCount,
        [showApplicationCount],
    )

    const loading = loadingSentCount || loadingApplicationsCount

    const [error, setError] = useState(null as any)

    // Loading

    useEffect(() => {
        const controller = new AbortController()

        if (showSentCount) {
            const count = sentCountType === "my"
                ? countMyInvites
                : countInvites

            count(controller.signal)
                .then(setSentCount)
                .catch(setErrorIfNotAborted)
                .finally(setNotLoadingSentCountIfNotAborted)
        }

        if (showApplicationCount)
            countInviteApplications(controller.signal)
                .then(setNewCount)
                .catch(setErrorIfNotAborted)
                .finally(setNotLoadingApplicationsCountIfNotAborted)

        return () => controller.abort()

        function setErrorIfNotAborted(error: unknown) {
            if (!controller.signal.aborted)
                setError(error)
        }

        function setNotLoadingSentCountIfNotAborted() {
            if (!controller.signal.aborted)
                setLoadingSentCount(false)
        }

        function setNotLoadingApplicationsCountIfNotAborted() {
            if (!controller.signal.aborted)
                setLoadingApplicationsCount(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSentCount, showApplicationCount])

    // Render

    return <Widget onTitleClick={() => navigate(ALL_INVITES_PAGE_PATH)}
                   title={t("widgets.invites.header") + ":"}
                   maxContentWidth={maxContentWidth}
                   backgroundColor="#E8F9E0"
                   right={renderRight()}
                   height={height}
                   width={width}
                   ref={ref}>
        {renderContent()}
    </Widget>

    function renderRight(): ReactNode {
        if (!localUser?.hasRightToSendInvites)
            return null

        return <Button onClick={() => navigate(INVITE_CREATION_PAGE_PATH)}
                       iconSrc={plusIconUrl}
                       iconAlt="Plus icon"
                       width="fit-content"/>
    }

    function renderContent(): ReactNode {
        if (loading)
            return <Loading centerType="flex"/>

        if (error)
            return <ErrorDisplay centerType="flex"
                                 error={error}/>

        return <Flex justify="space-between"
                       height="100%">
            <Flex>
                {showSentCount &&
                    <Clickable onClick={() => navigate(ALL_INVITES_PAGE_PATH)}
                               cursor="pointer"
                               width="100%">
                        <CountDataRow label={t("widgets.invites.labels.sentCount")}
                                      value={sentCount}/>
                    </Clickable>
                }

                {showApplicationCount &&
                    <Clickable onClick={() => navigate(ALL_INVITE_APPLICATIONS_PAGE_PATH)}
                               cursor="pointer"
                               width="100%">
                        <CountDataRow label={t("widgets.invites.labels.applicationCount")}
                                      value={newCount}
                                      important/>
                    </Clickable>
                }
            </Flex>
        </Flex>
    }
})

InvitesWidget.displayName = "InvitesWidget"

export default InvitesWidget
