import { ForwardedRef, forwardRef, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { createOrCopyDate, DateRange, DeepReadonly, ReadonlyDate } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { Flex, FlexProps } from "ui/ui/layout"
import Label from "ui/ui/Label"
import DateInput from "../DateInput"

export interface DateRangeInputProps extends FlexProps {
    onChange?: (value: DateRange) => void
    value?: DateRange

    invalid?: boolean
    disabled?: boolean
    readonly?: boolean
    loading?: boolean
}

const DateRangeInput = forwardRef((
    props: DeepReadonly<DateRangeInputProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const {
        onChange, value,
        invalid, disabled, readonly, loading,
    } = props

    const [t] = useTranslation()

    // State


    const [from, setFrom] = useStateWithDeps(
        () => createOrCopyDate(value?.from),
        [value?.from],
    )

    const [to, setTo] = useStateWithDeps(
        () => createOrCopyDate(value?.to),
        [value?.to],
    )

    // Render

    return <Flex { ...props }

                 direction="horizontal"
                 width="fit-content"

                 ref={ref}>
        {renderInput(t("datetime.labels.from"), from, onFromChange)}
        {renderInput(t("datetime.labels.to"), to, onToChange)}
    </Flex>

    function renderInput(
        label: string,
        value: ReadonlyDate,
        onChange: (date: Date) => void,
    ): ReactNode {
        return <Flex direction="horizontal"
                     gap="8px">
            <Label fontSize="16px"
                   text={label}/>

            <DateInput onChange={({ date }) => onChange(date)}
                       value={value}

                       invalid={invalid}
                       disabled={disabled}
                       loading={loading}
                       readonly={readonly}

                       width="min"/>
        </Flex>
    }

    // Events

    function onFromChange(newFrom: Date) {
        onInnerChange(
            newFrom,
            newFrom > to ? newFrom : to,
        )
    }

    function onToChange(newTo: Date) {
        onInnerChange(
            newTo < from ? newTo : from,
            newTo,
        )
    }

    function onInnerChange(newFrom: Date, newTo: Date) {
        setFrom(newFrom)
        setTo(newTo)

        onChange?.({
            from: new Date(newFrom),
            to: new Date(newTo),
        })
    }
})

DateRangeInput.displayName = "DateRangeInput"

export default DateRangeInput
