import { ForwardedRef, forwardRef, Fragment, ReactNode, useEffect, useRef, useState } from "react"
import { v4 as generateRandomUuid } from "uuid"
import { plusIconUrl, trashCanIconUrl } from "image"
import { EARTH_ROUTE_POINT_TYPE } from "model"
import { DeepReadonly, map, retainMapKeys, splicedArray } from "my-util"
import { copyRoutePointFields, RoutePointFields } from "ui/fields"
import { useStateWithDeps } from "ui/hook"
import { Button, Flex } from "ui/ui"
import RoutePointEditor, { RoutePointViolations } from "../RoutePointEditor"
import RoutePointListViolations from "./RoutePointListViolations"

export interface RoutePointListEditorProps {
    onValidate?: (violations: RoutePointListViolations) => void
    onChange?: (values: RoutePointFields[]) => void
    values?: Iterable<DeepReadonly<RoutePointFields>>

    noDocumentDelete?: boolean

    allowEmpty?: boolean

    loading?: boolean
    disabled?: boolean
    readonly?: boolean
    required?: boolean
    output?: boolean

    width?: string
}

const RoutePointListEditor = forwardRef((
    {
        onValidate, onChange, values,
        noDocumentDelete,
        allowEmpty,
        loading, disabled, readonly, required, output,
        width,
    }: DeepReadonly<RoutePointListEditorProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // Refs

    const valuesChangedRef = useRef(true)
    const violationsChangedRef = useRef(true)

    // State

    const [innerValues, setInnerValues] = useStateWithDeps<RoutePointFields[]>(
        oldValues => {
            if (valuesChangedRef.current && oldValues != null)
                return oldValues

            const newValues = map(values ?? [], copyRoutePointFields)

            if (!output && !allowEmpty && newValues.length === 0)
                newValues.push(createNew())

            return newValues
        },

        [values],
    )

    const [violations, setViolations] = useState(new RoutePointListViolations())

    // Effects

    // - Violations clean-up

    useEffect(() => {
        const newViolationsByRoutePointId = retainMapKeys(
            new Map(violations.violationsByRoutePointId),
            innerValues.map(({ id }) => id).filter(id => id != null) as string[],
        )

        if (newViolationsByRoutePointId.size === violations.violationsByRoutePointId.size)
            return

        setViolations(oldViolations => oldViolations.copy({
            violationsByRoutePointId: newViolationsByRoutePointId,
            deleteValid: true,
        }))

        violationsChangedRef.current = true
    }, [innerValues, violations.violationsByRoutePointId])

    // - Values propagation

    useEffect(() => {
        if (!valuesChangedRef.current)
            return

        valuesChangedRef.current = false

        if (violations.isValid)
            onChange?.(innerValues.map(copyRoutePointFields))
    }, [onChange, innerValues, violations.isValid])

    // - Violations propagation

    useEffect(() => {
        if (violationsChangedRef.current) {
            violationsChangedRef.current = false
            onValidate?.(violations)
        }
    }, [onValidate, violations])

    // Render

    return <Flex align="start"
                 width={width}
                 ref={ref}>
        {renderContent()}
    </Flex>

    function renderContent(): ReactNode {
        if (innerValues.length === 0)
            return renderAddButton()

        if (output || readonly)
            return renderAllRoutePoints()

        return renderAllRoutePointsWithEditingButtons()
    }

    // - Route points

    function renderAllRoutePoints(): ReactNode {
        return innerValues.map((routePoint, index) =>
            <Fragment key={routePoint.id ?? index}>
                {renderRoutePoint(routePoint, index)}
            </Fragment>
        )
    }

    function renderAllRoutePointsWithEditingButtons(): ReactNode {
        const deleteButtonDisabled = !allowEmpty && innerValues.length === 1

        return innerValues.map((routePoint, index) =>
            <Flex key={routePoint.id ?? index}
                  direction="horizontal"
                  align="stretch">
                {renderRoutePoint(routePoint, index)}

                <Flex justify="space-between"
                      width="fit-content"
                      align="start">
                    {renderDeleteButton(index, deleteButtonDisabled)}

                    {index === innerValues.length - 1 &&
                        renderAddButton()
                    }
                </Flex>
            </Flex>
        )
    }

    function renderRoutePoint(value: RoutePointFields, index: number = -1): ReactNode {
        return <RoutePointEditor onValidate={valueViolations => onValidateRoutePoint(valueViolations, value.id)}
                                 onChange={newValue => onRoutePointChange(newValue, index)}
                                 value={value}

                                 noDocumentDelete={noDocumentDelete}

                                 loading={loading}
                                 disabled={disabled}
                                 readonly={readonly}
                                 required={required}
                                 output={output}/>
    }

    // - Buttons

    function renderAddButton(): ReactNode {
        return <Button onClick={onAdd}

                       iconSrc={plusIconUrl}
                       iconAlt="Plus icon"

                       width="32px"/>
    }

    function renderDeleteButton(routePointIndex: number = -1, disabled: boolean = false): ReactNode {
        return <Button onClick={() => onDelete(routePointIndex)}

                       iconSrc={trashCanIconUrl}
                       iconAlt="Trash can icon"

                       width="32px"
                       buttonStyle="outline"

                       disabled={disabled}
                       critical/>
    }

    // Events

    function onAdd() {
        setInnerValues(oldInnerRoutePoints => [...oldInnerRoutePoints, createNew()])

        valuesChangedRef.current = true
    }

    function onDelete(routePointIndex: number = -1) {
        if (routePointIndex < 0)
            return

        setInnerValues(oldValues => {
            const routePoint = oldValues[routePointIndex]

            if (routePoint != null)
                setViolations(oldViolations => {
                    violationsChangedRef.current = true

                    return routePoint.id != null
                        ? oldViolations.withDeleted(routePoint.id)
                        : oldViolations
                })

            valuesChangedRef.current = true

            return splicedArray(oldValues, routePointIndex, 1)
        })
    }

    function onRoutePointChange(routePoint: RoutePointFields, index: number = -1) {
        if (index < 0)
            return

        setInnerValues(oldValues => splicedArray(oldValues, index, 1, routePoint))

        valuesChangedRef.current = true
    }

    function onValidateRoutePoint(
        routePointViolations: RoutePointViolations,
        routePointId?: string | null
    ) {
        setViolations(oldViolations => {
            if (routePointId == null)
                return oldViolations

            violationsChangedRef.current = true

            return oldViolations
                .withNew(routePointId, routePointViolations)
                .withoutValid()
        })
    }

    // Util

    function createNew(): RoutePointFields {
        return {
            id: generateRandomUuid(),
            country: "",
            type: EARTH_ROUTE_POINT_TYPE,
            documents: [],
        }
    }
})

RoutePointListEditor.displayName = "RoutePointListEditor"

export default RoutePointListEditor
