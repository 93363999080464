import { Copyable } from "model"
import { DeepReadonly, Immutable, IsImmutable } from "my-util"
import { ViolationType } from "validation"

export interface RoutePointViolationsOptionsBase {
    country?: ViolationType | null
}

export interface RoutePointViolationsCreationOptions extends RoutePointViolationsOptionsBase {}
export interface RoutePointViolationsCopyOptions extends RoutePointViolationsOptionsBase {}

export default class RoutePointViolations
    extends
        Copyable<RoutePointViolationsCopyOptions>

    implements
        Immutable
{
    static createOrPass(
        arg?: RoutePointViolations
            | DeepReadonly<RoutePointViolationsCreationOptions>
            | null
    ): RoutePointViolations {
        return arg instanceof RoutePointViolations
            ? arg
            : new RoutePointViolations(arg ?? {})
    }

    // Fields

    readonly [IsImmutable] = true

    readonly country: ViolationType | null

    // Constructor

    constructor(options: DeepReadonly<RoutePointViolationsCreationOptions> = {}) {
        super()

        this.country = options.country ?? null
    }

    // Validity check

    get isValid(): boolean {
        return this.country == null
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<RoutePointViolationsCopyOptions> = {},
    ): RoutePointViolations {
        return new RoutePointViolations({
            country: "country" in options
                ? options.country
                : this.country,
        })
    }
}
