import MessageTargetType,
       { EMAIL_MESSAGE_TARGET_TYPE,
         PHONE_MESSAGE_TARGET_TYPE } from "model/enums/MessageTargetType"

export default interface IsAllowedByMessageTargetType {
    isAllowedByPhone: boolean
    isAllowedByEmail: boolean
}

export const IS_ALLOWED_BY_EVERY_MESSAGE_TARGET_TYPE: Readonly<IsAllowedByMessageTargetType> = {
    isAllowedByEmail: true,
    isAllowedByPhone: true,
}

export function isAllowedByMessageTargetTypeToMessageTargetTypeList(
    config: Readonly<IsAllowedByMessageTargetType>,
): MessageTargetType[] {
    const list = new Array<MessageTargetType>()

    if (config.isAllowedByPhone)
        list.push(PHONE_MESSAGE_TARGET_TYPE)

    if (config.isAllowedByEmail)
        list.push(EMAIL_MESSAGE_TARGET_TYPE)

    return list
}
