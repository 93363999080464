import { t } from "i18next"

// Types

type TransferDirection =
    | ImportTransferDirection
    | ExportTransferDirection

export default TransferDirection

export type ImportTransferDirection = typeof IMPORT_TRANSFER_DIRECTION
export type ExportTransferDirection = typeof EXPORT_TRANSFER_DIRECTION

// Consts

export const IMPORT_TRANSFER_DIRECTION = "import"
export const EXPORT_TRANSFER_DIRECTION = "export"

// Name

export function getTransferDirectionName(direction: TransferDirection): string {
    return t(`domain.transfers.directions.${direction}`)
}

// Is-check

export function isTransferDirection(s: string): s is TransferDirection {
    switch (s) {
        case IMPORT_TRANSFER_DIRECTION:
        case EXPORT_TRANSFER_DIRECTION:
            s satisfies TransferDirection
            return true

        default:
            return false
    }
}
