// import { useTranslation } from "react-i18next"
// import { CONTACTS_PAGE_PATH } from "ui/page/sections/contacts/ContactsPage/path"
import { GlobalLangSelector, ReloginLink } from "ui/component"
import { Center, Flex, /* Link */ } from "ui/ui"
import styles from "./styles.module.css"

export default function Public() {
    // const [t] = useTranslation()

    return <>
        <Center type="absolute">
            <Flex gap="0">
                <h1 className={styles.logo}>MA</h1>
                <ReloginLink hideOnMissing/>
            </Flex>
        </Center>

        <div className={styles.langSelector}>
            <GlobalLangSelector/>
        </div>

        {/* <div className={styles.contactsLink}>
            <Link text={t("sections.contacts.link")}
                  to={CONTACTS_PAGE_PATH}/>
        </div> */}
    </>
}
