import { ReactElement, ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { getAllMyUsers, getAllUsersByIds, getAllUsersByRole } from "api"

import { ACTIVE_USER_STATUS, CLIENT_USER_ROLE, User,
         getUsersByStatusSortingOrderComparator, UserStatus } from "model"

import { uniqueArray } from "my-util"
import { useUsers } from "ui/hook"
import { UserContext } from "ui/context"
import { Error403Page, SessionExpiredErrorPage } from "ui/page/error"
import { Page, UserTable } from "ui/component"
import { Center, ErrorDisplay, Flex, Link, Loading, Padding, UserStatusColorLegendList } from "ui/ui"
import { ALL_INVITES_PAGE_PATH } from "../invites/AllInvitesPage/path"

export default function AllClientsPage() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const storedUsers = useUsers()

    // State

    // - Clients

    const [clients, setClients] = useState(new Array<User>())
    const [loadingClient, setLoadingClient] = useState(true)

    // - Agents

    const [agents, setAgents] = useState(new Array<User>())
    const [loadingAgents, setLoadingAgents] = useState(false)

    // - Loading

    const loading = loadingClient || loadingAgents

    const [error, setError] = useState(undefined as unknown)

    // - Sorting

    const [sortStatus, setSortStatus] = useState<UserStatus>(ACTIVE_USER_STATUS)

    const clientsComparator = useMemo(
        () => getUsersByStatusSortingOrderComparator(sortStatus),
        [sortStatus],
    )

    // Effects

    // - Clients loading

    useEffect(() => {
        if (!localUser?.isAgent)
            return

        const controller = new AbortController();

        const promise = localUser.isManager
            ? getAllUsersByRole(CLIENT_USER_ROLE, controller.signal)
            : getAllMyUsers(controller.signal)

        promise
            .then(newClients => {
                setClients(newClients)
                setLoadingAgents(localUser.isManager)
                storedUsers.addAll(newClients)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingClient(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // - Agents loading

    useEffect(() => {
        if (!loadingAgents)
            return

        const agentIds = uniqueArray(
            clients
                .map(({ creatorId }) => creatorId)
                .filter(id => id != null) as string[]
        )

        if (agentIds.length === 0) {
            setLoadingAgents(false)
            return
        }

        const controller = new AbortController()

        getAllUsersByIds({ ids: agentIds }, controller.signal)
            .then(agents => {
                setAgents(agents)
                storedUsers.addAll(agents)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingAgents(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAgents])

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    if (!localUser.isAgent)
        return <Error403Page/>

    return <Page title={renderTitle()}
                 type="main">
        {renderContent()}
    </Page>

    function renderTitle(): ReactElement {
        return <Flex direction="horizontal"
                     justify="space-between">
            {t("sections.clients.header").toUpperCase()}

            <Flex direction="horizontal"
                  width="fit-content"
                  gap="32px">

                <Link text={t("sections.clients.invites.header")}
                      to={ALL_INVITES_PAGE_PATH}
                      fontSize="16px"/>
            </Flex>
        </Flex>
    }

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (error)
            return <ErrorDisplay error={error}/>

        if (clients.length === 0)
            return <Center>
                {t("sections.clients.messages.no")}
            </Center>

        return <Flex width="fit-content"
                     align="start"
                     gap="8px">
            <Padding paddingLeft="16px">
                <UserStatusColorLegendList onClick={setSortStatus}
                                           fontSize="12px"
                                           showLegend
                                           gap="8px"/>
            </Padding>

            <UserTable users={clients}
                       agents={agents}

                       showAgents={localUser?.isManager}
                       showStatuses

                       comparator={clientsComparator}/>
        </Flex>
    }
}
