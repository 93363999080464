import { ForwardedRef, forwardRef, Fragment,
         ReactNode, useEffect, useMemo, useRef, useState } from "react"

import { plusIconUrl, trashCanIconUrl } from "image"
import * as api from "api"
import { Provider, TransferProvider, User } from "model"
import { DeepReadonly, splicedArray } from "my-util"
import { useStateWithDeps, useUsers } from "ui/hook"
import { Button, Flex, FLEX_DEFAULT_GAP } from "ui/ui"
import TransferProviderEditor from "../TransferProviderEditor"

const BUTTON_WIDTH = "32px"
const BUTTONS_FLEX_WIDTH = `calc(2 * ${BUTTON_WIDTH} + ${FLEX_DEFAULT_GAP})`

export interface TransferProviderListEditorProps {
    onChange?: (values: TransferProvider[]) => void
    values?: Iterable<TransferProvider>

    onUsersChange?: (users: User[]) => void
    users?: Iterable<User> | Map<string, User>
    getAllUsers?: () => Promise<User[]>
    getUserById?: (id: string, signal?: AbortSignal | null) => Promise<User>

    onProvidersChange?: (providers: Provider[]) => void
    providers?: Iterable<Provider> | Map<string, Provider>
    getAllProviders?: () => Promise<Provider[]>
    getProviderById?: (id: string, signal?: AbortSignal | null) => Promise<Provider>

    onRenderModal?: (modal: ReactNode) => void

    allowEmpty?: boolean

    loading?: boolean
    disabled?: boolean
    readonly?: boolean
    required?: boolean
    output?: boolean

    width?: string
}

const TransferProviderListEditor = forwardRef((
    {
        onChange, values,
        onUsersChange, users, getAllUsers, getUserById,
        onProvidersChange, providers, getAllProviders, getProviderById,
        onRenderModal,
        allowEmpty,
        loading, disabled, readonly, required, output,
        width,
    }: DeepReadonly<TransferProviderListEditorProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const usersStorage = useUsers()

    // Ref

    const changedRef = useRef(true)

    const usersChangedRef = useRef(false)
    const providersChangedRef = useRef(false)

    const allUsersLoadingPromiseRef = useRef(null as Promise<User[]> | null)
    const allProvidersLoadingPromiseRef = useRef(null as Promise<Provider[]> | null)

    // State

    // - Values

    const [innerValues, setInnerValues] = useStateWithDeps<TransferProvider[]>(
        oldValues => {
            if (changedRef.current && oldValues != null)
                return oldValues

            const newValues = [...values ?? []]

            if (!output && !allowEmpty && newValues.length === 0)
                newValues.push(createNew())

            return newValues
        },

        [values, allowEmpty, output],
    )

    // - Users

    const [innerUsersById, setInnerUsersById] = useStateWithDeps<Map<string, User>>(
        oldUsersByIds => {
            const newUsersByIds = new Map(oldUsersByIds)
            const propsUsersByIds = User.groupByIdOrPassOrCreate(users)

            for (const [id, user] of propsUsersByIds.entries())
                newUsersByIds.set(id, user)

            return newUsersByIds
        },

        [users],
    )

    const innerUsers = useMemo(() => [...innerUsersById.values()], [innerUsersById])

    const [loadedAllUsers, setLoadedAllUsers] = useState(false)
    const [allUsersLoadingError, setAllUsersLoadingError] = useState(undefined as unknown)

    // - Providers

    const [innerProvidersById, setInnerProvidersById] = useStateWithDeps<Map<string, Provider>>(
        oldProvidersByIds => {
            const newProvidersByIds = new Map(oldProvidersByIds)
            const propsProvidersByIds = Provider.groupByIdOrPassOrCreate(providers)

            for (const [id, provider] of propsProvidersByIds.entries())
                newProvidersByIds.set(id, provider)

            return newProvidersByIds
        },

        [providers],
    )

    const innerProviders = useMemo(() => [...innerProvidersById.values()], [innerProvidersById])

    const [loadedAllProviders, setLoadedAllProviders] = useState(false)
    const [allProvidersLoadingError, setAllProvidersLoadingError] = useState(undefined as unknown)

    // Effects

    // - Users propagation

    useEffect(() => {
        if (usersChangedRef.current) {
            usersChangedRef.current = false
            onUsersChange?.(innerUsers)
        }
    }, [onUsersChange, innerUsers])

    // - Providers propagation

    useEffect(() => {
        if (providersChangedRef.current) {
            providersChangedRef.current = false
            onProvidersChange?.(innerProviders)
        }
    }, [onProvidersChange, innerProviders])

    // - Values propagation

    useEffect(() => {
        if (changedRef.current) {
            changedRef.current = false
            onChange?.(innerValues)
        }
    }, [onChange, innerValues])

    // Render

    return <Flex align="start"
                 width={width}
                 ref={ref}>
        {renderContent()}
    </Flex>

    function renderContent(): ReactNode {
        if (innerValues.length === 0)
            return renderAddButton()

        if (output || readonly)
            return renderAllProviders()

        return renderAllProvidersWithEditingButtons()
    }

    // - Products

    function renderAllProviders(): ReactNode {
        return innerValues.map((product, index) =>
            <Fragment key={product.id ?? index}>
                {renderProvider(product, index)}
            </Fragment>
        )
    }

    function renderAllProvidersWithEditingButtons(): ReactNode {
        const deleteButtonDisabled = !allowEmpty && innerValues.length === 1

        return innerValues.map((product, index) =>
            <Flex key={product.id ?? index}
                  direction="horizontal">
                {renderProvider(product, index)}

                <div>
                    <Flex width={BUTTONS_FLEX_WIDTH}
                          direction="horizontal">
                        {renderDeleteButton(index, deleteButtonDisabled)}

                        {index === innerValues.length - 1 &&
                            renderAddButton()
                        }
                    </Flex>
                </div>
            </Flex>
        )
    }

    function renderProvider(provider: TransferProvider, index: number = -1): ReactNode {
        return <TransferProviderEditor onChange={newValue => onProviderChange(newValue, index)}
                                       value={provider}

                                       users={innerUsersById}
                                       getAllUsers={innerGetAllUsers}
                                       getUserById={innerGetUserById}

                                       providers={innerProvidersById}
                                       getAllProviders={innerGetAllProviders}
                                       getProviderById={innerGetProviderById}

                                       onRenderModal={onRenderModal}

                                       loading={loading}
                                       disabled={disabled}
                                       readonly={readonly}
                                       required={required}
                                       output={output}/>
    }

    // - Buttons

    function renderAddButton(): ReactNode {
        return <Button onClick={onAdd}

                       iconSrc={plusIconUrl}
                       iconAlt="Plus icon"

                       width={BUTTON_WIDTH}/>
    }

    function renderDeleteButton(providerIndex: number = -1, disabled: boolean = false): ReactNode {
        return <Button onClick={() => onDelete(providerIndex)}

                       iconSrc={trashCanIconUrl}
                       iconAlt="Trash can icon"

                       buttonStyle="outline"
                       width={BUTTON_WIDTH}

                       disabled={disabled}
                       critical/>
    }

    // Events

    function onAdd() {
        setInnerValues(oldValues => {
            changedRef.current = true
            return [...oldValues, createNew()]
        })
    }

    function onDelete(providerIndex: number = -1) {
        if (providerIndex < 0)
            return

        setInnerValues(oldValues => {
            changedRef.current = true
            return splicedArray(oldValues, providerIndex, 1)
        })
    }

    function onProviderChange(provider: TransferProvider, index: number = -1) {
        if (index < 0)
            return

        setInnerValues(oldValues => {
            changedRef.current = true
            return splicedArray(oldValues, index, 1, provider)
        })
    }

    // Util

    function createNew(): TransferProvider {
        return new TransferProvider({ percent: 5 })
    }

    // - User

    async function innerGetAllUsers(signal?: AbortSignal | null): Promise<User[]> {
        if (loadedAllUsers)
            return [...innerUsers]

        if (allUsersLoadingError != null)
            throw allUsersLoadingError

        if (allUsersLoadingPromiseRef.current == null)
            allUsersLoadingPromiseRef.current = new Promise(async (resolve, reject) => {
                try {
                    const loadedUsers = await (getAllUsers ?? api.getAllUsers)(signal)

                    usersStorage.addAll(loadedUsers)

                    setLoadedAllUsers(true)

                    setInnerUsersById(oldUsersById => {
                        const newUsersById = new Map(oldUsersById)

                        for (const user of loadedUsers)
                            newUsersById.set(user.id, user)

                        resolve([...newUsersById.values()])

                        usersChangedRef.current = true

                        return newUsersById
                    })
                } catch (error) {
                    setAllUsersLoadingError(error)
                    reject(error)
                }
            })

        return await allUsersLoadingPromiseRef.current
    }

    async function innerGetUserById(id: string, signal?: AbortSignal | null): Promise<User> {
        const oldUser = innerUsersById.get(id)

        if (oldUser != null)
            return oldUser

        const loadedUser = await (getUserById ?? api.getUserById)(id, signal)

        usersStorage.add(loadedUser)

        setInnerUsersById(oldUsersById => {
            const newUsersById = new Map(oldUsersById)

            newUsersById.set(loadedUser.id, loadedUser)

            usersChangedRef.current = true

            return newUsersById
        })

        return loadedUser
    }

    // - Provider

    async function innerGetAllProviders(signal?: AbortSignal | null): Promise<Provider[]> {
        if (loadedAllProviders)
            return [...innerProviders]

        if (allProvidersLoadingError != null)
            throw allProvidersLoadingError

        if (allProvidersLoadingPromiseRef.current == null)
            allProvidersLoadingPromiseRef.current = new Promise(async (resolve, reject) => {
                try {
                    const loadedProviders = await (getAllProviders ?? api.getAllProviders)(signal)

                    setLoadedAllProviders(true)

                    setInnerProvidersById(oldProvidersById => {
                        const newProvidersById = new Map(oldProvidersById)

                        for (const provider of loadedProviders)
                            newProvidersById.set(provider.id, provider)

                        resolve([...newProvidersById.values()])

                        providersChangedRef.current = true

                        return newProvidersById
                    })
                } catch (error) {
                    setAllProvidersLoadingError(error)
                    reject(error)
                }
            })

        return await allProvidersLoadingPromiseRef.current
    }

    async function innerGetProviderById(id: string, signal?: AbortSignal | null): Promise<Provider> {
        const oldProvider = innerProvidersById.get(id)

        if (oldProvider != null)
            return oldProvider

        const loadedProvider = await (getProviderById ?? api.getProviderById)(id, signal)

        setInnerProvidersById(oldProvidersById => {
            const newProvidersById = new Map(oldProvidersById)

            newProvidersById.set(loadedProvider.id, loadedProvider)

            providersChangedRef.current = true

            return newProvidersById
        })

        return loadedProvider
    }
})

TransferProviderListEditor.displayName = "TransferProviderListEditor"

export default TransferProviderListEditor
