import AbstractModelObject,
       { AbstractModelObjectCopyOptions,
         AbstractModelObjectCreationOptions } from "model/AbstractModelObject"

import { arrayOfNonNull, DeepReadonly, tryNormalizeNullableUuid } from "my-util"
import type NotificationType from "./NotificationType"

export interface AbstractNotificationOptionsBase {
    recipientId?: string | null
}

export interface AbstractNotificationCreationOptions<Type extends NotificationType>
    extends
        AbstractModelObjectCreationOptions,
        AbstractNotificationOptionsBase
{
    type: Type
}

export interface AbstractNotificationCopyOptions
    extends
        AbstractModelObjectCopyOptions,
        AbstractNotificationOptionsBase
{}

export default abstract class AbstractNotification<
    Type extends NotificationType,
    CopyOptions extends AbstractModelObjectCopyOptions,
> extends AbstractModelObject<CopyOptions> {
    // Fields

    readonly type: Type
    readonly recipientId: string | null

    // Constructor

    constructor(options: DeepReadonly<AbstractNotificationCreationOptions<Type>>) {
        super(options)

        this.type = options.type as Type
        this.recipientId = tryNormalizeNullableUuid(options.recipientId)
    }

    // User IDs

    get userIds(): string[] {
        return arrayOfNonNull(this.recipientId)
    }
}
