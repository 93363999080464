import { t } from "i18next"
import { Key } from "react"
import { AnonymousChatMessage, ChatMessage, User } from "model"
import { DeepReadonly } from "my-util"
import { UiDocument } from "../document"

// Chat

export type ReadonlyUiChat = DeepReadonly<UiChat>

export interface UiChat {
    messages: UiChatMessage[]
    unreadCount?: number
    user: User
}

// Message

export type ReadonlyUiChatMessage = DeepReadonly<UiChatMessage>

export interface UiChatMessage {
    id?: Key | null
    status?: UiChatMessageStatus | null
    senderId?: Key | null
    sender?: UiChatMessageSender | null
    text?: string | null
    documents?: UiDocument[] | null
    date: Date
    local?: boolean | null
    edited?: boolean | null
}

export type UiChatMessageStatus =
    | "read"
    | "sent"
    | "sending"
    | "error"

export type UiChatMessageSender =
    | (() => UiChatMessageSenderValue)
    | UiChatMessageSenderValue

export type UiChatMessageSenderValue =
    | UiChatMessageMultiLangSender
    | string

export interface UiChatMessageMultiLangSender {
    ruName: string
    enName: string
}

// Conversions

export interface ChatMessageToUiOptions {
    usersById?: Map<string, User> | null
    localUser?: User | null
}

export function chatMessageToUi(
    message: ChatMessage | AnonymousChatMessage,
    { usersById, localUser }: DeepReadonly<ChatMessageToUiOptions> = {},
): UiChatMessage {
    return {
        id: id(),
        status: status(),
        senderId: senderId(),
        sender: sender(),
        text: text(),
        documents: documents(),
        date: date(),
        local: local(),
        edited: edited(),
    }

    function id(): Key | undefined | null {
        return message.id
    }

    function status(): UiChatMessageStatus {
        if (localUser == null)
            return "sent"

        if (message.readByIds.has(localUser.id))
            return "read"

        if (message instanceof AnonymousChatMessage)
            return "sent"

        if (message.creatorId === localUser.id && message.recipientId === localUser.id)
            return "read"

        if (message.creatorId === localUser.id && message.readByIds.size > 0)
            return "read"

        return "sent"
    }

    function senderId(): Key | undefined | null {
        return message instanceof ChatMessage
            ? message.creatorId
            : null
    }

    function sender(): UiChatMessageSender | undefined | null {
        return message instanceof ChatMessage
            ? message.creatorId === localUser?.id
                ? () => t("misc.words.you")
                : message.creatorId != null
                    ? usersById?.get(message.creatorId)
                    : null
            : message.creator
    }

    function text(): string | undefined | null {
        return message.text
    }

    function documents(): UiDocument[] {
        return message.documentIds.map(id => ({ id, status: "loading" }))
    }

    function date(): Date {
        return new Date(message.createdAt.getTime())
    }

    function local(): boolean {
        return message instanceof ChatMessage
            ? message.creatorId === localUser?.id
            : false
    }

    function edited(): boolean {
        return message.edited
    }
}
