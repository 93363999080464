import { getLang } from "i18n"
import { Named, NamedFields } from "model/interfaces"
import { Constructor, makeFormalName, makeName } from "my-util"

export default function NameMixin<BaseConstructor extends Constructor<NamedFields>>(
    Base: BaseConstructor,
) {
    return class extends Base implements Named {
        private cachedEnName: string | null = null
        private cachedRuName: string | null = null

        private cachedEnFormalName: string | null = null
        private cachedRuFormalName: string | null = null

        // En

        get enName(): string {
            if (this.cachedEnName == null)
                this.cachedEnName = makeName(this.enFirstname, this.enLastname, this.enPatronymic)

            return this.cachedEnName
        }

        get enFormalName(): string {
            if (this.cachedEnFormalName == null)
                this.cachedEnFormalName = makeFormalName(this.enFirstname, this.enPatronymic)

            return this.cachedEnFormalName
        }

        // Ru

        get ruName(): string {
            if (this.cachedRuName == null)
                this.cachedRuName = makeName(this.ruFirstname, this.ruLastname, this.ruPatronymic)

            return this.cachedRuName
        }

        get ruFormalName(): string {
            if (this.cachedRuFormalName == null)
                this.cachedRuFormalName = makeFormalName(this.ruFirstname, this.ruPatronymic)

            return this.cachedRuFormalName
        }

        // Current locale

        get firstname(): string {
            return getLang() === "ru"
                ? this.ruFirstname
                : this.enFirstname
        }

        get lastname(): string {
            return getLang() === "ru"
                ? this.ruLastname
                : this.enLastname
        }

        get patronymic(): string | null {
            return getLang() === "ru"
                ? this.ruPatronymic
                : this.enPatronymic
        }

        get name(): string {
            return getLang() === "ru"
                ? this.ruName
                : this.enName
        }

        get formalName(): string {
            return getLang() === "ru"
                ? this.ruFormalName
                : this.enFormalName
        }
    }
}
