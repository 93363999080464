import { useTranslation } from "react-i18next"
import { ForwardedRef, forwardRef, ReactNode, useMemo } from "react"
import { crossIconUrl } from "image"

import { Notification, User, Invite,
         NewChatMessagesNotification,
         NewInviteApplicationNotification,
         Provider, NewProviderNotification,
         NewTransferNotification, Transfer,
         TransferStatusChangedNotification,
         NewAnonymousChatMessagesNotification,
         InviteApplication, NewInviteNotification,
         NewUserNotification, getTransferStatusName,

         NEW_INVITE_NOTIFICATION_TYPE,
         NEW_USER_NOTIFICATION_TYPE,
         NEW_TRANSFER_NOTIFICATION_TYPE,
         NEW_PROVIDER_NOTIFICATION_TYPE ,
         NEW_CHAT_MESSAGES_NOTIFICATION_TYPE,
         NEW_INVITE_APPLICATION_NOTIFICATION_TYPE,
         TRANSFER_STATUS_CHANGED_NOTIFICATION_TYPE } from "model"

import { DeepReadonly } from "my-util"
import { UserLink } from "ui/component/user"
import { EventTime, Button, MessageTarget } from "ui/ui"
import style from "./style.module.css"

export interface NotificationCardProps {
    onClick?: (Notification: Notification) => void
    onDelete?: (notification: Notification) => void

    notification: Notification

    inviteApplications?: InviteApplication[] | Map<string, InviteApplication>
    invites?: Invite[] | Map<string, Invite>
    users?: User[] | Map<string, User>
    transfers?: Transfer[] | Map<string, Transfer>
    providers?: Provider[] | Map<string, Provider>

    width?: string
    height?: string

    loading?: boolean
    disabled?: boolean
}

const NotificationCard = forwardRef((
    {
        onClick, onDelete,
        notification,
        inviteApplications, invites, users, transfers, providers,
        width, height,
        loading, disabled,
    }: DeepReadonly<NotificationCardProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    // State

    const usersById = useMemo(
        () => User.groupByIdOrPassOrCreate(users),
        [users],
    )

    const inviteApplicationsById = useMemo(
        () => InviteApplication.groupByIdOrPassOrCreate(inviteApplications),
        [inviteApplications],
    )

    const invitesById = useMemo(
        () => Invite.groupByIdOrPassOrCreate(invites),
        [invites],
    )

    const transferById = useMemo(
        () => Transfer.groupByIdOrPassOrCreate(transfers),
        [transfers],
    )

    const providersById = useMemo(
        () => Provider.groupByIdOrPassOrCreate(providers),
        [providers],
    )

    // Render

    return <div onClick={() => onClick?.(notification)}
                className={style.NotificationCard}
                style={{ width, height }}
                ref={ref}>
        <div className={style.message}>
            {renderMessage(notification)}
        </div>

        <div onClick={event => event.stopPropagation()}
             className={style.delete}>
            <Button onClick={() => onDelete?.(notification)}

                    iconSrc={crossIconUrl}
                    iconAlt="Cross icon"

                    buttonStyle="text"
                    fontSize="12px"

                    width="24px"
                    height="24px"

                    loading={loading}
                    disabled={disabled}/>
        </div>

        <div className={style.time}>
            <EventTime time={notification.modifiedAt}/>
        </div>
    </div>

    function renderMessage(notification: Notification): ReactNode {
        switch (notification.type) {
            case NEW_CHAT_MESSAGES_NOTIFICATION_TYPE:
                return renderNewChatMessagesMessage(notification)

            case NEW_INVITE_APPLICATION_NOTIFICATION_TYPE:
                return renderNewInviteApplicationMessage(notification)

            case NEW_INVITE_NOTIFICATION_TYPE:
                return renderNewInviteMessage(notification)

            case NEW_TRANSFER_NOTIFICATION_TYPE:
                return renderNewTransferMessage(notification)

            case NEW_USER_NOTIFICATION_TYPE:
                return renderNewUserMessage(notification)

            case NEW_PROVIDER_NOTIFICATION_TYPE:
                return renderNewProviderMessage(notification)

            case TRANSFER_STATUS_CHANGED_NOTIFICATION_TYPE:
                return renderTransferStatusChangedMessage(notification)

            default:
                notification satisfies never
        }
    }

    function renderNewChatMessagesMessage(
        notification:
            | NewAnonymousChatMessagesNotification
            | NewChatMessagesNotification
    ): ReactNode {
        const { count } = notification

        const fromId = notification instanceof NewChatMessagesNotification
            ? notification.fromId
            : null

        if (fromId == null)
            return t("notifications.messages.youHaveNewMessages", { count })

        const from = usersById.get(fromId)

        const fromName = from != null
            ? <UserLink stopClickPropagation
                        user={from}/>

            : t("misc.words.unknown")

        return <>
            {fromName}
            {" "}
            {t("notifications.messages.sentYouNewMessages", { count })}
        </>
    }

    function renderNewInviteApplicationMessage(
        notification: NewInviteApplicationNotification,
    ): ReactNode {
        const { inviteApplicationId } = notification

        const inviteApplication = inviteApplicationId != null
            ? inviteApplicationsById.get(inviteApplicationId)
            : undefined

        return <>
            {t("notifications.messages.newInviteApplication")}

            {inviteApplication != null && <>
                {" "}
                {t("misc.words.from").toLowerCase()}
                {" "}

                <MessageTarget messageTarget={inviteApplication.messageTarget}
                               stopLinkClickPropagation/>
            </>}
        </>
    }

    function renderNewInviteMessage(notification: NewInviteNotification): ReactNode {
        const { inviteId } = notification

        const invite = inviteId != null
            ? invitesById.get(inviteId)
            : undefined

        const creator = invite?.creatorId != null
            ? usersById.get(invite.creatorId)
            : undefined

        return <>
            {creator != null
                ? <>
                    <UserLink stopClickPropagation
                              user={creator}/>

                    {" "}
                    {t("notifications.messages.someoneCreatedNewInvite")}
                </>

                : t("notifications.messages.newInvite")
            }

            {invite != null && <>
                {" "}
                {t("misc.words.for").toLowerCase()}
                {" "}

                <MessageTarget messageTarget={invite.messageTarget}
                                stopLinkClickPropagation/>
            </>}
        </>
    }

    function renderNewTransferMessage(notification: NewTransferNotification): ReactNode {
        const { transferId } = notification

        const transfer = transferId != null
            ? transferById.get(transferId)
            : undefined

        const creator = transfer?.creatorId != null
            ? usersById.get(transfer.creatorId)
            : undefined

        return <>
            {t("notifications.messages.newTransfer")}

            {creator != null && <>
                {" "}
                {t("misc.words.from").toLowerCase()}
                {" "}

                <UserLink stopClickPropagation
                          user={creator}/>
            </>}
        </>
    }

    function renderNewUserMessage(notification: NewUserNotification): ReactNode {
        const { userId } = notification

        const user = userId != null
            ? usersById.get(userId)
            : undefined

        return user != null
            ? <>
                <UserLink stopClickPropagation
                          user={user}/>

                {" "}
                {t("notifications.messages.someNewUser")}
            </>

            : t("notifications.messages.newUser")
    }

    function renderNewProviderMessage(notification: NewProviderNotification): ReactNode {
        const { providerId } = notification

        const provider = providerId != null
            ? providersById.get(providerId)
            : undefined

        const creator = provider?.creatorId != null
            ? usersById.get(provider.creatorId)
            : undefined

        return creator != null
            ? <>
                <UserLink stopClickPropagation
                          user={creator}/>

                {" "}
                {t("notifications.messages.someoneCreatedNewProvider")}
            </>

            : t("notifications.messages.newProvider")
    }

    function renderTransferStatusChangedMessage(
        notification: TransferStatusChangedNotification,
    ): ReactNode {
        const { from, to } = notification

        return <>
            {t("notifications.messages.transferStatusChangedFrom")}
            {" "}

            <em className={style.emphasis}>
                {getTransferStatusName(from).toLowerCase()}
            </em>

            {" "}
            {t("misc.words.to").toLowerCase()}
            {" "}

            <em className={style.emphasis}>
                {getTransferStatusName(to).toLowerCase()}
            </em>
        </>
    }
})

NotificationCard.displayName = "NotificationCard"

export default NotificationCard
