import { EMAIL_REGEX } from "my-util"
import ViolationType from "./ViolationType"

export const MIN_EMAIL_LENGTH = 3
export const MAX_EMAIL_LENGTH = 320

export function validateEmail(email: string): ViolationType | null {
    email = email.trim()

    if (email.length > MAX_EMAIL_LENGTH)
        return "too-long"

    if (email.length < MIN_EMAIL_LENGTH)
        return "too-short"

    if (email.match(EMAIL_REGEX) == null)
        return "bad-syntax"

    return null
}
