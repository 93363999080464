import { z } from "zod"
import { WithManyMessageTargetsResponseSchema } from "api/response/common"
import { ModelObjectResponseSchema } from "../model-object"
import { AnonymousUserResponseSchema } from "./anonymous-user"
import { WithCreatorIdResponseSchema } from "./with-creator-id"
import { WithCompanyNameResponseSchema } from "./with-company-name"

export const ProviderResponseSchema = ModelObjectResponseSchema
    .merge(WithCreatorIdResponseSchema)
    .merge(WithManyMessageTargetsResponseSchema)
    .merge(AnonymousUserResponseSchema)
    .merge(WithCompanyNameResponseSchema)

export type ProviderResponse = z.infer<typeof ProviderResponseSchema>
