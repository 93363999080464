import { t } from "i18next"

// Types

type TransferStatus =
    | NewTransferStatus
    | WaitingTransferStatus
    | PaidTransferStatus
    | DoneTransferStatus
    | DeclinedTransferStatus

export default TransferStatus

export type WaitingTransferStatus =
    | WaitingMomentTransferStatus
    | WaitingPaymentTransferStatus

export type DeclinedTransferStatus =
    | PaymentExpiredTransferStatus
    | RejectedTransferStatus

export type NewTransferStatus = typeof NEW_TRANSFER_STATUS
export type WaitingMomentTransferStatus = typeof WAITING_MOMENT_TRANSFER_STATUS
export type WaitingPaymentTransferStatus = typeof WAITING_PAYMENT_TRANSFER_STATUS
export type PaidTransferStatus = typeof PAID_TRANSFER_STATUS
export type DoneTransferStatus = typeof DONE_TRANSFER_STATUS
export type PaymentExpiredTransferStatus = typeof PAYMENT_EXPIRED_TRANSFER_STATUS
export type RejectedTransferStatus = typeof REJECTED_TRANSFER_STATUS

// Consts

export const NEW_TRANSFER_STATUS = "new"
export const WAITING_MOMENT_TRANSFER_STATUS = "waiting-moment"
export const WAITING_PAYMENT_TRANSFER_STATUS = "waiting-payment"
export const PAID_TRANSFER_STATUS = "paid"
export const DONE_TRANSFER_STATUS = "done"
export const PAYMENT_EXPIRED_TRANSFER_STATUS = "payment-expired"
export const REJECTED_TRANSFER_STATUS = "rejected"

// Name

export function getTransferStatusName(status: TransferStatus): string {
    return t(`domain.transfers.statuses.${status}`)
}

// Is-check

export function isTransferStatus(s: string): s is TransferStatus {
    switch (s) {
        case NEW_TRANSFER_STATUS:
        case WAITING_MOMENT_TRANSFER_STATUS:
        case WAITING_PAYMENT_TRANSFER_STATUS:
        case PAID_TRANSFER_STATUS:
        case DONE_TRANSFER_STATUS:
        case PAYMENT_EXPIRED_TRANSFER_STATUS:
        case REJECTED_TRANSFER_STATUS:
            s satisfies TransferStatus
            return true

        default:
            return false
    }
}

export function isTransferStatusWaiting(status: TransferStatus): status is WaitingTransferStatus {
    return status === WAITING_MOMENT_TRANSFER_STATUS
        || status === WAITING_PAYMENT_TRANSFER_STATUS
}

export function isTransferStatusDeclined(status: TransferStatus): status is DeclinedTransferStatus {
    return status === REJECTED_TRANSFER_STATUS
        || status === PAYMENT_EXPIRED_TRANSFER_STATUS
}

// Color

export interface TransferStatusColors {
    backgroundColor: string
    borderColor: string
    color: string
}

export const NEW_TRANSFER_STATUS_COLORS: Readonly<TransferStatusColors> = {
    backgroundColor: "#F5F9FF",
    borderColor: "#2D6FCC",
    color: "#2D6FCC",
}

export const WAITING_MOMENT_TRANSFER_STATUS_COLORS: Readonly<TransferStatusColors> = NEW_TRANSFER_STATUS_COLORS

export const WAITING_PAYMENT_TRANSFER_STATUS_COLORS: Readonly<TransferStatusColors> = NEW_TRANSFER_STATUS_COLORS

export const PAID_TRANSFER_STATUS_COLORS: Readonly<TransferStatusColors> = {
    backgroundColor: "#E6F4EA",
    borderColor: "#E6F4EA",
    color: "#08922E",
}

export const DONE_TRANSFER_STATUS_COLORS: Readonly<TransferStatusColors> = PAID_TRANSFER_STATUS_COLORS

export const PAYMENT_EXPIRED_TRANSFER_STATUS_COLORS: Readonly<TransferStatusColors> = {
    backgroundColor: "#F2F2F2",
    borderColor: "#F2F2F2",
    color: "#333",
}

export const REJECTED_TRANSFER_STATUS_COLORS: Readonly<TransferStatusColors> = PAYMENT_EXPIRED_TRANSFER_STATUS_COLORS

export function getTransferStatusColors(status: TransferStatus): TransferStatusColors {
    switch (status) {
        case NEW_TRANSFER_STATUS:
            return { ...NEW_TRANSFER_STATUS_COLORS }

        case WAITING_MOMENT_TRANSFER_STATUS:
            return { ...WAITING_MOMENT_TRANSFER_STATUS_COLORS }

        case WAITING_PAYMENT_TRANSFER_STATUS:
            return { ...WAITING_PAYMENT_TRANSFER_STATUS_COLORS }

        case PAID_TRANSFER_STATUS:
            return { ...PAID_TRANSFER_STATUS_COLORS }

        case DONE_TRANSFER_STATUS:
            return { ...DONE_TRANSFER_STATUS_COLORS }

        case PAYMENT_EXPIRED_TRANSFER_STATUS:
            return { ...PAYMENT_EXPIRED_TRANSFER_STATUS_COLORS }

        case REJECTED_TRANSFER_STATUS:
            return { ...REJECTED_TRANSFER_STATUS_COLORS }
    }
}
