import { DeepReadonly, Nullish, tryNormalizeNullableUuid } from "my-util"

import AbstractNotification,
       { AbstractNotificationOptionsBase,
         AbstractNotificationCopyOptions,
         AbstractNotificationCreationOptions } from "./AbstractNotification"

import NotificationType,
       { NewTransferNotificationType,
         NEW_TRANSFER_NOTIFICATION_TYPE } from "./NotificationType"

export interface NewTransferNotificationOptionsBase extends AbstractNotificationOptionsBase {
    transferId?: string | null
}

export interface NewTransferNotificationCreationOptions
    extends
        Omit<AbstractNotificationCreationOptions<NotificationType>, "type">,
        NewTransferNotificationOptionsBase
{}

export interface NewTransferNotificationCopyOptions
    extends
        Omit<AbstractNotificationCopyOptions, "type">,
        Nullish<NewTransferNotificationOptionsBase>
{}

export default class NewTransferNotification extends AbstractNotification<
    NewTransferNotificationType,
    NewTransferNotificationCopyOptions
> {
    static createOrPass(
        arg?: NewTransferNotification
            | DeepReadonly<NewTransferNotificationCreationOptions>
            | null
    ): NewTransferNotification {
        return arg instanceof NewTransferNotification
            ? arg
            : new NewTransferNotification(arg ?? {})
    }

    // Fields

    readonly transferId: string | null

    // Constructor

    constructor(options: DeepReadonly<NewTransferNotificationCreationOptions> = {}) {
        super({ ...options, type: NEW_TRANSFER_NOTIFICATION_TYPE })

        this.transferId = tryNormalizeNullableUuid(options.transferId)
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<NewTransferNotificationCopyOptions> = {},
    ): NewTransferNotification {
        return new NewTransferNotification({
            // Recipient ID

            recipientId: "recipientId" in options
                ? options.recipientId
                : this.recipientId,

            // Content

            transferId: "transferId" in options
                ? options.transferId
                : this.transferId,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
