import { DeepReadonly } from "my-util"

export default abstract class Copyable<CopyOptions> {
    copyOrPass(arg?: this | DeepReadonly<CopyOptions> | null): this {
        if (arg == null)
            return this

        if (arg instanceof (this as any).__proto__.constructor)
            return arg as this

        return this.copy(arg as DeepReadonly<CopyOptions>)
    }

    copy(options?: DeepReadonly<CopyOptions>): this {
        const copy = this.createCopy(options)

        ;(copy as any).__proto__ = (this as any).__proto__

        return copy as never as this
    }

    protected abstract createCopy(options?: DeepReadonly<CopyOptions>): Copyable<CopyOptions>
}
