import Decimal from "decimal.js"
import { WithCreatorId } from "model/interfaces"

import AbstractModelObject,
       { AbstractModelObjectCreationOptions,
         AbstractModelObjectCopyOptions } from "model/AbstractModelObject"

import { dateWithTimeNulled, DeepReadonly, nullDateTime, map,
         tryCopyDate, tryNormalizeUuid, ReadonlyDate, Nullable,
         createOrPassNullableDecimal, tryNormalizeNullableUuid, Nullish,
         collapseWhiteSpace, removeWhiteSpaceToNull, collapseSpacesToNull } from "my-util"

import CurrencyRate,
       { CurrencyRateCreationOptions,
         CurrencyRateCopyOptions } from "../CurrencyRate"

import Money,
       { MoneyCreationOptions,
         MoneyCopyOptions } from "../Money"

import Product,
       { ProductCreationOptions,
         ProductCopyOptions } from "../Product"

import RoutePoint,
       { RoutePointCreationOptions,
         RoutePointCopyOptions } from "../RoutePoint"

import TransferProvider,
       { TransferProviderCreationOptions,
         TransferProviderCopyOptions } from "../TransferProvider"

import LegalDocument,
       { LegalDocumentCreationOptions,
         LegalDocumentCopyOptions } from "../LegalDocument"

import SignableDocument,
      { SignableDocumentCopyOptions,
        SignableDocumentCreationOptions } from "../SignableDocument"

import TransferStatus,
       { NEW_TRANSFER_STATUS, PAID_TRANSFER_STATUS,
         WAITING_MOMENT_TRANSFER_STATUS, WAITING_PAYMENT_TRANSFER_STATUS } from "./TransferStatus"

import TransferDirection,
       { IMPORT_TRANSFER_DIRECTION } from "./TransferDirection"

export interface TransferCreationOptions
    extends
        AbstractModelObjectCreationOptions,
        Nullish<WithCreatorId>
{
    // Special user IDs

    creatorId?: string | null

    requisitesSourceId?: string | null

    stopperId?: string | null
    isStopped?: boolean | null

    // Basic info

    status?: TransferStatus | null
    direction?: TransferDirection | null
    country: string
    recipient: {
        name: string
        itn?: string | null
        paymentAccount?: string | null
        correspondentAccount?: string | null
        iban?: string | null
        bank: {
            name: string
            address: string
            swift: string
        }
    }

    // Moment

    moment?: Date | null

    // Money

    money?: Money | MoneyCreationOptions | null
    currencyRate?: CurrencyRate | CurrencyRateCreationOptions | null

    // Special documents

    invoice: LegalDocument | LegalDocumentCreationOptions
    contract: LegalDocument | LegalDocumentCreationOptions

    // Payments

    cost?: Money | MoneyCreationOptions | null
    expenses?: Money | MoneyCreationOptions | null
    agentPercent?: Decimal.Value | null
    attorneyFee?: Decimal.Value | null
    agentPayedAt?: Date | null

    // Comments

    comment?: string | null
    adminComment?: string | null
    lawyerComment?: string | null
    accountantComment?: string | null

    // Collections

    documentIds?: Iterable<string> | null
    orders?: Iterable<SignableDocument | SignableDocumentCreationOptions> | null
    products?: Iterable<Product | ProductCreationOptions> | null
    routePoints?: Iterable<RoutePoint | RoutePointCreationOptions> | null
    providers?: Iterable<TransferProvider | TransferProviderCreationOptions> | null
}

export interface TransferCopyOptions
    extends
        AbstractModelObjectCopyOptions,
        Nullish<WithCreatorId>
{
    // Special user IDs

    creatorId?: string | null

    requisitesSourceId?: string | null

    stopperId?: string | null
    isStopped?: boolean | null

    // Basic info

    status?: TransferStatus | null
    direction?: TransferDirection | null
    country?: string | null
    recipient?: {
        name?: string | null
        itn?: string | null
        paymentAccount?: string | null
        correspondentAccount?: string | null
        iban?: string | null
        bank?: {
            name?: string | null
            address?: string | null
            swift?: string | null
        } | null
    } | null

    // Moment

    moment?: Date | null

    // Money

    money?: Money | MoneyCopyOptions | null
    currencyRate?: CurrencyRate | CurrencyRateCopyOptions | null

    // Special documents

    invoice?: LegalDocument | LegalDocumentCopyOptions | null
    contract?: LegalDocument | LegalDocumentCopyOptions | null

    // Payments

    cost?: Money | MoneyCopyOptions | null
    expenses?: Money | MoneyCopyOptions | null
    agentPercent?: Decimal | number | string | null
    attorneyFee?: Decimal | number | string | null
    agentPayedAt?: Date | null

    // Comments

    comment?: string | null
    adminComment?: string | null
    lawyerComment?: string | null
    accountantComment?: string | null

    // Collections

    documentIds?: Iterable<string> | null
    orders?: Iterable<SignableDocument | SignableDocumentCopyOptions> | null
    products?: Iterable<Product | ProductCopyOptions> | null
    routePoints?: Iterable<RoutePoint | RoutePointCopyOptions> | null
    providers?: Iterable<TransferProvider | TransferProviderCopyOptions> | null
}

export default class Transfer
    extends
        AbstractModelObject<TransferCopyOptions>

    implements
        Readonly<Nullable<WithCreatorId>>
{
    static createOrPass(arg: Transfer | DeepReadonly<TransferCreationOptions>): Transfer {
        return arg instanceof Transfer
            ? arg
            : new Transfer(arg)
    }

    // Fields

    // - Special user IDs

    readonly creatorId: string | null

    readonly requisitesSourceId: string | null

    readonly stopperId: string | null
    readonly isStopped: boolean

    // - Common

    readonly status: TransferStatus
    readonly direction: TransferDirection
    readonly country: string
    readonly recipient: Readonly<{
        name: string
        itn: string | null
        paymentAccount: string | null
        correspondentAccount: string | null
        iban: string | null
        bank: Readonly<{
            name: string
            address: string
            swift: string
        }>
    }>

    // - Moment

    readonly moment: ReadonlyDate | null

    // - Money

    readonly money: Money
    readonly currencyRate: CurrencyRate | null

    // - Special documents

    readonly invoice: LegalDocument
    readonly contract: LegalDocument

    // - Payments

    readonly cost: Money | null
    readonly expenses: Money | null
    readonly agentPercent: Decimal | null
    readonly attorneyFee: Decimal | null
    readonly agentPayedAt: ReadonlyDate | null

    // - Comments

    readonly comment: string | null
    readonly adminComment: string | null
    readonly lawyerComment: string | null
    readonly accountantComment: string | null

    // - Collections

    readonly documentIds: readonly string[]
    readonly orders: readonly SignableDocument[]
    readonly products: readonly Product[]
    readonly routePoints: readonly RoutePoint[]
    readonly providers: readonly TransferProvider[] | null

    // Constructor

    constructor(options: DeepReadonly<TransferCreationOptions>) {
        super(options)

        // Special user IDs

        this.creatorId = tryNormalizeNullableUuid(options.creatorId)

        this.requisitesSourceId = tryNormalizeNullableUuid(options.requisitesSourceId)

        this.stopperId = tryNormalizeNullableUuid(options.stopperId)
        this.isStopped = options.isStopped ?? this.stopperId != null

        // Common

        this.status = options.status ?? NEW_TRANSFER_STATUS
        this.direction = options.direction ?? IMPORT_TRANSFER_DIRECTION
        this.country = collapseWhiteSpace(options.country)

        this.recipient = {
            name: collapseWhiteSpace(options.recipient.name),
            itn: removeWhiteSpaceToNull(options.recipient.itn ?? ""),
            paymentAccount: removeWhiteSpaceToNull(options.recipient.paymentAccount ?? ""),

            correspondentAccount: removeWhiteSpaceToNull(options.recipient.correspondentAccount ?? "")
                ?.toUpperCase() ?? null,

            iban: removeWhiteSpaceToNull(options.recipient.iban ?? ""),

            bank: {
                name: collapseWhiteSpace(options.recipient.bank.name),
                address: collapseWhiteSpace(options.recipient.bank.address),
                swift: collapseWhiteSpace(options.recipient.bank.swift),
            },
        }

        // Moment

        this.moment = tryCopyDate(options.moment)

        // Money

        this.money = Money.createOrPass(options.money)

        this.currencyRate = options.currencyRate != null
            ? CurrencyRate.createOrPass(options.currencyRate)
            : null

        // Special documents

        this.invoice = LegalDocument.createOrPass(options.invoice)
        this.contract = LegalDocument.createOrPass(options.contract)

        // Payments

        this.cost = options.cost != null
            ? Money.createOrPass(options.cost)
            : null

        this.expenses = options.expenses != null
            ? Money.createOrPass(options.expenses)
            : null

        this.agentPercent = createOrPassNullableDecimal(options.agentPercent)
        this.attorneyFee = createOrPassNullableDecimal(options.attorneyFee)
        this.agentPayedAt = tryCopyDate(options.agentPayedAt)

        // Comments

        this.comment = collapseSpacesToNull(options.comment ?? "")
        this.adminComment = collapseSpacesToNull(options.adminComment ?? "")
        this.lawyerComment = collapseSpacesToNull(options.lawyerComment ?? "")
        this.accountantComment = collapseSpacesToNull(options.accountantComment ?? "")

        // Collections

        this.documentIds = map(options.documentIds ?? [], tryNormalizeUuid)
        this.orders = map(options.orders ?? [], SignableDocument.createOrPass)
        this.products = map(options.products ?? [], Product.createOrPass)
        this.routePoints = map(options.routePoints ?? [], RoutePoint.createOrPass)

        this.providers = options.providers != null
            ? map(options.providers, TransferProvider.createOrPass)
            : null
    }

    // Status

    get isToday(): boolean {
        if (this.moment == null)
            return false

        const today = nullDateTime(new Date()).getTime()
        const momentDay = dateWithTimeNulled(this.moment).getTime()

        return today === momentDay
    }

    get isInWork(): boolean {
        switch (this.status) {
            case WAITING_MOMENT_TRANSFER_STATUS:
            case WAITING_PAYMENT_TRANSFER_STATUS:
            case PAID_TRANSFER_STATUS:
                return true

            default:
                return false
        }
    }

    // Documents

    get allDocumentIds(): readonly string[] {
        const allDocumentIds = [...this.documentIds]

        for (const { initialId, signedId } of this.orders) {
            if (initialId != null)
                allDocumentIds.push(initialId)

            if (signedId != null)
                allDocumentIds.push(signedId)
        }

        for (const routePoint of this.routePoints)
            allDocumentIds.push(...routePoint.documentIds)

        if (this.currencyRate?.evidenceId != null)
            allDocumentIds.push(this.currencyRate.evidenceId)

        if (this.invoice.documentId != null)
            allDocumentIds.push(this.invoice.documentId)

        if (this.contract.documentId != null)
            allDocumentIds.push(this.contract.documentId)

        return allDocumentIds
    }

    // Copy

    protected override createCopy(options: DeepReadonly<TransferCopyOptions> = {}): Transfer {
        return new Transfer({
            // Special user IDs

            creatorId: "creatorId" in options
                ? options.creatorId
                : this.creatorId,

            requisitesSourceId: "requisitesSourceId" in options
                ? options.requisitesSourceId
                : this.requisitesSourceId,

            stopperId: "stopperId" in options
                ? options.stopperId
                : this.stopperId,

            isStopped: options.isStopped ?? this.isStopped,

            // Common

            status: options.status ?? this.status,
            direction: options.direction ?? this.direction,
            country: options.country ?? this.country,

            recipient: options.recipient != null
                ? {
                    name: options.recipient.name ?? this.recipient.name,
                    itn: options.recipient.itn ?? this.recipient.itn,

                    paymentAccount: "paymentAccount" in options.recipient
                        ? options.recipient.paymentAccount
                        : this.recipient.paymentAccount,

                    correspondentAccount: "correspondentAccount" in options.recipient
                        ? options.recipient.correspondentAccount
                        : this.recipient.correspondentAccount,

                    iban: "iban" in options.recipient
                        ? options.recipient.iban
                        : this.recipient.iban,

                    bank: options.recipient.bank != null
                        ? {
                            name: options.recipient.bank.name ?? this.recipient.bank.name,
                            address: options.recipient.bank.address ?? this.recipient.bank.address,
                            swift: options.recipient.bank.swift ?? this.recipient.bank.swift,
                        }

                        : this.recipient.bank,
                }

                : this.recipient,

            // Moment

            moment: "moment" in options
                ? options.moment
                : this.moment,

            // Money

            money: this.money.copyOrPass(options.money),

            currencyRate: "currencyRate" in options
                ? options.currencyRate != null
                    ? (this.currencyRate?.copyOrPass(options.currencyRate) ?? options.currencyRate)
                    : null
                : this.currencyRate,

            // Special documents

            invoice: this.invoice.copyOrPass(options.invoice),
            contract: this.contract.copyOrPass(options.contract),

            // Payments

            cost: "cost" in options
                ? options.cost != null
                    ? (this.cost?.copyOrPass(options.cost) ?? options.cost)
                    : null
                : this.cost,

            expenses: "expenses" in options
                ? options.expenses != null
                    ? (this.expenses?.copyOrPass(options.expenses) ?? options.expenses)
                    : null
                : this.expenses,

            agentPercent: "agentPercent" in options
                ? options.agentPercent
                : this.agentPercent,

            attorneyFee: "attorneyFee" in options
                ? options.attorneyFee
                : this.attorneyFee,

            agentPayedAt: "agentPayedAt" in options
                ? options.agentPayedAt
                : this.agentPayedAt,

            // Comments

            comment: "comment" in options
                ? options.comment
                : this.comment,

            adminComment: "adminComment" in options
                ? options.adminComment
                : this.adminComment,

            lawyerComment: "lawyerComment" in options
                ? options.lawyerComment
                : this.lawyerComment,

            accountantComment: "accountantComment" in options
                ? options.accountantComment
                : this.accountantComment,

            // Collections

            documentIds: options.documentIds ?? this.documentIds,
            orders: this.copyOrders(options.orders),
            products: this.copyProducts(options.products),
            routePoints: this.copyRoutePoints(options.routePoints),
            providers: this.copyProviders(options.providers),

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }

    private copyOrders(
        newOrders?: DeepReadonly<TransferCopyOptions["orders"]>,
    ): readonly SignableDocument[] {
        return Transfer.copyCollection(
            this.orders,
            newOrders,
            (value): value is SignableDocument => value instanceof SignableDocument,
        )
    }

    private copyProducts(
        newProducts?: DeepReadonly<TransferCopyOptions["products"]>,
    ): readonly Product[] {
        return Transfer.copyCollection(
            this.products,
            newProducts,
            (value): value is Product => value instanceof Product,
        )
    }

    private copyRoutePoints(
        newRoutePoints?: DeepReadonly<TransferCopyOptions["routePoints"]>,
    ): readonly RoutePoint[] {
        return Transfer.copyCollection(
            this.routePoints,
            newRoutePoints,
            (value): value is RoutePoint => value instanceof RoutePoint,
        )
    }

    private copyProviders(
        newProviders?: DeepReadonly<TransferCopyOptions["providers"]>,
    ): readonly TransferProvider[] | null {
        return this.providers != null
            ? Transfer.copyCollection(
                this.providers,
                newProviders,
                (value): value is TransferProvider => value instanceof TransferProvider,
            )

            : null
    }

    private static copyCollection<
        Item extends { copy(options: CopyOptions): Item },
        CopyOptions,
    >(
        oldItems: readonly Item[],
        newItemsOrCopyOptions: Iterable<Item | CopyOptions> | undefined | null,
        isItem: (value?: Item | CopyOptions | null) => value is Item,
    ): Item[] {
        if (newItemsOrCopyOptions == null)
            return [...oldItems]

        const result = new Array<Item>()
        const newItemsOrCopyOptionsArray = [...newItemsOrCopyOptions]

        for (
            let max = Math.max(newItemsOrCopyOptionsArray.length, oldItems.length),
                i = 0;

            i < max;

            ++i
        ) {
            const newItemOrCopyOptions = newItemsOrCopyOptionsArray.at(i)

            if (isItem(newItemOrCopyOptions)) {
                result.push(newItemOrCopyOptions)
                continue
            }

            const oldItem: Item | undefined = oldItems[i]

            if (newItemOrCopyOptions == null) {
                // Should be always true here
                // but in order to be 100% safe
                // i'll leave this if-statement

                if (oldItem != null)
                    result.push(oldItem)

                continue
            }

            if (oldItem != null)
                result.push(oldItem.copy(newItemOrCopyOptions))
        }

        return result
    }
}
