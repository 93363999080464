import { ForwardedRef, forwardRef } from "react"
import { UserRole, getUserRoleName } from "model"
import { DeepReadonly } from "my-util"
import Output, { OutputProps } from "ui/ui/Output"

export interface UserRoleOutputProps extends Omit<OutputProps, "children"> {
    role: UserRole
}

const UserRoleOutput = forwardRef((
    props: DeepReadonly<UserRoleOutputProps>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <Output {...props as UserRoleOutputProps}
            ref={ref}>
        {getUserRoleName(props.role)}
    </Output>
)

UserRoleOutput.displayName = "UserRoleOutput"

export default UserRoleOutput
