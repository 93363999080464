import { t } from "i18next"
import type { Comparator } from "my-util"
import Invite from "./Invite"

// Types

type InviteStatus =
    | ActiveInviteStatus
    | ExpiresInviteStatus
    | ExpiredInviteStatus

export default InviteStatus

export type ActiveInviteStatus = typeof ACTIVE_INVITE_STATUS
export type ExpiresInviteStatus = typeof EXPIRES_INVITE_STATUS
export type ExpiredInviteStatus = typeof EXPIRED_INVITE_STATUS

// Consts

export const ACTIVE_INVITE_STATUS = "active"
export const EXPIRES_INVITE_STATUS = "expires"
export const EXPIRED_INVITE_STATUS = "expired"

// Name

export function getInviteStatusName(status: InviteStatus): string {
    return t(`domain.invites.statuses.${status}`)
}

// Is-check

export function isInviteStatus(s: string): s is InviteStatus {
    switch (s) {
        case ACTIVE_INVITE_STATUS:
        case EXPIRES_INVITE_STATUS:
        case EXPIRED_INVITE_STATUS:
            s satisfies InviteStatus
            return true

        default:
            return false
    }
}

// Color

export interface InviteStatusColors {
    backgroundColor: string
    borderColor: string
    color: string
}

export const ACTIVE_INVITE_STATUS_COLORS: Readonly<InviteStatusColors> = {
    backgroundColor: "#E6F4EA",
    borderColor: "#E6F4EA",
    color: "#08922E",
}

export const EXPIRES_INVITE_STATUS_COLORS: Readonly<InviteStatusColors> = {
    backgroundColor: "#fffcf4",
    borderColor: "#cc5500",
    color: "#cc5500",
}

export const EXPIRED_INVITE_STATUS_COLORS: Readonly<InviteStatusColors> = {
    backgroundColor: "#F2F2F2",
    borderColor: "#F2F2F2",
    color: "#333",
}

export function getInviteStatusColors(status: InviteStatus): InviteStatusColors {
    switch (status) {
        case ACTIVE_INVITE_STATUS:
            return { ...ACTIVE_INVITE_STATUS_COLORS }

        case EXPIRES_INVITE_STATUS:
            return { ...EXPIRES_INVITE_STATUS_COLORS }

        case EXPIRED_INVITE_STATUS:
            return { ...EXPIRED_INVITE_STATUS_COLORS }
    }
}

// Sorting

export type InviteByStatusSortingOrder = InviteStatus[]

export const ACTIVE_INVITE_BY_STATUS_SORTING_ORDER: Readonly<InviteByStatusSortingOrder> = [
    ACTIVE_INVITE_STATUS,
    EXPIRES_INVITE_STATUS,
    EXPIRED_INVITE_STATUS,
]

export const EXPIRES_INVITE_BY_STATUS_SORTING_ORDER: Readonly<InviteByStatusSortingOrder> = [
    EXPIRES_INVITE_STATUS,
    EXPIRED_INVITE_STATUS,
    ACTIVE_INVITE_STATUS,
]

export const EXPIRED_INVITE_BY_STATUS_SORTING_ORDER: Readonly<InviteByStatusSortingOrder> = [
    EXPIRED_INVITE_STATUS,
    EXPIRES_INVITE_STATUS,
    ACTIVE_INVITE_STATUS,
]

export function getInvitesByStatusSortingOrderComparator(
    statusOrOrder: InviteStatus | Readonly<InviteByStatusSortingOrder>,
): Comparator<Invite> {
    const order = typeof statusOrOrder === "string"
        ? getInvitesByStatusSortingOrder(statusOrOrder)
        : statusOrOrder

    return (lhs, rhs) => {
        const lhsIndex = order.indexOf(lhs.status)
        const rhsIndex = order.indexOf(rhs.status)
        const result = Math.sign(lhsIndex - rhsIndex)

        return result
    }
}

export function getInvitesByStatusSortingOrder(status: InviteStatus): InviteByStatusSortingOrder {
    switch (status) {
        case ACTIVE_INVITE_STATUS:
            return [...ACTIVE_INVITE_BY_STATUS_SORTING_ORDER]

        case EXPIRES_INVITE_STATUS:
            return [...EXPIRES_INVITE_BY_STATUS_SORTING_ORDER]

        case EXPIRED_INVITE_STATUS:
            return [...EXPIRED_INVITE_BY_STATUS_SORTING_ORDER]
    }
}

// Presence

export interface InviteStatusPresence {
    activePresent: boolean
    expiresPresent: boolean
    expiredPresent: boolean
}

export function checkInviteStatusPresence(invite: readonly Invite[]): InviteStatusPresence {
    return {
        activePresent: invite.some(({ status }) => status === ACTIVE_INVITE_STATUS),
        expiresPresent: invite.some(({ status }) => status === EXPIRES_INVITE_STATUS),
        expiredPresent: invite.some(({ status }) => status === EXPIRED_INVITE_STATUS),
    }
}
