import { ForwardedRef, forwardRef, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import Modal from "../Modal"

export interface AgreementModalProps {
    onYes?: () => void
    onNo?: () => void

    yes?: string
    no?: string

    yesDisabled?: boolean

    header?: string
    children?: ReactNode

    loading?: boolean
    disabled?: boolean

    opacity?: number
    width?: string
    critical?: boolean
}

const AgreementModal = forwardRef((
    {
        onNo, onYes,
        yes, no,
        yesDisabled,
        header, children,
        loading, disabled,
        opacity, width, critical,
    }: Readonly<AgreementModalProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <Modal
        onClose={onNo}

        header={header}
        children={children}

        loading={loading}
        disabled={disabled}

        opacity={opacity}
        width={width}

        buttons={[
            {
                text: no ?? t("misc.buttons.no"),
                buttonStyle: "text",
                onClick: onNo,
            },

            {
                text: yes ?? t("misc.buttons.yes"),
                onClick: onYes,
                disabled: disabled || yesDisabled,
                type: "submit",
                critical,
            }
        ]}

        ref={ref}
    />
})

AgreementModal.displayName = "AgreementModal"

export default AgreementModal
