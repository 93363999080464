import { get, post } from "api/http/util"
import { CompletePasswordChangeRequest } from "api/request"

import { MfaResponse, MfaResponseSchema,
         PasswordChangeConfigResponse,
         PasswordChangeConfigResponseSchema } from "api/response"

import { joinSubpaths } from "my-util"
import { PASSWORDS_SUBPATH } from "./subpath"

export const PASSWORD_CHANGE_SUBPATH = joinSubpaths(PASSWORDS_SUBPATH, "change")

export async function startPasswordChange(signal?: AbortSignal | null): Promise<MfaResponse> {
    return post({
        subpath: `${PASSWORD_CHANGE_SUBPATH}/start`,
        schema: MfaResponseSchema,
        signal,
    })
}

export async function completePasswordChange(
    request: Readonly<CompletePasswordChangeRequest>,
    signal?: AbortSignal | null,
): Promise<void> {
    return post({
        subpath: `${PASSWORD_CHANGE_SUBPATH}/complete`,
        body: request,
        signal,
    })
}

export async function getPasswordChangeConfig(
    signal?: AbortSignal | null,
): Promise<PasswordChangeConfigResponse> {
    return get({
        subpath: `${PASSWORD_CHANGE_SUBPATH}/config`,
        schema: PasswordChangeConfigResponseSchema,
        signal,
    })
}

export async function cancelPasswordChange(signal?: AbortSignal | null): Promise<void> {
    return post({
        subpath: `${PASSWORD_CHANGE_SUBPATH}/cancel`,
        signal,
    })
}
