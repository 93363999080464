import { z } from "zod"
import { CompanyMessageSchema } from "api/message"
import { WithManyMessageTargetsResponseSchema } from "api/response/common"
import { ModelObjectResponseSchema } from "api/response/model/model-object"
import { AnonymousUserResponseSchema } from "../anonymous-user"
import { WithNotificationOptionsResponseSchema } from "../with-notification-options"
import { WithUserRightsResponseSchema } from "../with-user-rights"
import { WithCreatorIdResponseSchema } from "../with-creator-id"
import { UserStatusResponseSchema } from "./user-status"

export const UserResponseSchema = z.object({
    company: CompanyMessageSchema.nullish(),
    status: UserStatusResponseSchema.nullish(),
})
    .merge(ModelObjectResponseSchema)
    .merge(WithCreatorIdResponseSchema)
    .merge(WithManyMessageTargetsResponseSchema)
    .merge(AnonymousUserResponseSchema)
    .merge(WithUserRightsResponseSchema)
    .merge(WithNotificationOptionsResponseSchema)

export type UserResponse = z.infer<typeof UserResponseSchema>
