import { WithOneMessageTargetFields } from "model/interfaces"
import { mixin, OneMessageTargetMixin } from "model/mixins"

import AbstractModelObject,
       { AbstractModelObjectCopyOptions,
         AbstractModelObjectCreationOptions } from "model/AbstractModelObject"

import { DeepReadonly, Nullish, removeWhiteSpace } from "my-util"

export interface InviteApplicationCreationOptions
    extends
        AbstractModelObjectCreationOptions,
        WithOneMessageTargetFields
{
    messageTarget: string
}

export interface InviteApplicationCopyOptions
    extends
        AbstractModelObjectCopyOptions,
        Nullish<WithOneMessageTargetFields>
{}

class InviteApplication
    extends
        AbstractModelObject<InviteApplicationCopyOptions>

    implements
        WithOneMessageTargetFields
{
    // Fields

    readonly messageTarget: string

    // Constructor

    constructor(options: DeepReadonly<InviteApplicationCreationOptions>) {
        super(options)

        this.messageTarget = removeWhiteSpace(options.messageTarget)
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<InviteApplicationCopyOptions> = {},
    ): InviteApplication {
        return new InviteApplication({
            // Message target

            messageTarget: options.messageTarget ?? this.messageTarget,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}

export default class InviteApplicationWithMixins extends mixin(InviteApplication, [
    OneMessageTargetMixin,
]) {
    static createOrPass(
        arg: InviteApplicationWithMixins | DeepReadonly<InviteApplicationCreationOptions>,
    ): InviteApplicationWithMixins {
        return arg instanceof InviteApplicationWithMixins
            ? arg
            : new InviteApplicationWithMixins(arg)
    }
}
