import { CSSProperties, ForwardedRef, forwardRef, ReactNode } from "react"
import { Flex } from "ui/ui/layout"
import Information from "ui/ui/Information"
import Label from "ui/ui/Label"
import { Icon } from "../icon"
import style from "./style.module.css"

export interface OutputProps {
    children?: ReactNode

    left?: ReactNode
    right?: ReactNode

    width?: string
    height?: string
    valueHeight?: string

    color?: string
    backgroundColor?: string
    borderColor?: string

    resize?: CSSProperties["resize"]
    whiteSpace?: CSSProperties["whiteSpace"]
    wordBreak?: CSSProperties["wordBreak"]
    overflow?: CSSProperties["overflow"]

    label?: string
    information?: string

    iconSrc?: string
    iconAlt?: string
    iconFilter?: string
}

const Output = forwardRef((
    {
        children,
        left, right,
        width, height, valueHeight,
        color, backgroundColor, borderColor,
        resize, whiteSpace, wordBreak, overflow,
        label, information,
        iconSrc, iconAlt, iconFilter,
    }: Readonly<OutputProps>,
    ref: ForwardedRef<HTMLDivElement>
) =>
    <Flex align="start"
          gap="4px"
          width={width}
          height={height}
          ref={ref}>
        <Label fontSize="16px"
               text={label}/>

        <div className={style.childrenWrapper}
             style={{ height: valueHeight }}>
            {left &&
                <div className={style.left}>
                    {left}
                </div>
            }

            <div style={{ color, backgroundColor, borderColor, resize, whiteSpace, wordBreak, overflow }}
                 className={style.children}>
                {children}

                {iconSrc != null &&
                    <div className={style.icon}>
                        <Icon src={iconSrc}
                              alt={iconAlt}
                              filter={iconFilter}
                              width="1em"
                              height="1em"/>
                    </div>
                }
            </div>

            {right &&
                <div className={style.right}>
                    {right}
                </div>
            }
        </div>

        <Information text={information}
                     fontSize="16px"/>
    </Flex>
)

Output.displayName = "Output"

export default Output
