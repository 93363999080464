import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { createProvider, ProviderRequest } from "api"
import { Provider } from "model"
import ProviderActionModal from "../ProviderActionModal"

export interface ProviderCreationModalProps {
    onCreated?: (provider: Provider) => void
    onClose?: () => void

    closeOnSuccess?: boolean

    width?: string
}

const ProviderCreationModal = forwardRef((
    {
        onCreated, onClose,
        closeOnSuccess,
        width,
    }: Readonly<ProviderCreationModalProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <ProviderActionModal header={t("domain.providers.headers.creation")}
                                yes={t("misc.buttons.save")}
                                no={t("misc.buttons.cancel")}

                                onYes={onInnerCreate}
                                onNo={onClose}

                                closeOnSuccess={closeOnSuccess}

                                apiErrorMessageMapping={{
                                    409: t("messageTargets.messages.errors.occupied.messageTarget")
                                }}

                                width={width}

                                ref={ref}/>

    // Events

    async function onInnerCreate(provider: Provider) {
        const request: ProviderRequest = {
            // Name

            enFirstname: provider.enFirstname,
            enLastname: provider.enLastname,
            enPatronymic: provider.enPatronymic,

            ruFirstname: provider.ruFirstname,
            ruLastname: provider.ruLastname,
            ruPatronymic: provider.ruPatronymic,

            // Company

            enCompany: provider.enCompany,
            ruCompany: provider.ruCompany,

            // Message targets

            phone: provider.phone,
            email: provider.email,
        }

        const createdProvider = await createProvider(request)

        onCreated?.(createdProvider)
    }
})

ProviderCreationModal.displayName = "ProviderCreationModal"

export default ProviderCreationModal
