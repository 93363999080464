import { v4 as generateRandomUuid } from "uuid"

import { createOrCopyDate, tryNormalizeUuid, Nullish,
         IsImmutable, ReadonlyDate, DeepReadonly, Immutable } from "my-util"

import { ModelObject } from "./interfaces"
import Copyable from "./Copyable"

export interface AbstractModelObjectCreationOptions extends Nullish<ModelObject> {}

export interface AbstractModelObjectCopyOptions extends Nullish<ModelObject> {}

export default abstract class AbstractModelObject<CopyOptions extends AbstractModelObjectCopyOptions>
    extends
        Copyable<CopyOptions>

    implements
        DeepReadonly<ModelObject>,
        Immutable
{
    // Group

    static groupByIdOrPassOrCreate<T extends DeepReadonly<ModelObject>>(
        objects:
            | ReadonlyMap<string, T>
            | Iterable<T>
            | undefined
            | null
    ): Map<string, T> {
        return objects != null
            ? this.groupByIdOrPass(objects)
            : new Map()
    }

    static groupByIdOrPass<T extends DeepReadonly<ModelObject>>(
        objects:
            | ReadonlyMap<string, T>
            | Iterable<T>
    ): Map<string, T> {
        return objects instanceof Map
            ? objects
            : this.groupById(objects as readonly T[])
    }

    static groupById<T extends DeepReadonly<ModelObject>>(objects: Iterable<T>): Map<string, T> {
        const map = new Map<string, T>()

        for (const object of objects)
            map.set(object.id, object)

        return map
    }

    // Fields

    readonly [IsImmutable] = true

    readonly id: string
    readonly createdAt: ReadonlyDate
    readonly modifiedAt: ReadonlyDate

    // Constructor

    constructor(
        {
            id,
            createdAt,
            modifiedAt,
        }: DeepReadonly<AbstractModelObjectCreationOptions> = {},
    ) {
        super()

        this.id = id != null
            ? tryNormalizeUuid(id)
            : generateRandomUuid()

        this.createdAt = createOrCopyDate(createdAt)
        this.modifiedAt = createOrCopyDate(modifiedAt)
    }
}
