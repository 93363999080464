import Decimal from "decimal.js"
import { ForwardedRef, forwardRef, ReactNode, useMemo } from "react"
import { DECIMAL_INPUT_REGEX, formatDecimal, isDigit } from "my-util"
import { useStateWithDeps } from "ui/hook"
import Input from "ui/ui/Input"

export const DEFAULT_DECIMAL_INPUT_PRECISION = 2

export interface DecimalInputProps {
    onChange?: (value: Decimal) => void
    value?: Decimal.Value | null
    precision?: number

    left?: ReactNode
    right?: ReactNode
    over?: ReactNode

    autoFocus?: boolean

    width?: string
    height?: string
    color?: string
    backgroundColor?: string
    borderColor?: string

    invalid?: boolean
    disabled?: boolean
    readonly?: boolean
    loading?: boolean

    label?: string
    placeholder?: string
    information?: string

    onIconClick?: () => void
    iconSrc?: string
    iconAlt?: string
    iconFilter?: string
}

const DecimalInput = forwardRef((
    {
        onChange, value, precision,
        left, right, over,
        autoFocus,
        width, height, color, backgroundColor, borderColor,
        invalid, disabled, readonly, loading,
        label, placeholder, information,
        onIconClick, iconSrc, iconAlt, iconFilter,
    }: Readonly<DecimalInputProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    // State

    const innerPrecision = (precision ?? DEFAULT_DECIMAL_INPUT_PRECISION) | 0

    const InnerDecimal = useMemo(
        () => Decimal.clone({ precision: innerPrecision }),
        [innerPrecision],
    )

    const [stringValue, setStringValue] = useStateWithDeps<string>(oldStringValue => {
            switch (typeof value) {
                case "undefined":
                    return ""

                case "string":
                    return value

                case "number":
                    return formatDecimal(value, innerPrecision)

                case "object":
                    if (value == null)
                        return ""

                    if (oldStringValue == null)
                        return formatDecimal(value, innerPrecision)

                    const oldDecimalValue = stringValueToDecimal(oldStringValue)

                    if (oldDecimalValue.equals(value))
                        return oldStringValue

                    return formatDecimal(value, innerPrecision)

                default:
                    return value satisfies never
            }
        },
        [value],
    )

    // Render

    return <Input onChange={onStringValueChange}
                  value={stringValue}

                  regex={DECIMAL_INPUT_REGEX}
                  test={testInput}

                  left={left}
                  right={right}
                  over={over}

                  autoFocus={autoFocus}

                  width={width}
                  height={height}
                  color={color}
                  backgroundColor={backgroundColor}
                  borderColor={borderColor}

                  invalid={invalid}
                  disabled={disabled}
                  readonly={readonly}
                  loading={loading}

                  label={label}
                  placeholder={placeholder}
                  information={information}

                  onIconClick={onIconClick}
                  iconSrc={iconSrc}
                  iconAlt={iconAlt}
                  iconFilter={iconFilter}

                  ref={ref}/>

    // Events

    function onStringValueChange(newStringValue: string) {
        setStringValue(newStringValue)

        if (!onChange)
            return

        const newDecimalValue = stringValueToDecimal(newStringValue)

        onChange(newDecimalValue)
    }

    // Util

    function stringValueToDecimal(value: string): Decimal {
        return new InnerDecimal(normalizeStringValue(value))
    }

    function normalizeStringValue(value: string): string {
        value = value
            .replaceAll(/\s+/g, "")
            .replace(",", ".")

        return value.length !== 0
            ? value
            : "0"
    }

    function testInput(amount: string): boolean {
        let sepIndex = amount.indexOf(".")

        if (sepIndex < 0)
            sepIndex = amount.indexOf(",")

        if (sepIndex < 0)
            return true

        const fractional = amount.substring(sepIndex + 1)

        let digitCount = 0

        for (const char of fractional)
            if (isDigit(char) && ++digitCount > innerPrecision)
                return false

        return true
    }
})

DecimalInput.displayName = "DecimalInput"

export default DecimalInput
