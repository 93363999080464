import { allMandatoryNamedFieldsPresent, MandatoryNamedFields, NamedFields } from "model/interfaces"
import { mixin, NameMixin } from "model/mixins"
import Copyable from "model/Copyable"

import { capitalized, collapseWhiteSpace, IsImmutable,
         collapseWhiteSpaceToNull, Immutable, Nullish } from "my-util"

export interface AnonymousUserCreationOptions extends MandatoryNamedFields {}

export interface AnonymousUserCopyOptions extends Nullish<NamedFields> {}

class AnonymousUser
    extends
        Copyable<AnonymousUserCopyOptions>

    implements
        NamedFields,
        Immutable
{
    // Fields

    readonly [IsImmutable] = true

    // - En

    readonly enFirstname: string
    readonly enLastname: string
    readonly enPatronymic: string | null

    // - Ru

    readonly ruFirstname: string
    readonly ruLastname: string
    readonly ruPatronymic: string | null

    // Constructor

    constructor(options: Readonly<AnonymousUserCreationOptions>) {
        super()

        this.enFirstname = collapseWhiteSpace(capitalized(options.enFirstname))
        this.enLastname = collapseWhiteSpace(capitalized(options.enLastname))
        this.enPatronymic = collapseWhiteSpaceToNull(capitalized(options.enPatronymic ?? ""))

        this.ruFirstname = collapseWhiteSpace(capitalized(options.ruFirstname))
        this.ruLastname = collapseWhiteSpace(capitalized(options.ruLastname))
        this.ruPatronymic = collapseWhiteSpaceToNull(capitalized(options.ruPatronymic ?? ""))
    }

    // Copy

    protected override createCopy(
        options: Readonly<AnonymousUserCopyOptions> = {}
    ): AnonymousUser {
        return new AnonymousUser({
            // En

            enFirstname: options.enFirstname ?? this.enFirstname,
            enLastname: options.enLastname ?? this.enLastname,

            enPatronymic: "enPatronymic" in options
                ? options.enPatronymic
                : this.enPatronymic,

            // Ru

            ruFirstname: options.ruFirstname ?? this.ruFirstname,
            ruLastname: options.ruLastname ?? this.ruLastname,

            ruPatronymic: "ruPatronymic" in options
                ? options.ruPatronymic
                : this.ruPatronymic,
        })
    }
}

export default class AnonymousUserWithMixins extends mixin(AnonymousUser, [NameMixin]) {
    // Create

    static createOrPass(
        arg: AnonymousUserWithMixins | Readonly<AnonymousUserCreationOptions>,
    ): AnonymousUserWithMixins {
        return arg instanceof AnonymousUserWithMixins
            ? arg
            : new AnonymousUserWithMixins(arg)
    }

    // Copy

    static copyOrPass(
        object?: AnonymousUserWithMixins | null,
        arg?: AnonymousUserWithMixins | Readonly<AnonymousUserCopyOptions> | null,
    ): AnonymousUserWithMixins | AnonymousUserCreationOptions | null {
        if (object != null)
            return object.copyOrPass(arg)

        if (arg != null && allMandatoryNamedFieldsPresent(arg))
            return arg

        return null
    }
}
