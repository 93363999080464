import { UUID_REGEX } from "./regex"
import { getStringColor } from "./string"

// Consts

export const ZERO_UUID = normalizeUuid("0-0-0-0-0")

// Color

export function getNullableUuidColor(id?: string | null, opacity?: number | null) {
    return getStringColor(
        id != null
            ? tryNormalizeUuid(id)
            : ZERO_UUID,

        opacity,
    )
}

export function getUuidColor(id: string, opacity?: number | null) {
    return getStringColor(tryNormalizeUuid(id), opacity)
}

// Normalization

// - Try

export function tryNormalizeNullableUuid(id: string | undefined | null): string | null {
    return id != null
        ? tryNormalizeUuid(id)
        : null
}

export function tryNormalizeUuid(id: string): string {
    return isUuid(id)
        ? normalizeUuid(id)
        : id
}

// - Force

export function normalizeNullableUuid(id: string | undefined | null): string | null {
    return id != null
        ? normalizeUuid(id)
        : null
}

export function normalizeUuid(id: string): string {
    const match = id.match(UUID_REGEX)

    if (match == null)
        throw Error("Not a valid UUID")

    return [
        match[1].padStart(8, "0"),

        match[2].padStart(4, "0"),
        match[3].padStart(4, "0"),
        match[4].padStart(4, "0"),

        match[5].padStart(12, "0"),
    ]
        .join("-")
        .toLowerCase()
}

// Is-check

export function isUuid(s: string): boolean {
    return s.match(UUID_REGEX) != null
}
