import { ForwardedRef, forwardRef, Fragment, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { envelopeIconUrl, trashCanIconUrl } from "image"
import { InviteApplication } from "model"
import { dateToDateString, dateWithTimeNulled, DeepReadonly, group } from "my-util"
import { INVITE_CREATION_PAGE_PATH } from "ui/page/sections/clients/invites/InviteCreationPage/path"
import { INVITE_CREATION_PAGE_MESSAGE_TARGET_SEARCH_PARAM } from "ui/page/sections/clients/invites/InviteCreationPage/Component"
import { Button, Flex, MessageTarget } from "ui/ui"
import style from "./style.module.css"

export interface InviteApplicationTableProps {
    onDelete?: (application: InviteApplication) => void

    applications: Iterable<InviteApplication>,

    loading?: boolean
    disabled?: boolean

    width?: string
}

const InviteApplicationTable = forwardRef((
    {
        onDelete,
        applications,
        loading, disabled,
        width,
    }: DeepReadonly<InviteApplicationTableProps>,
    ref: ForwardedRef<HTMLTableElement>,
) => {
    const navigate = useNavigate()

    // State

    const innerApplicationsByTimestamps = useMemo(
        () =>
            [
                ...group(
                    applications,
                    ({ createdAt }) => dateWithTimeNulled(createdAt).getTime(),
                ).entries()
            ].sort(([lhs], [rhs]) => rhs - lhs),
        [applications],
    )

    // Render

    return <table className={style.table}
                  style={{ width }}
                  ref={ref}>
        <tbody>
            {innerApplicationsByTimestamps.map(([timestamp, applications], i) =>
                <Fragment key={timestamp}>
                    {applications.map((application, j) =>
                        <tr className={i % 2 === 0 ? style.eventRowGroup : style.oddRowGroup}
                            key={application.id}>
                            {j === 0 &&
                                <td className={style.bigText + " " + style.topText}
                                    rowSpan={applications.length}>
                                    {dateToDateString(new Date(timestamp))}
                                </td>
                            }

                            <td>
                                <MessageTarget messageTarget={application.messageTarget}/>
                            </td>

                            <td>
                                <Flex direction="horizontal">
                                    <Button onClick={() => onCreateInvite(application)}

                                            iconSrc={envelopeIconUrl}
                                            iconAlt="Envelope icon"

                                            loading={loading}
                                            disabled={disabled}

                                            buttonStyle="text"/>

                                    <Button onClick={() => onDelete?.(application)}

                                            iconSrc={trashCanIconUrl}
                                            iconAlt="Trash can icon"

                                            loading={loading}
                                            disabled={disabled}

                                            buttonStyle="text"
                                            critical/>
                                </Flex>
                            </td>
                        </tr>
                    )}

                    <tr className={style.gap}/>
                </Fragment>
            )}
        </tbody>
    </table>

    // Events

    function onCreateInvite(application: InviteApplication) {
        const pathname = INVITE_CREATION_PAGE_PATH
        const searchParamName = INVITE_CREATION_PAGE_MESSAGE_TARGET_SEARCH_PARAM
        const searchParamValue = encodeURIComponent(application.messageTarget)
        const path = `${pathname}?${searchParamName}=${searchParamValue}`

        navigate(path)
    }
})

InviteApplicationTable.displayName = "InviteApplicationTable"

export default InviteApplicationTable
