import { forwardRef, ForwardedRef } from "react"
import { InviteStatus, getInviteStatusColors, getInviteStatusName } from "model"
import ColorLegend from "ui/ui/ColorLegend"

export interface InviteStatusColorLegendProps {
    onClick?: (status: InviteStatus) => void
    status: InviteStatus

    fontSize?: string

    showLegend?: boolean
}

export const InviteStatusColorLegend = forwardRef((
    {
        onClick, status,
        fontSize,
        showLegend,
    }: Readonly<InviteStatusColorLegendProps>,
    ref: ForwardedRef<HTMLSpanElement>
) => {
    const onInnerClick = onClick != null
        ? () => onClick(status)
        : undefined

    const legend = showLegend
        ? getInviteStatusName(status)
        : undefined

    const { color } = getInviteStatusColors(status)

    return <ColorLegend onClick={onInnerClick}
                        fontSize={fontSize}
                        legend={legend}
                        color={color}
                        ref={ref}/>
});

InviteStatusColorLegend.displayName = "InviteStatusColorLegend"

export default InviteStatusColorLegend
