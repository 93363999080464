import { countEntities, get, post, put, patchEntity, del } from "api/http/util"
import { IdsRequest, JsonPatch, ProviderRequest } from "api/request"
import { ProviderResponseSchema } from "api/response"
import { Provider } from "model"
import { DeepReadonly } from "my-util"

export const PROVIDERS_SUBPATH = "/providers"

// Count

export async function countProviders(signal?: AbortSignal | null): Promise<number> {
    return countEntities(`${PROVIDERS_SUBPATH}/count`, signal)
}

export async function countMyProviders(signal?: AbortSignal | null): Promise<number> {
    return countEntities(`${PROVIDERS_SUBPATH}/my/count`, signal)
}

// Get many

export async function getAllProviders(signal?: AbortSignal | null): Promise<Provider[]> {
    return (await get({
        subpath: PROVIDERS_SUBPATH,
        schema: ProviderResponseSchema.array(),
        signal,
    })).map(response => new Provider(response))
}

export async function getAllMyProviders(signal?: AbortSignal | null): Promise<Provider[]> {
    return (await get({
        subpath: `${PROVIDERS_SUBPATH}/my`,
        schema: ProviderResponseSchema.array(),
        signal,
    })).map(response => new Provider(response))
}

// Bulk get

export async function getAllProvidersByIds(
    request: DeepReadonly<IdsRequest>,
    signal?: AbortSignal | null,
): Promise<Provider[]> {
    return (await post({
        subpath: `${PROVIDERS_SUBPATH}/bulk-get`,
        schema: ProviderResponseSchema.array(),
        body: request,
        signal,
    })).map(response => new Provider(response))
}

// Get one

export async function getProviderById(id: string, signal?: AbortSignal | null): Promise<Provider> {
    return new Provider(await get({
        subpath: `${PROVIDERS_SUBPATH}/${id}`,
        schema: ProviderResponseSchema,
        signal,
    }))
}

// Delete

export async function clearProviders(signal?: AbortSignal | null) {
    return del({
        subpath: PROVIDERS_SUBPATH,
        signal,
    })
}

export async function clearMyProviders(signal?: AbortSignal | null) {
    return del({
        subpath: `${PROVIDERS_SUBPATH}/my`,
        signal,
    })
}

export async function deleteProviderById(id: string, signal?: AbortSignal | null) {
    return del({
        subpath: `${PROVIDERS_SUBPATH}/${id}`,
        signal,
    })
}

// Patch

export async function patchProviderById(
    id: string,
    patches: DeepReadonly<JsonPatch[]>,
    signal?: AbortSignal | null
): Promise<Provider> {
    return new Provider(await patchEntity({
        subpath: `${PROVIDERS_SUBPATH}/${id}`,
        schema: ProviderResponseSchema,
        patches,
        signal,
    }))
}

// Put

export async function putProviderById(
    id: string,
    request: DeepReadonly<ProviderRequest>,
    signal?: AbortSignal | null,
): Promise<Provider> {
    return new Provider(await put({
        subpath: `${PROVIDERS_SUBPATH}/${id}`,
        schema: ProviderResponseSchema,
        body: request,
        signal,
    }))
}

// Create

export async function createProvider(
    request: Readonly<ProviderRequest>,
    signal?: AbortSignal,
): Promise<Provider> {
    return new Provider(await post({
        subpath: PROVIDERS_SUBPATH,
        schema: ProviderResponseSchema,
        body: request,
        signal,
    }))
}
