import { ForwardedRef, forwardRef } from "react"
import { Flex, FlexProps } from "ui/ui/layout"
import { ACTIVE_INVITE_STATUS, BLOCKED_USER_STATUS, UNVERIFIED_USER_STATUS, UserStatus } from "model"
import UserStatusColorLegend from "../UserStatusColorLegend"

export interface UserStatusColorLegendListProps extends Omit<FlexProps, "children"> {
    onClick?: (status: UserStatus) => void

    hideActive?: boolean
    hideUnverified?: boolean
    hideBlocked?: boolean

    showLegend?: boolean

    fontSize?: string
}

const UserStatusColorLegendList = forwardRef((
    props: Readonly<UserStatusColorLegendListProps>,
    ref: ForwardedRef<HTMLDivElement>
) => {
    const {
        onClick,
        hideActive, hideUnverified, hideBlocked,
        showLegend,
        fontSize,
    } = props

    return <Flex direction="horizontal"
                 width="fit-content"
                 { ...props }
                 ref={ref}>
        {!hideActive &&
            <UserStatusColorLegend status={ACTIVE_INVITE_STATUS}
                                   showLegend={showLegend}
                                   fontSize={fontSize}
                                   onClick={onClick}/>
        }

        {!hideUnverified &&
            <UserStatusColorLegend status={UNVERIFIED_USER_STATUS}
                                   showLegend={showLegend}
                                   fontSize={fontSize}
                                   onClick={onClick}/>
        }

        {!hideBlocked &&
            <UserStatusColorLegend status={BLOCKED_USER_STATUS}
                                   showLegend={showLegend}
                                   fontSize={fontSize}
                                   onClick={onClick}/>
        }
    </Flex>
})

UserStatusColorLegendList.displayName = "UserStatusColorLegendList"

export default UserStatusColorLegendList
