import Decimal from "decimal.js"
import { ForwardedRef, forwardRef, ReactNode, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import * as api from "api"
import { Provider, TransferProvider, User } from "model"
import { DeepReadonly } from "my-util"
import { useProviders, useStateWithDeps, useUsers } from "ui/hook"
import { ProviderLink } from "ui/component/provider"
import { UserLink } from "ui/component/user"

import { Button, Flex, Label, Group, LoadingIndicator, Modal,
         FLEX_DEFAULT_GAP, OptionallyRequired, DecimalOutput,
         DecimalInput, Loading, ErrorDisplay, Output, ErrorText, Pane } from "ui/ui"

import style from "./style.module.css"

export interface TransferProviderEditorProps {
    onChange?: (value: TransferProvider) => void
    value?: TransferProvider

    onUsersChange?: (users: User[]) => void
    users?: Iterable<User> | Map<string, User>
    getAllUsers?: (signal?: AbortSignal | null) => Promise<User[]>
    getUserById?: (id: string, signal?: AbortSignal | null) => Promise<User>

    onProvidersChange?: (providers: Provider[]) => void
    providers?: Iterable<Provider> | Map<string, Provider>
    getAllProviders?: (signal?: AbortSignal | null) => Promise<Provider[]>
    getProviderById?: (id: string, signal?: AbortSignal | null) => Promise<Provider>

    onRenderModal?: (modal: ReactNode) => void

    loading?: boolean
    disabled?: boolean
    readonly?: boolean
    required?: boolean
    output?: boolean

    width?: string
}

const TransferProviderEditor = forwardRef((
    {
        onChange, value,
        onUsersChange, users, getAllUsers, getUserById,
        onProvidersChange, providers, getAllProviders, getProviderById,
        onRenderModal,
        loading, disabled, readonly, required, output,
        width,
    }: DeepReadonly<TransferProviderEditorProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    // Storages

    const usersStorage = useUsers()
    const providersStorage = useProviders()

    // Refs

    const changedRef = useRef(true)

    const usersChangedRef = useRef(false)
    const providersChangedRef = useRef(false)

    // State

    // - Value

    const [innerValue, setInnerValue] = useStateWithDeps(
        () => value ?? new TransferProvider(),
        [value?.id],
    )

    // - Users

    const [innerUsersById, setInnerUsersById] = useStateWithDeps<Map<string, User>>(
        oldUsersByIds => {
            const newUsersByIds = new Map(oldUsersByIds)
            const propsUsersByIds = User.groupByIdOrPassOrCreate(users)

            for (const [id, user] of propsUsersByIds.entries())
                newUsersByIds.set(id, user)

            return newUsersByIds
        },

        [users],
    )

    const innerUsers = useMemo(() => [...innerUsersById.values()], [innerUsersById])

    // -- Initial

    const [loadingUser, setLoadingUser] = useStateWithDeps(
        () => innerValue.userId != null && !innerUsersById.has(innerValue.userId),
        [innerValue, innerUsersById],
    )

    const [userLoadingError, setUserLoadingError] = useState(undefined as unknown)

    // -- All

    const [loadedAllUsers, setLoadedAllUsers] = useState(false)
    const [loadingAllUsers, setLoadingAllUsers] = useState(false)
    const [allUsersLoadingError, setAllUsersLoadingError] = useState(undefined as unknown)

    // - Providers

    const [innerProvidersById, setInnerProvidersById] = useStateWithDeps<Map<string, Provider>>(
        oldProvidersByIds => {
            const newProvidersByIds = new Map(oldProvidersByIds)
            const propsProvidersByIds = Provider.groupByIdOrPassOrCreate(providers)

            for (const [id, provider] of propsProvidersByIds.entries())
                newProvidersByIds.set(id, provider)

            return newProvidersByIds
        },

        [providers],
    )

    const innerProviders = useMemo(() => [...innerProvidersById.values()], [innerProvidersById])

    // -- Initial

    const [loadingProvider, setLoadingProvider] = useStateWithDeps(
        () => innerValue.providerId != null && !innerProvidersById.has(innerValue.providerId),
        [innerValue, innerProvidersById],
    )

    const [providerLoadingError, setProviderLoadingError] = useState(undefined as unknown)

    // -- All

    const [loadedAllProviders, setLoadedAllProviders] = useState(false)
    const [loadingAllProviders, setLoadingAllProviders] = useState(false)
    const [allProvidersLoadingError, setAllProvidersLoadingError] = useState(undefined as unknown)

    // - Modal

    const [selecting, setSelecting] = useState(false)

    const selectionModal = useMemo(
        renderSelectionModal,

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            selecting,
            innerUsers, loadingAllUsers, allUsersLoadingError,
            innerProviders, loadingAllProviders, allProvidersLoadingError,
        ],
    )

    // Effects

    // - Value propagation

    useEffect(() => {
        if (changedRef.current) {
            changedRef.current = false
            onChange?.(innerValue)
        }
    }, [onChange, innerValue])

    // - Users propagation

    useEffect(() => {
        if (usersChangedRef.current) {
            usersChangedRef.current = true
            onUsersChange?.([...innerUsers])
        }
    }, [innerUsers, onUsersChange])

    // - Providers propagation

    useEffect(() => {
        if (providersChangedRef.current) {
            providersChangedRef.current = true
            onProvidersChange?.([...innerProviders])
        }
    }, [innerProviders, onProvidersChange])

    // - Modal propagation

    useEffect(() => {
        onRenderModal?.(selectionModal)
    }, [selectionModal, onRenderModal])

    // - User loading

    useEffect(() => {
        if (!loadingUser)
            return

        const { userId } = innerValue

        if (userId == null) {
            setLoadingUser(false)
            return
        }

        const controller = new AbortController()

        ;(getUserById ?? api.getUserById)(userId, controller.signal)
            .then(gotUser => {
                setInnerUsersById(oldUsersById => {
                    const newUsersById = new Map(oldUsersById)
                    newUsersById.set(gotUser.id, gotUser)
                    return newUsersById
                })

                usersStorage.add(gotUser)

                usersChangedRef.current = true
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setUserLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingUser(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingUser])

    // - All users loading

    useEffect(() => {
        if (!loadingAllUsers)
            return

        const controller = new AbortController()

        ;(getAllUsers ?? api.getAllUsers)(controller.signal)
            .then(gotUsers => {
                setInnerUsersById(oldUsersById => {
                    const newUsersById = new Map(oldUsersById)

                    for (const user of gotUsers)
                        newUsersById.set(user.id, user)

                    return newUsersById
                })

                usersStorage.addAll(gotUsers)

                usersChangedRef.current = true
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setAllUsersLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted) {
                    setLoadingAllUsers(false)
                    setLoadedAllUsers(true)
                }
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAllUsers])

    // - Provider loading

    useEffect(() => {
        if (!loadingProvider)
            return

        const { providerId } = innerValue

        if (providerId == null) {
            setLoadingProvider(false)
            return
        }

        const controller = new AbortController()

        ;(getProviderById ?? api.getProviderById)(providerId, controller.signal)
            .then(gotProvider => {
                setInnerProvidersById(oldProvidersById => {
                    const newProvidersById = new Map(oldProvidersById)
                    newProvidersById.set(gotProvider.id, gotProvider)
                    return newProvidersById
                })

                providersStorage.add(gotProvider)

                providersChangedRef.current = true
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setProviderLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingProvider(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingProvider])

    // - All providers loading

    useEffect(() => {
        if (!loadingAllProviders)
            return

        const controller = new AbortController()

        ;(getAllProviders ?? api.getAllProviders)(controller.signal)
            .then(gotProviders => {
                setInnerProvidersById(oldProvidersById => {
                    const newProvidersById = new Map(oldProvidersById)

                    for (const provider of gotProviders)
                        newProvidersById.set(provider.id, provider)

                    return newProvidersById
                })

                providersStorage.addAll(gotProviders)

                providersChangedRef.current = true
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setAllProvidersLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted) {
                    setLoadingAllProviders(false)
                    setLoadedAllProviders(true)
                }
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAllProviders])

    // Render

    const FIELD_WIDTH = `calc(50% - ${FLEX_DEFAULT_GAP})`

    return <>
        {renderContent()}
        {renderSelectionModalIfNeeded()}
    </>

    function renderContent(): ReactNode {
        return <Group width={width}
                      ref={ref}>
            <Flex direction="horizontal">
                <Flex direction="horizontal"
                      width={FIELD_WIDTH}>
                    <OptionallyRequired required={required}>
                        <Label text={t("domain.transferProviders.labels.provider")}/>
                    </OptionallyRequired>

                    {output
                        ? renderUserOrProviderOutput()
                        : renderUserOrProviderInput()
                    }
                </Flex>

                <Flex direction="horizontal"
                      width={FIELD_WIDTH}>
                    <OptionallyRequired required={required}>
                        <Label text={t("domain.transferProviders.labels.percent")}/>
                    </OptionallyRequired>

                    {output
                        ? <DecimalOutput value={innerValue.percent}
                                         precision={4}/>

                        : <DecimalInput onChange={onPercentChange}
                                        value={innerValue.percent}
                                        precision={4}

                                        loading={loading}
                                        disabled={disabled}
                                        readonly={readonly}/>
                    }
                </Flex>
            </Flex>
        </Group>
    }

    // - Provider

    function renderUserOrProviderOutput(): ReactNode {
        const { userId, providerId } = innerValue

        if (userId != null) {
            const user = innerUsersById.get(userId)

            if (user == null) {
                if (userLoadingError != null)
                    return renderUserLoadingError()

                return <LoadingIndicator/>
            }

            return <UserLink user={user}/>
        }

        if (providerId != null) {
            const provider = innerProvidersById.get(providerId)

            if (provider == null) {
                if (providerLoadingError != null)
                    return renderProviderLoadingError()

                return <LoadingIndicator/>
            }

            return <ProviderLink provider={provider}/>
        }

        return <Output>{t("domain.transferProviders.labels.provider")}</Output>
    }

    function renderUserOrProviderInput(): ReactNode {
        const { userId, providerId } = innerValue

        if (userId == null && providerId == null)
            return <Button onClick={onSelect}

                           text={t("domain.transferProviders.buttons.select")}

                           loading={loading}
                           disabled={disabled || readonly}/>

        let text = t("domain.transferProviders.labels.provider")

        if (userId != null) {
            const user = innerUsersById.get(userId)

            if (user == null) {
                if (userLoadingError != null)
                    return renderUserLoadingError()

                return <LoadingIndicator/>
            }

            text = user.name
        }

        if (providerId != null) {
            const provider = innerProvidersById.get(providerId)

            if (provider == null) {
                if (providerLoadingError != null)
                    return renderProviderLoadingError()

                return <LoadingIndicator/>
            }

            text = provider.name
        }

        return <Button onClick={onSelect}

                       text={text}

                       loading={loading}
                       disabled={disabled || readonly}

                       buttonStyle="outline"/>
    }

    // - Errors

    function renderUserLoadingError(): ReactNode {
        return <ErrorText error={userLoadingError}
                          apiErrorMessageMapping={{
                               403: t("domain.users.messages.errors.accessDenied"),
                              404: t("domain.users.messages.errors.notFound"),
                          }}/>
    }

    function renderProviderLoadingError(): ReactNode {
        return <ErrorText error={providerLoadingError}
                          apiErrorMessageMapping={{
                              403: t("domain.providers.messages.errors.accessDenied"),
                              404: t("domain.providers.messages.errors.notFound"),
                          }}/>
    }

    // - Selection modal

    function renderSelectionModalIfNeeded(): ReactNode {
        return onRenderModal
            ? null
            : selectionModal
    }

    function renderSelectionModal(): ReactNode {
        if (!selecting)
            return null

        return <Modal header={t("domain.transferProviders.headers.selecting")}
                      onClose={() => setSelecting(false)}
                      width="calc(min(80vw, 816px))">
            {renderSelectionModalContent()}
        </Modal>
    }

    function renderSelectionModalContent(): ReactNode {
        if (allUsersLoadingError != null)
            return <ErrorDisplay error={allUsersLoadingError}/>

        if (allProvidersLoadingError != null)
            return <ErrorDisplay error={allProvidersLoadingError}/>

        if (loadingAllUsers || loadingAllProviders)
            return <Loading/>

        return <div className={style.layout}>
            {innerUsers.length > 0 &&
                <div style={{ gridColumn: 1 }}>
                    <Pane header={t("domain.transferProviders.labels.users")}>
                        <Flex>
                            {innerUsers.map(user =>
                                <Button onClick={() => onUserSelect(user)}
                                        text={user.name}
                                        buttonStyle="text"
                                        key={user.id}/>
                            )}
                        </Flex>
                    </Pane>
                </div>
            }

            {innerProviders.length > 0 &&
                <div style={{ gridColumn: innerUsers.length > 0 ? 2 : 1 }}>
                    <Pane header={t("domain.transferProviders.labels.providers")}>
                        <Flex>
                            {innerProviders.map(provider =>
                                <Button onClick={() => onProviderSelect(provider)}
                                        text={provider.name}
                                        buttonStyle="text"
                                        key={provider.id}/>
                            )}
                        </Flex>
                    </Pane>
                </div>
            }
        </div>
    }

    // Events

    function onSelect() {
        if (!loadedAllUsers && allUsersLoadingError == null)
            setLoadingAllUsers(true)

        if (!loadedAllProviders && allProvidersLoadingError == null)
            setLoadingAllProviders(true)

        setSelecting(true)
    }

    function onUserSelect(user: User) {
        setInnerValue(oldValue => {
            changedRef.current = true

            return oldValue.copy({
                userId: user.id,
                providerId: null,
            })
        })

        setSelecting(false)
    }

    function onProviderSelect(provider: Provider) {
        setInnerValue(oldValue => {
            changedRef.current = true

            return oldValue.copy({
                userId: null,
                providerId: provider.id,
            })
        })

        setSelecting(false)
    }

    function onPercentChange(percent: Decimal) {
        setInnerValue(oldValue => {
            changedRef.current = true
            return oldValue.copy({ percent })
        })
    }
})

TransferProviderEditor.displayName = "TransferProviderEditor"

export default TransferProviderEditor
