import { DeepReadonly, Nullish, tryNormalizeNullableUuid } from "my-util"

import AbstractNotification,
       { AbstractNotificationOptionsBase,
         AbstractNotificationCopyOptions,
         AbstractNotificationCreationOptions } from "./AbstractNotification"

import NotificationType,
       { NewChatMessagesNotificationType,
         NEW_CHAT_MESSAGES_NOTIFICATION_TYPE } from "./NotificationType"

export interface NewChatMessagesNotificationOptionsBase extends AbstractNotificationOptionsBase {
    fromId?: string | null
    count?: number | null
}

export interface NewChatMessagesNotificationCreationOptions
    extends
        Omit<AbstractNotificationCreationOptions<NotificationType>, "type">,
        NewChatMessagesNotificationOptionsBase
{}

export interface NewChatMessagesNotificationCopyOptions
    extends
        Omit<AbstractNotificationCopyOptions, "type">,
        Nullish<NewChatMessagesNotificationOptionsBase>
{}

export default class NewChatMessagesNotification extends AbstractNotification<
    NewChatMessagesNotificationType,
    NewChatMessagesNotificationCopyOptions
> {
    static createOrPass(
        arg?: NewChatMessagesNotification
            | DeepReadonly<NewChatMessagesNotificationCreationOptions>
            | null
    ): NewChatMessagesNotification {
        return arg instanceof NewChatMessagesNotification
            ? arg
            : new NewChatMessagesNotification(arg ?? {})
    }

    // Fields

    readonly fromId: string | null
    readonly count: number

    // Constructor

    constructor(options: DeepReadonly<NewChatMessagesNotificationCreationOptions> = {}) {
        super({ ...options, type: NEW_CHAT_MESSAGES_NOTIFICATION_TYPE })

        this.fromId = tryNormalizeNullableUuid(options.fromId)
        this.count = options.count ?? 1
    }

    // User IDs

    override get userIds(): string[] {
        const userIds = super.userIds

        if (this.fromId != null)
            userIds.push(this.fromId)

        return userIds
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<NewChatMessagesNotificationCopyOptions> = {},
    ): NewChatMessagesNotification {
        return new NewChatMessagesNotification({
            // Recipient ID

            recipientId: "recipientId" in options
                ? options.recipientId
                : this.recipientId,

            // Content

            fromId: "fromId" in options
                ? options.fromId
                : this.fromId,

            count: options.count ?? this.count,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
