import { useEffect, useImperativeHandle, useRef, useCallback,
         ForwardedRef, forwardRef, ReactNode, useLayoutEffect } from "react"

import { domRectContains } from "my-util"
import { useStateWithDeps } from "ui/hook"
import { Padding } from "../layout"
import Hovering from "../Hovering"
import style from "./style.module.css"

// Types

export interface TooltipProps {
    onShow?: (shown: boolean) => void
    show?: boolean

    children?: ReactNode

    gap?: number
    windowPadding?: number
    position?: TooltipPosition
}

export type TooltipPosition =
    | "top"
    | "bottom"

// Consts

export const DEFAULT_TOOLTIP_GAP: number = 8
export const DEFAULT_TOOLTIP_WINDOW_PADDING: number = 16
export const DEFAULT_TOOLTIP_POSITION: TooltipPosition = "top"

// Component

const Tooltip = forwardRef((
    {
        onShow, show,
        children,
        gap, windowPadding, position,
    }: Readonly<TooltipProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const innerGap = gap ?? DEFAULT_TOOLTIP_GAP
    const innerWindowPadding = windowPadding ?? DEFAULT_TOOLTIP_WINDOW_PADDING
    const innerPosition = position ?? DEFAULT_TOOLTIP_POSITION

    // Refs

    const containerRef = useRef(null as HTMLDivElement | null)
    const contentRef = useRef(null as HTMLDivElement | null)

    useImperativeHandle(ref, () => containerRef.current!, [])

    // State

    const [innerShow, setInnerShow] = useStateWithDeps(() => show ?? false, [show])

    const updatePositionMemo = useCallback(
        updatePosition,
        [innerGap, innerPosition, innerWindowPadding],
    )

    // Effects

    // - State propagation

    useEffect(
        () => { onShow?.(innerShow) },
        [onShow, innerShow],
    )

    // - Position update on show property toggling

    useEffect(() => {
        if (innerShow && show != null)
            updatePositionMemo()
    }, [innerShow, show, updatePositionMemo])

    // - Mouse move event handling

    useEffect(() => {
        if (show != null)
            return

        window.addEventListener("mousemove", handleMouseMove)

        return () => window.removeEventListener("mousemove", handleMouseMove)

        function handleMouseMove(event: MouseEvent) {
            const container = containerRef.current

            if (container == null)
                return

            const { x, y } = event

            const containerRect = container.getBoundingClientRect()

            const newVisible = domRectContains(containerRect, x, y) && (
                event.target instanceof Node
                    ? getSuperContainer()?.contains(event.target) ||
                      event.target.contains(container)

                    : true
            )

            if (newVisible)
                onMouseEnter()
            else
                onMouseLeave()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, updatePositionMemo])

    // - Resize handling

    useLayoutEffect(() => {
        const content = contentRef.current

        if (content == null)
            return

        const observer = new ResizeObserver(updatePositionMemo)

        observer.observe(content)

        return () => observer.disconnect()
    }, [updatePositionMemo])

    // Render

    return <div className={style.container}
                ref={containerRef}>
        <div style={{ display: innerShow ? "block" : "none" }}
             className={style.content}
             ref={contentRef}>
            <Hovering>
                <Padding padding="8px">
                    {children}
                </Padding>
            </Hovering>
        </div>
    </div>

    // Events

    function onMouseEnter() {
        setInnerShow(true)
        updatePositionMemo()
    }

    function onMouseLeave() {
        setInnerShow(false)
    }

    // Util

    function getSuperContainer(): HTMLElement | null {
        const container = containerRef.current

        if (container == null)
            return null

        let superContainer = container.parentElement

        while (superContainer != null)  {
            const computedStyles = getComputedStyle(superContainer)

            if (computedStyles.position === "relative")
                return superContainer

            superContainer = superContainer.parentElement
        }

        return null
    }

    function updatePosition() {
        // Elements getting

        const container = containerRef.current

        if (container == null)
            return

        const content = contentRef.current

        if (content == null)
            return

        // Calculation

        const containerRect = container.getBoundingClientRect()
        const contentRect = content.getBoundingClientRect()

        // - Left

        let newContentLeft = containerRect.x + .5 * (containerRect.width - contentRect.width)

        if (newContentLeft < innerWindowPadding)
            newContentLeft = innerWindowPadding

        // - Top

        let newContentTop = innerPosition === "top"
            ? containerRect.top - contentRect.height - innerGap
            : containerRect.bottom + innerGap

        if (newContentTop < innerWindowPadding)
            newContentTop = innerWindowPadding

        // Update

        content.style.left = `${newContentLeft}px`
        content.style.top = `${newContentTop}px`
    }
})

Tooltip.displayName = "Tooltip"

export default Tooltip
