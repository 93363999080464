import assert from "assert"
import { ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { v4 as generateRandomUuid } from "uuid"
import { useNavigate, useSearchParams } from "react-router-dom"
import { createInvite, getAllUsers, InviteRequest } from "api"

import { messageTargetToObject, UserRole, User,
         UserSpecialization, isUserSpecialization,
         determineNullableMessageTargetType, isUserRole,
         MessageTargetType, MANAGER_USER_ROLE, CLIENT_USER_ROLE } from "model"

import { DAY_MILLIS, durationToMillis, millisToDuration,
         SECOND_MILLIS, Duration, tryNormalizeNullableUuid } from "my-util"

import { MAX_SHORT_TEXT_LENGTH, validateMessageTarget } from "validation"
import { UserContext } from "ui/context"
import { useUsers, useWindowSize } from "ui/hook"
import { Error403Page, SessionExpiredErrorPage } from "ui/page/error"
import { createRegistrationPagePath } from "ui/page/RegistrationPage/path"
import { Page, UserCheckList } from "ui/component"

import { Pane, Input, Limit, TextArea, Form, Flex,
         DurationInput, MessageTargetInput, FormControls,
         CheckBox, UserRoleSelect, LoadingIndicator, ErrorText, DiLangInput,
         UserSpecializationSelect} from "ui/ui"

import InviteHeader from "../InviteHeader"
import { createInvitePagePath } from "../InvitePage/path"
import style from "./style.module.css"

// Consts

export const INVITE_CREATION_PAGE_MESSAGE_TARGET_SEARCH_PARAM = "message-target"
export const INVITE_CREATION_PAGE_NAME_SEARCH_PARAM = "name"
export const INVITE_CREATION_PAGE_EN_COMPANY_SEARCH_PARAM = "en-company"
export const INVITE_CREATION_PAGE_RU_COMPANY_SEARCH_PARAM = "ru-company"
export const INVITE_CREATION_PAGE_REFERENCE_SEARCH_PARAM = "reference"
export const INVITE_CREATION_PAGE_ROLE_SEARCH_PARAM = "role"
export const INVITE_CREATION_PAGE_SPECIALIZATION_SEARCH_PARAM = "specialization"
export const INVITE_CREATION_PAGE_TEXT_SEARCH_PARAM = "text"
export const INVITE_CREATION_PAGE_COMMENT_SEARCH_PARAM = "comment"
export const INVITE_CREATION_PAGE_DURATION_SEARCH_PARAM = "duration"
export const INVITE_CREATION_PAGE_PROVIDER_ID_SEARCH_PARAM = "provider-id"

// Component

export default function InviteCreationPage() {
    const [t] = useTranslation()

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const { width: windowWidth } = useWindowSize()
    const mobile = windowWidth < 800

    const [localUser] = useContext(UserContext)
    const storedUsers = useUsers()

    // State

    const [id] = useState(generateRandomUuid())

    const providerId = useMemo(getProviderId, [searchParams])

    const [messageTarget, setMessageTarget] = useState(getInitialMessageTarget())

    const messageTargetInputInvalid =
        messageTarget != null &&
        validateMessageTarget(messageTarget) != null

    const messageTargetInvalid =
        messageTarget == null ||
        messageTargetInputInvalid

    const [messageTargetType, setMessageTargetType] = useState(
        determineNullableMessageTargetType(messageTarget)
    )

    const [name, setName] = useState(getInitialName())
    const [company, setCompany] = useState(getInitialCompany())
    const [reference, setReference] = useState(getInitialReference())
    const [role, setRole] = useState(getInitialRole())
    const [specialization, setSpecialization] = useState(getInitialSpecialization())
    const [text, setText] = useState(getInitialText())
    const [comment, setComment] = useState(getInitialComment())
    const [duration, setDuration] = useState(getInitialDuration())

    const [canManageTransfers, setCanManageTransfers] = useState(true)
    const [canManageInvites, setCanManageInvites] = useState(true)
    const [canSendInvites, setCanSendInvites] = useState(true)
    const [canManageUsers, setCanManageUsers] = useState(true)
    const [canManageProviders, setCanManageProviders] = useState(true)
    const [canSeeAllUsers, setCanSeeAllUsers] = useState(true)
    const [visibleUserIds, setVisibleUserIds] = useState(new Array<string>())

    const [usersById, setUsersById] = useState(undefined as Map<string, User> | undefined)
    const [loadingUsers, setLoadingUsers] = useState(false)
    const [usersLoadingError, setUsersLoadingError] = useState(undefined as unknown)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined as any)

    // Effects

    useEffect(() => {
        if (!loadingUsers)
            return

        const controller = new AbortController()

        getAllUsers(controller.signal)
            .then(users => {
                setUsersById(User.groupById(users))
                storedUsers.addAll(users)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setUsersLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingUsers(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingUsers])

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    if (!localUser.hasRightToSendInvites)
        return <Error403Page/>

    return <Page
        type="main"
        title={
            <InviteHeader text={t("sections.clients.invites.new.header").toUpperCase()}
                          inviteId={id}/>
        }>
        <Pane>
            <Form onSubmit={onSend}
                  loading={loading}
                  height="100%">
                <Flex justify="space-between"
                      align="start"
                      height="100%">
                    {renderFields()}
                    {renderControls()}
                </Flex>
            </Form>
        </Pane>
    </Page>

    function renderFields(): ReactNode {
        const MAX_WIDTH = "400px"

        return <Flex align="start">
            <Limit maxWidth={MAX_WIDTH}>
                <Flex align="start">
                    <MessageTargetInput onChange={onMessageTargetChange}
                                        value={messageTarget}

                                        label={t("messageTargets.labels.messageTarget")}
                                        placeholder={t("messageTargets.placeholders.messageTarget")}

                                        invalid={messageTargetInputInvalid}
                                        loading={loading}
                                        required/>

                    <Input onChange={setName}
                           value={name}

                           label={t("domain.invites.labels.name")}
                           placeholder={t("domain.invites.placeholders.name")}

                           loading={loading}

                           max={MAX_SHORT_TEXT_LENGTH}/>
                </Flex>
            </Limit>

            <DiLangInput onChange={(en, ru) => setCompany({ en, ru })}
                         enValue={company.en}
                         ruValue={company.ru}

                         label={t("domain.invites.labels.company")}
                         placeholder={t("domain.invites.placeholders.company")}

                         loading={loading}

                         max={MAX_SHORT_TEXT_LENGTH}

                         maxWidth={MAX_WIDTH}/>

            <Limit maxWidth={MAX_WIDTH}>
                <Flex align="start">
                    <Input onChange={setReference}
                           value={reference}

                           label={t("domain.invites.labels.reference")}
                           placeholder={t("domain.invites.placeholders.reference")}

                           loading={loading}

                           max={MAX_SHORT_TEXT_LENGTH}/>

                    <UserRoleSelect onSelect={setRole}
                                    selected={role}

                                    hideAgent={!localUser?.isAgent}
                                    hideManager={!localUser?.isManager}
                                    hideAdmin={!localUser?.isAdmin}

                                    label={t("domain.userRights.labels.role")}

                                    loading={loading}/>

                    {role === MANAGER_USER_ROLE && localUser?.isAdmin && <>
                        <UserSpecializationSelect onSelect={setSpecialization}
                                                  selected={specialization}

                                                  label={t("domain.userRights.labels.specialization")}

                                                  loading={loading}/>

                        <CheckBox onChange={setCanManageTransfers}
                                  checked={canManageTransfers}

                                  label={t("domain.userRights.labels.canManageTransfers")}

                                  loading={loading}/>

                        <CheckBox onChange={setCanManageInvites}
                                  checked={canManageInvites}

                                  label={t("domain.userRights.labels.canManageInvites")}

                                  loading={loading}/>

                        <CheckBox onChange={setCanSendInvites}
                                  checked={canSendInvites}

                                  label={t("domain.userRights.labels.canSendInvites")}

                                  loading={loading}/>

                        <CheckBox onChange={setCanManageUsers}
                                  checked={canManageUsers}

                                  label={t("domain.userRights.labels.canManageUsers")}

                                  loading={loading}/>

                        <CheckBox onChange={setCanManageProviders}
                                  checked={canManageProviders}

                                  label={t("domain.userRights.labels.canManageProviders")}

                                  loading={loading}/>

                        <CheckBox onChange={checked => onCanSeeAllUsersChange(!checked)}
                                  checked={!canSeeAllUsers}

                                  label={t("domain.userRights.labels.canNotSeeAllUsers")}

                                  loading={loading}/>

                        {!canSeeAllUsers &&
                            <Pane header={t("domain.userRights.labels.visibleUsers")}
                                  backgroundColor="white"
                                  paneStyle="widget"
                                  width="100%">
                                  {(() => {
                                    if (usersLoadingError != null)
                                        return <ErrorText error={usersLoadingError}/>

                                    if (usersById == null)
                                        return <LoadingIndicator/>

                                    return <div className={style.userList}>
                                        <UserCheckList onChange={onVisibleUsersChange}
                                                    checked={visibleUserIds}
                                                    linkTarget="_blank"
                                                    users={usersById}/>
                                    </div>
                                  })()}
                            </Pane>
                        }
                    </>}

                    <TextArea onChange={setText}
                              value={text}

                              label={t("domain.invites.labels.text")}
                              placeholder={t("domain.invites.placeholders.text")}
                              information={t("sections.clients.invites.new.messages.linkWillBeSent")}

                              loading={loading}

                              resize="vertical"

                              max={MAX_SHORT_TEXT_LENGTH}
                              showMax/>

                    <TextArea onChange={setComment}
                              value={comment}

                              label={t("domain.invites.labels.comment")}
                              placeholder={t("domain.invites.placeholders.comment")}

                              loading={loading}

                              resize="vertical"

                              max={MAX_SHORT_TEXT_LENGTH}
                              showMax/>

                    <DurationInput label={t("domain.invites.labels.duration")}
                                   onChange={setDuration}
                                   loading={loading}
                                   showSeconds={process.env.NODE_ENV === "development"}
                                   {...duration}/>
                </Flex>
            </Limit>
        </Flex>
    }

    function renderControls(): ReactNode {
        return <FormControls
            mobile={mobile}

            loading={loading}
            error={error}

            buttons={[
                {
                    type: "submit",
                    position: "left",
                    disabled: messageTargetInvalid,
                    text: t("domain.invites.buttons.send"),
                },

                {
                    onClick: onBack,
                    buttonStyle: "text",
                    position: "right",
                    text: t("misc.buttons.back"),
                },
            ]}

            apiErrorMessageMapping={{
                409: t("messageTargets.messages.errors.occupied.messageTarget"),
            }}
        />
    }

    // Events

    function onMessageTargetChange(
        newMessageTarget: string,
        newMessageTargetType: MessageTargetType | null,
    ) {
        setMessageTarget(newMessageTarget)
        setMessageTargetType(newMessageTargetType)
    }

    function onCanSeeAllUsersChange(checked: boolean) {
        if (!checked && usersById == null)
            setLoadingUsers(true)

        setCanSeeAllUsers(checked)
    }

    function onVisibleUsersChange(users: User[]) {
        setVisibleUserIds(users.map(({ id }) => id))
    }

    async function onSend() {
        setLoading(true)

        try {
            assert(
                messageTarget != null &&
                messageTargetType != null
            )

            const durationSeconds = durationToMillis(duration) / SECOND_MILLIS
            const link = window.location.origin + createRegistrationPagePath(id)

            const request: InviteRequest = {
                id,
                providerId,

                ...messageTargetToObject(messageTarget, messageTargetType),

                duration: durationSeconds,

                enCompany: company.en,
                ruCompany: company.ru,

                name, reference, text, comment, link,

                role, specialization,
                canManageTransfers, canManageInvites, canSendInvites, canManageUsers, canManageProviders,
                canSeeAllUsers, visibleUserIds,
            }

            await createInvite(request)

            const newPath = createInvitePagePath(id)

            navigate(newPath, { replace: true })
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    function onBack() {
        navigate(-1)
    }

    // Util

    function getInitialMessageTarget(): string | undefined {
        const messageTarget = decodeNullableUriComponent(
            searchParams.get(INVITE_CREATION_PAGE_MESSAGE_TARGET_SEARCH_PARAM)
        )?.trim()

        return messageTarget.length !== 0
            ? messageTarget
            : undefined
    }

    function getInitialName(): string {
        return decodeNullableUriComponent(
            searchParams.get(INVITE_CREATION_PAGE_NAME_SEARCH_PARAM)
        ).trim()
    }

    function getInitialCompany(): { en: string, ru: string } {
        const en = decodeNullableUriComponent(
            searchParams.get(INVITE_CREATION_PAGE_EN_COMPANY_SEARCH_PARAM)
        ).trim()

        const ru = decodeNullableUriComponent(
            searchParams.get(INVITE_CREATION_PAGE_RU_COMPANY_SEARCH_PARAM)
        ).trim()

        return { en, ru }
    }

    function getInitialReference(): string {
        const param = searchParams.get(INVITE_CREATION_PAGE_REFERENCE_SEARCH_PARAM)

        if (param == null)
            return `${t("misc.words.from")} ${localUser?.formalName}`

        return decodeURIComponent(param).trim()
    }

    function getInitialRole(): UserRole {
        const param = searchParams.get(INVITE_CREATION_PAGE_ROLE_SEARCH_PARAM)
            ?.trim()
            ?.toLowerCase()

        return param != null && isUserRole(param)
            ? param
            : CLIENT_USER_ROLE
    }

    function getInitialSpecialization(): UserSpecialization | null {
        const param = searchParams.get(INVITE_CREATION_PAGE_SPECIALIZATION_SEARCH_PARAM)
            ?.trim()
            ?.toLowerCase()

        return param != null && isUserSpecialization(param)
            ? param
            : null
    }

    function getInitialText(): string {
        return decodeNullableUriComponent(
            searchParams.get(INVITE_CREATION_PAGE_TEXT_SEARCH_PARAM)
        )?.trim()
    }

    function getInitialComment(): string {
        return decodeNullableUriComponent(
            searchParams.get(INVITE_CREATION_PAGE_COMMENT_SEARCH_PARAM)
        )?.trim()
    }

    function getInitialDuration(): Duration {
        return millisToDuration(getInitialDurationMillis())
    }

    function getInitialDurationMillis(): number {
        const param = searchParams.get(INVITE_CREATION_PAGE_DURATION_SEARCH_PARAM)

        if (param == null)
            return DAY_MILLIS

        const millis = Number(decodeURIComponent(param))

        if (!isFinite(millis))
            return DAY_MILLIS

        return millis
    }

    function decodeNullableUriComponent(component?: string | null): string {
        return component != null
            ? decodeURIComponent(component)
            : ""
    }

    function getProviderId(): string | null {
        const param = searchParams.get(INVITE_CREATION_PAGE_PROVIDER_ID_SEARCH_PARAM)

        if (param == null)
            return null

        const decodedParam = decodeURIComponent(param)
        const id = tryNormalizeNullableUuid(decodedParam)

        return id
    }
}
