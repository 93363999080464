import { ForwardedRef, forwardRef, Fragment, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { editIconUrl, envelopeIconUrl, trashCanIconUrl } from "image"
import { Provider } from "model"
import { DeepReadonly } from "my-util"
import { Button, Email, Flex, Phone } from "ui/ui"
import style from "./style.module.css"

export interface ProviderTableProps {
    onDelete?: (provider: Provider) => void
    onEdit?: (provider: Provider) => void
    onInvite?: (provider: Provider) => void

    hideDeleteButton?: boolean
    hideEditButton?: boolean
    hideInviteButton?: boolean

    providers: Iterable<Provider>

    width?: string
}

const ProviderTable = forwardRef((
    {
        onDelete, onEdit, onInvite,
        hideDeleteButton, hideEditButton, hideInviteButton,
        providers,
        width,
    }: DeepReadonly<ProviderTableProps>,
    ref: ForwardedRef<HTMLTableElement>,
) => {
    const [t] = useTranslation()

    // State

    const innerProviders = useMemo(
        () => [...providers],
        [providers]
    )

    // Render

    return <table className={style.table}
                  width={width}
                  ref={ref}>
        <tbody>
            {innerProviders.map(provider =>
                <Fragment key={provider.id}>
                    {/* Company */}

                    <tr>
                        <td className={style.provider}>
                            {provider.anyCompany ?? t("domain.company.messages.notSet")}
                        </td>

                        <td rowSpan={4}>
                            <Flex justify="start"
                                    height="152px"
                                    gap="8px">
                                {!hideEditButton &&
                                    <Button onClick={() => onEdit?.(provider)}

                                            iconSrc={editIconUrl}
                                            iconAlt="Edit icon"

                                            width="32px"
                                            height="32px"

                                            buttonStyle="text"/>
                                }

                                {!hideInviteButton &&
                                    <Button onClick={() => onInvite?.(provider)}

                                            iconSrc={envelopeIconUrl}
                                            iconAlt="Envelope icon"

                                            width="32px"
                                            height="32px"

                                            buttonStyle="text"/>
                                }

                                {!hideDeleteButton &&
                                    <Button onClick={() => onDelete?.(provider)}

                                            iconSrc={trashCanIconUrl}
                                            iconAlt="Trash can icon"

                                            width="32px"
                                            height="32px"

                                            buttonStyle="text"

                                            critical/>
                                }
                            </Flex>
                        </td>
                    </tr>

                    {/* Name */}

                    <tr>
                        <td className={style.provider}>
                            {provider.name}
                        </td>
                    </tr>

                    {/* Phone */}

                    <tr>
                        <td className={style.provider}>
                            {provider.phone != null
                                ? <Phone phone={provider.phone}/>
                                : t("messageTargets.messages.phoneNotSet")
                            }
                        </td>
                    </tr>

                    {/* Email */}

                    <tr>
                        <td className={style.provider}>
                            {provider.email != null
                                ? <Email email={provider.email}/>
                                : t("messageTargets.messages.emailNotSet")
                            }
                        </td>
                    </tr>

                    {/* Gap */}

                    <tr className={style.gap}/>
                </Fragment>
            )}
        </tbody>
    </table>
})

ProviderTable.displayName = "ProviderTable"

export default ProviderTable
