import AbstractModelObject,
       { AbstractModelObjectCopyOptions,
         AbstractModelObjectCreationOptions } from "model/AbstractModelObject"

import { DeepReadonly, map, tryNormalizeUuid } from "my-util"

export interface ChatMessageBaseOptionsBase {
    text?: string | null
    documentIds?: string[] | null
    readByIds?: Iterable<string> | null
    edited?: boolean | null
}

export interface ChatMessageBaseCreationOptions
    extends
        AbstractModelObjectCreationOptions,
        ChatMessageBaseOptionsBase
{}

export interface ChatMessageBaseCopyOptions
    extends
        AbstractModelObjectCopyOptions,
        ChatMessageBaseOptionsBase
{}

export default abstract class ChatMessageBase<CopyOptions extends ChatMessageBaseCopyOptions>
    extends
        AbstractModelObject<CopyOptions>
{
    // Fields

    // - Payload

    readonly text: string | null
    readonly documentIds: readonly string[]

    // - Meta

    readonly readByIds: ReadonlySet<string>
    readonly edited: boolean

    // Constructor

    constructor(options: DeepReadonly<ChatMessageBaseCreationOptions>) {
        super(options)

        // Payload

        this.text = options.text ?? null
        this.documentIds = options.documentIds?.map(tryNormalizeUuid) ?? []

        // Meta

        this.readByIds = new Set(map(options.readByIds ?? [], tryNormalizeUuid))
        this.edited = options.edited ?? false
    }
}
