import { t } from "i18next"
import { Comparator, map } from "my-util"
import type Transfer from "./Transfer"

import { DONE_TRANSFER_STATUS, NEW_TRANSFER_STATUS, PAID_TRANSFER_STATUS,
         PAYMENT_EXPIRED_TRANSFER_STATUS, REJECTED_TRANSFER_STATUS,
         WAITING_MOMENT_TRANSFER_STATUS, WAITING_PAYMENT_TRANSFER_STATUS } from "./TransferStatus"

// Types

type TransferGroup =
    | NewTransferGroup
    | InWorkTransferGroup
    | TodayTransferGroup
    | DoneTransferGroup
    | DeclinedTransferGroup
    | StoppedTransferGroup

export default TransferGroup

export type NewTransferGroup = typeof NEW_TRANSFER_GROUP
export type InWorkTransferGroup = typeof IN_WORK_TRANSFER_GROUP
export type TodayTransferGroup = typeof TODAY_TRANSFER_GROUP
export type DoneTransferGroup = typeof DONE_TRANSFER_GROUP
export type DeclinedTransferGroup = typeof DECLINED_TRANSFER_GROUP
export type StoppedTransferGroup = typeof STOPPED_TRANSFER_GROUP

// Consts

export const NEW_TRANSFER_GROUP = "new"
export const IN_WORK_TRANSFER_GROUP = "in-work"
export const TODAY_TRANSFER_GROUP = "today"
export const DONE_TRANSFER_GROUP = "done"
export const DECLINED_TRANSFER_GROUP = "declined"
export const STOPPED_TRANSFER_GROUP = "stopped"

// Get

export function getTransferGroup(transfer: Transfer): TransferGroup {
    if (transfer.isStopped)
        return STOPPED_TRANSFER_GROUP

    if (transfer.isToday)
        return TODAY_TRANSFER_GROUP

    switch (transfer.status) {
        case NEW_TRANSFER_STATUS:
            return NEW_TRANSFER_GROUP

        case WAITING_MOMENT_TRANSFER_STATUS:
        case WAITING_PAYMENT_TRANSFER_STATUS:
        case PAID_TRANSFER_STATUS:
            return IN_WORK_TRANSFER_GROUP

        case DONE_TRANSFER_STATUS:
            return DONE_TRANSFER_GROUP

        case REJECTED_TRANSFER_STATUS:
        case PAYMENT_EXPIRED_TRANSFER_STATUS:
            return DECLINED_TRANSFER_GROUP
    }
}

// Name

export function getTransferGroupName(group: TransferGroup): string {
    return t(`domain.transfers.groups.${group}`)
}

// Is-check

export function isTransferGroup(s: string): s is TransferGroup {
    switch (s) {
        case NEW_TRANSFER_GROUP:
        case IN_WORK_TRANSFER_GROUP:
        case TODAY_TRANSFER_GROUP:
        case DONE_TRANSFER_GROUP:
        case DECLINED_TRANSFER_GROUP:
        case STOPPED_TRANSFER_GROUP:
            s satisfies TransferGroup
            return true

        default:
            return false
    }
}

// Presence

export interface TransferGroupPresence {
    newPresent: boolean
    inWorkPresent: boolean
    todayPresent: boolean
    donePresent: boolean
    declinedPresent: boolean
    stoppedPresent: boolean
}

export function checkTransferGroupPresence(transfers: Iterable<Transfer>): TransferGroupPresence {
    const groups = map(transfers, getTransferGroup)

    return {
        newPresent: groups.some(group => group === NEW_TRANSFER_GROUP),
        inWorkPresent: groups.some(group => group === IN_WORK_TRANSFER_GROUP),
        todayPresent: groups.some(group => group === TODAY_TRANSFER_GROUP),
        donePresent: groups.some(group => group === DONE_TRANSFER_GROUP),
        declinedPresent: groups.some(group => group === DECLINED_TRANSFER_GROUP),
        stoppedPresent: groups.some(group => group === STOPPED_TRANSFER_GROUP),
    }
}

// Colors

export interface TransferGroupColors {
    backgroundColor: string
    borderColor: string
    color: string
}

export const NEW_TRANSFER_GROUP_COLORS: TransferGroupColors = {
    backgroundColor: "#FFF0F7",
    borderColor: "orange",
    color: "orange",
}

export const IN_WORK_TRANSFER_GROUP_COLORS: TransferGroupColors = {
    backgroundColor: "#FEEAE9",
    borderColor: "blue",
    color: "blue",
}

export const TODAY_TRANSFER_GROUP_COLORS: TransferGroupColors = {
    backgroundColor: "#E5F9DE",
    borderColor: "red",
    color: "red",
}

export const DONE_TRANSFER_GROUP_COLORS: TransferGroupColors = {
    backgroundColor: "#D2EBFF",
    borderColor: "lime",
    color: "lime",
}

export const DECLINED_TRANSFER_GROUP_COLORS: TransferGroupColors = {
    backgroundColor: "white",
    borderColor: "gray",
    color: "gray",
}

export const STOPPED_TRANSFER_GROUP_COLORS: TransferGroupColors = {
    backgroundColor: "#fffcf4",
    borderColor: "#cc5500",
    color: "#cc5500",
}

export function getTransferGroupColorsByTransfer(transfer: Transfer): TransferGroupColors {
    return getTransferGroupColors(getTransferGroup(transfer))
}

export function getTransferGroupColors(group: TransferGroup): TransferGroupColors {
    switch (group) {
        case NEW_TRANSFER_GROUP:
            return NEW_TRANSFER_GROUP_COLORS

        case IN_WORK_TRANSFER_GROUP:
            return IN_WORK_TRANSFER_GROUP_COLORS

        case TODAY_TRANSFER_GROUP:
            return TODAY_TRANSFER_GROUP_COLORS

        case DONE_TRANSFER_GROUP:
            return DONE_TRANSFER_GROUP_COLORS

        case DECLINED_TRANSFER_GROUP:
            return DECLINED_TRANSFER_GROUP_COLORS

        case STOPPED_TRANSFER_GROUP:
            return STOPPED_TRANSFER_GROUP_COLORS

        default:
            return group satisfies never
    }
}

// Sorting order

export type TransfersByGroupSortingOrder = TransferGroup[]

export const NEW_TRANSFERS_BY_GROUP_SORTING_ORDER: Readonly<TransfersByGroupSortingOrder> = [
    NEW_TRANSFER_GROUP,
    TODAY_TRANSFER_GROUP,
    IN_WORK_TRANSFER_GROUP,
    DONE_TRANSFER_GROUP,
    STOPPED_TRANSFER_GROUP,
    DECLINED_TRANSFER_GROUP,
]

export const IN_WORK_TRANSFERS_BY_GROUP_SORTING_ORDER: Readonly<TransfersByGroupSortingOrder> = [
    IN_WORK_TRANSFER_GROUP,
    TODAY_TRANSFER_GROUP,
    NEW_TRANSFER_GROUP,
    DONE_TRANSFER_GROUP,
    STOPPED_TRANSFER_GROUP,
    DECLINED_TRANSFER_GROUP,
]

export const TODAY_TRANSFERS_BY_GROUP_SORTING_ORDER: Readonly<TransfersByGroupSortingOrder> = [
    TODAY_TRANSFER_GROUP,
    NEW_TRANSFER_GROUP,
    IN_WORK_TRANSFER_GROUP,
    DONE_TRANSFER_GROUP,
    STOPPED_TRANSFER_GROUP,
    DECLINED_TRANSFER_GROUP,
]

export const DONE_TRANSFERS_BY_GROUP_SORTING_ORDER: Readonly<TransfersByGroupSortingOrder> = [
    DONE_TRANSFER_GROUP,
    TODAY_TRANSFER_GROUP,
    NEW_TRANSFER_GROUP,
    IN_WORK_TRANSFER_GROUP,
    STOPPED_TRANSFER_GROUP,
    DECLINED_TRANSFER_GROUP,
]

export const DECLINED_TRANSFERS_BY_GROUP_SORTING_ORDER: Readonly<TransfersByGroupSortingOrder> = [
    DECLINED_TRANSFER_GROUP,
    NEW_TRANSFER_GROUP,
    TODAY_TRANSFER_GROUP,
    IN_WORK_TRANSFER_GROUP,
    DONE_TRANSFER_GROUP,
    STOPPED_TRANSFER_GROUP,
]

export const STOPPED_TRANSFERS_BY_GROUP_SORTING_ORDER: Readonly<TransfersByGroupSortingOrder> = [
    STOPPED_TRANSFER_GROUP,
    NEW_TRANSFER_GROUP,
    TODAY_TRANSFER_GROUP,
    IN_WORK_TRANSFER_GROUP,
    DONE_TRANSFER_GROUP,
    DECLINED_TRANSFER_GROUP,
]

export function getTransfersByGroupSortingOrderComparator(
    groupOrOrder: TransferGroup | Readonly<TransfersByGroupSortingOrder>,
): Comparator<Transfer> {
    const order = typeof groupOrOrder === "string"
        ? getTransfersByGroupSortingOrder(groupOrOrder)
        : groupOrOrder

    return (lhs: Transfer, rhs: Transfer) => {
        const lhsGroup = getTransferGroup(lhs)
        const lhsIndex = order.indexOf(lhsGroup)

        const rhsGroup = getTransferGroup(rhs)
        const rhsIndex = order.indexOf(rhsGroup)

        return Math.sign(lhsIndex - rhsIndex)
    }
}

export function getTransfersByGroupSortingOrder(group: TransferGroup) {
    switch (group) {
        case NEW_TRANSFER_GROUP:
            return NEW_TRANSFERS_BY_GROUP_SORTING_ORDER

        case IN_WORK_TRANSFER_GROUP:
            return IN_WORK_TRANSFERS_BY_GROUP_SORTING_ORDER

        case TODAY_TRANSFER_GROUP:
            return TODAY_TRANSFERS_BY_GROUP_SORTING_ORDER

        case DONE_TRANSFER_GROUP:
            return DONE_TRANSFERS_BY_GROUP_SORTING_ORDER

        case DECLINED_TRANSFER_GROUP:
            return DECLINED_TRANSFERS_BY_GROUP_SORTING_ORDER

        case STOPPED_TRANSFER_GROUP:
            return STOPPED_TRANSFERS_BY_GROUP_SORTING_ORDER

        default:
            return group satisfies never
    }
}
