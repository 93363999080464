import { useEffect, useState } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { getMe } from "api"
import { User } from "model"
import { UserContext } from "ui/context"

import { Error404Page,
         MainPage, MAIN_PAGE_PATH,
         AllClientsPage, ALL_CLIENTS_PAGE_PATH,
         AllInvitesPage, ALL_INVITES_PAGE_PATH,
         InvitePage, INVITE_PAGE_PATH,
         InviteCreationPage, INVITE_CREATION_PAGE_PATH,
         AllInviteApplicationsPage, ALL_INVITE_APPLICATIONS_PAGE_PATH,
         AllAgentsPage, ALL_AGENTS_PAGE_PATH,
         AllTransfersPage, ALL_TRANSFERS_PAGE_PATH,
         TransferPage, TRANSFER_PAGE_PATH,
         TransferCreationPage, TRANSFER_CREATION_PAGE_PATH,
         AllAdminsPage, ALL_ADMINS_PAGE_PATH,
         CalendarPage, CALENDAR_PAGE_PATH,
        //  ContactsPage, CONTACTS_PAGE_PATH,
         AllDocumentsPage, ALL_DOCUMENTS_PAGE_PATH,
         AllArchivedTransfersPage, ALL_ARCHIVED_TRANSFERS_PAGE_PATH,
         ARCHIVED_TRANSFER_PAGE_PATH, ArchivedTransferPage,
         MessengerPage, MESSENGER_PAGE_PATH,
         AllProvidersPage, ALL_PROVIDERS_PAGE_PATH,
         LoginPage, LOGIN_PAGE_PATH,
         PasswordResetPage, PASSWORD_RESET_PAGE_PATH,
         RegistrationPage, REGISTRATION_PAGE_PATH,
         UserPage, USER_PAGE_PATH,
         WalletPage, WALLET_PAGE_PATH } from "ui/page"

import { Loading } from "ui/ui"

export default function App() {
    // State

    const [loadingLocalUser, setLoadingLocalUser] = useState(true)
    const [localUser, setLocalUser] = useState(null as User | null)
    const refetchUser = () => setLoadingLocalUser(true)

    const [loadingTestModule, setLoadingTestModule] = useState(process.env.NODE_ENV === "development")
    const [testModule, setTestModule] = useState(null as typeof import("ui/page/test") | null)

    const loading = loadingLocalUser || loadingTestModule

    // Effects

    // - Local user loading

    useEffect(() => {
        if (!loadingLocalUser)
            return

        const controller = new AbortController()

        getMe(controller.signal)
            .then(setLocalUser)
            .catch(error => {
                if (controller.signal.aborted)
                    return

                setLocalUser(null)
                console.error(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingLocalUser(false)
            })

        return () => controller.abort()
    }, [loadingLocalUser])

    // - Test module loading

    useEffect(() => {
        if (!loadingTestModule)
            return

        import("ui/page/test")
            .then(setTestModule)
            .catch(error => { setTestModule(null); console.error(error) })
            .finally(() => setLoadingTestModule(false))
    }, [loadingTestModule])

    // - Drag events handling

    useEffect(() => {
        window.addEventListener("dragstart", handleDrag)
        window.addEventListener("dragover", handleDrag)
        window.addEventListener("drop", handleDrag)

        return () => {
            window.removeEventListener("dragstart", handleDrag)
            window.removeEventListener("dragover", handleDrag)
            window.removeEventListener("drop", handleDrag)
        }

        function handleDrag(event: DragEvent) {
            event.preventDefault()
        }
    }, [])

    // Render

    if (loading)
        return <Loading/>

    return <UserContext.Provider value={[localUser, setLocalUser, refetchUser]}>
        <BrowserRouter>
            <Routes>
                <Route path={MAIN_PAGE_PATH}
                       Component={MainPage}/>

                <Route path={ALL_CLIENTS_PAGE_PATH}
                       Component={AllClientsPage}/>

                <Route path={ALL_INVITES_PAGE_PATH}
                       Component={AllInvitesPage}/>

                <Route path={INVITE_PAGE_PATH}
                       Component={InvitePage}/>

                <Route path={INVITE_CREATION_PAGE_PATH}
                       Component={InviteCreationPage}/>

                <Route path={ALL_INVITE_APPLICATIONS_PAGE_PATH}
                       Component={AllInviteApplicationsPage}/>

                <Route path={ALL_AGENTS_PAGE_PATH}
                       Component={AllAgentsPage}/>

                <Route path={TRANSFER_PAGE_PATH}
                       Component={TransferPage}/>

                <Route path={ALL_TRANSFERS_PAGE_PATH}
                       Component={AllTransfersPage}/>

                <Route path={TRANSFER_CREATION_PAGE_PATH}
                       Component={TransferCreationPage}/>

                <Route path={ALL_ADMINS_PAGE_PATH}
                       Component={AllAdminsPage}/>

                <Route path={CALENDAR_PAGE_PATH}
                       Component={CalendarPage}/>

                {/* <Route path={CONTACTS_PAGE_PATH}
                       Component={ContactsPage}/> */}

                <Route path={ALL_DOCUMENTS_PAGE_PATH}
                       Component={AllDocumentsPage}/>

                <Route path={ALL_ARCHIVED_TRANSFERS_PAGE_PATH}
                       Component={AllArchivedTransfersPage}/>

                <Route path={ARCHIVED_TRANSFER_PAGE_PATH}
                       Component={ArchivedTransferPage}/>

                <Route path={MESSENGER_PAGE_PATH}
                       Component={MessengerPage}/>

                <Route path={ALL_PROVIDERS_PAGE_PATH}
                       Component={AllProvidersPage}/>

                <Route path={USER_PAGE_PATH}
                       Component={UserPage}/>

                <Route path={LOGIN_PAGE_PATH}
                       Component={LoginPage}/>

                <Route path={PASSWORD_RESET_PAGE_PATH}
                       Component={PasswordResetPage}/>

                <Route path={REGISTRATION_PAGE_PATH}
                       Component={RegistrationPage}/>

                <Route path={WALLET_PAGE_PATH}
                       Component={WalletPage}/>

                {testModule != null && <>
                    <Route path={testModule.MAIN_TEST_PAGE_PATH}
                           Component={testModule.MainTestPage}/>

                    <Route path={testModule.BUTTONS_TEST_PAGE_PATH}
                           Component={testModule.ButtonsTestPage}/>

                    <Route path={testModule.RADIOS_TEST_PAGE_PATH}
                           Component={testModule.RadiosTestPage}/>
                </>}

                <Route path="*"
                       Component={Error404Page}/>
            </Routes>
        </BrowserRouter>
    </UserContext.Provider>
}
