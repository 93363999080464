import { t } from "i18next"

// Types

type MessageTargetType =
    | PhoneMessageTargetType
    | EmailMessageTargetType

export default MessageTargetType

export type PhoneMessageTargetType = typeof PHONE_MESSAGE_TARGET_TYPE
export type EmailMessageTargetType = typeof EMAIL_MESSAGE_TARGET_TYPE

export type MessageTargetObject =
    | { phone: string }
    | { email: string }

// Consts

export const PHONE_MESSAGE_TARGET_TYPE = "phone"
export const EMAIL_MESSAGE_TARGET_TYPE = "email"

// Determination

export function determineNullableMessageTargetType(s?: string | null): MessageTargetType | null {
    return s != null
        ? determineMessageTargetType(s)
        : null
}

export function determineMessageTargetType(s: string): MessageTargetType {
    return s.match(/[.@\p{L}]/ui)
         ? EMAIL_MESSAGE_TARGET_TYPE
         : PHONE_MESSAGE_TARGET_TYPE
}

// Name

export function getMessageTargetTypeName(type: MessageTargetType): string {
    return t(`messageTargets.labels.${type}`)
}

// Is-check

export function isMessageTargetType(s: string): s is MessageTargetType {
    switch (s) {
        case PHONE_MESSAGE_TARGET_TYPE:
        case EMAIL_MESSAGE_TARGET_TYPE:
            s satisfies MessageTargetType
            return true

        default:
            return false
    }
}

// To object

export function messageTargetToObject(
    messageTarget: string,
    messageTargetType: MessageTargetType = determineMessageTargetType(messageTarget),
): MessageTargetObject {
    switch (messageTargetType) {
        case PHONE_MESSAGE_TARGET_TYPE:
            return { phone: messageTarget }

        case EMAIL_MESSAGE_TARGET_TYPE:
            return { email: messageTarget }
    }
}
