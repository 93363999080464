import { ForwardedRef, forwardRef, useReducer,
         ReactNode, useEffect, useState, useMemo,
         Fragment} from "react"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { cancelMfa, getAllUsers, JsonPatchValue,
         patchUserById, VERIFICATION_NEEDED_MFA_RESPONSE_STATUS } from "api"

import { User, UserStatus } from "model"
import { Comparator, DeepReadonly, splicedArray } from "my-util"
import { useStateWithDeps, useUsers } from "ui/hook"
import { createUserPagePath } from "ui/page/sections/users/UserPage/path"

import { Phone, Flex, Email, ErrorDisplay, Pane, Clickable,
         CheckBox, Loading, UserStatusColorLegend, UserRoleAbbr } from "ui/ui"

import UserCheckList from "../UserCheckList"
import UserLink from "../UserLink"
import style from "./style.module.css"

// User rights by user is reducer

function userRightsByUserIdReducer(
    oldRightsById: UserRightsByUserId,
    { userId, right, newValue }: UserRightsByUserIdReducerAction,
) {
    // Old rights getting

    let oldRights = oldRightsById.get(userId)

    if (oldRights == null) {
        if (!newValue)
            return oldRightsById

        oldRights = new Set()
    }

    // New rights creation

    const newRights = new Set(oldRights)

    if (newValue)
        newRights.add(right)
    else
        newRights.delete(right)

    // New state creation

    const newState = new Map(oldRightsById)

    if (newRights.size > 0)
        newState.set(userId, newRights)
    else
        newState.delete(userId)

    return newState
}

interface UserRightsByUserIdReducerAction {
    userId: string
    right: UserRight
    newValue: boolean
}

type UserRightsByUserId = Map<string, Set<UserRight>>

type UserRight =
    | BooleanUserRight
    | "visibleUserIds"

type BooleanUserRight =
    | "canManageTransfers"
    | "canManageInvites"
    | "canSendInvites"
    | "canManageUsers"
    | "canManageProviders"
    | "canSeeAllUsers"

// Component

export interface UserTableProps {
    onUsersChange?: (users: User[]) => void
    onError?: (error: unknown) => void

    users: Iterable<User>
    agents?: Iterable<User> | Map<string, User>

    showStatuses?: boolean
    showAgents?: boolean
    showRights?: boolean

    readonly?: boolean

    width?: string

    comparator?: Comparator<User>
    toSorted?: (users: readonly User[]) => User[]
}

const UserTable = forwardRef((
    {
        onUsersChange, onError,
        users, agents,
        showStatuses, showAgents, showRights,
        readonly,
        width,
        comparator, toSorted,
    }: DeepReadonly<UserTableProps>,
    ref: ForwardedRef<HTMLTableElement>,
) => {
    const [t] = useTranslation()

    const navigate = useNavigate()

    const storedUsers = useUsers()

    // State

    const [innerUsers, setInnerUsers] = useStateWithDeps(
        () => sortUsers(users),
        [users, comparator, toSorted],
    )

    const innerAgentsById = useMemo(
        () => User.groupByIdOrPassOrCreate(agents),
        [agents],
    )

    const [allUsersById, setAllUsers] = useState(null as Map<string, User> | null)
    const [loadingAllUsers, setLoadingAllUsersById] = useState(false)
    const [allUsersLoadingError, setAllUsersLoadingError] = useState(undefined as unknown)

    const [changingUserRightsByUserId, updateChangingUserRightsByUserId] = useReducer(
        userRightsByUserIdReducer,
        new Map<string, Set<UserRight>>(),
    )

    // Effects

    // - All users loading initiating

    useEffect(() => {
        if (allUsersById != null || loadingAllUsers || allUsersLoadingError != null)
            return

        setLoadingAllUsersById(innerUsers.some(({ canSeeAllUsers }) => !canSeeAllUsers))
    }, [allUsersById, allUsersLoadingError, loadingAllUsers, innerUsers])

    // - All users loading

    useEffect(() => {
        if (!loadingAllUsers)
            return

        const controller = new AbortController()

        getAllUsers(controller.signal)
            .then(users => {
                setAllUsers(User.groupById(users))
                storedUsers.addAll(users)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setAllUsersLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingAllUsersById(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAllUsers])

    // Render

    return <table className={style.table}
                  width={width}
                  ref={ref}>
        <tbody>
            {innerUsers.map((user, userIndex) => {
                const onRowClick = () => onUserClick(user)

                const agent = user.creatorId != null
                    ? innerAgentsById.get(user.creatorId)
                    : undefined

                return <Fragment key={user.id}>
                    <tr className={style.firstRow}
                        onClick={onRowClick}
                        key={user.id}>
                        <td>
                            {showStatuses != null &&
                                renderStatus(user.status)
                            }
                        </td>

                        <td>{t("domain.users.labels.user")}</td>

                        {showAgents &&
                            <td>{t("domain.users.labels.agent")}</td>
                        }

                        {showRights &&
                            <td>{t("domain.userRights.labels.rights")}</td>
                        }
                    </tr>

                    <tr onClick={onRowClick}>
                        <td className={style.role}
                            rowSpan={4}>
                            <UserRoleAbbr role={user.role}/>
                        </td>

                        <td className={style.user}>
                            {renderCompanyName(user)}
                        </td>

                        {showAgents && (
                            agent != null
                                ? <td className={style.agent}>
                                    {renderCompanyName(agent)}
                                </td>

                                : <td className={style.blankAgent}
                                      rowSpan={4}>
                                    –
                                </td>
                        )}

                        {showRights &&
                            <td className={style.rights}
                                rowSpan={4}>
                                {renderAllRights(user, userIndex)}
                            </td>
                        }
                    </tr>

                    <tr onClick={onRowClick}>
                        <td className={style.user}>
                            {renderName(user)}
                        </td>

                        {showAgents && agent != null &&
                            <td className={style.agent}>
                                {renderName(agent)}
                            </td>
                        }
                    </tr>

                    <tr onClick={onRowClick}>
                        <td className={style.user}>
                            {renderPhone(user.phone)}
                        </td>

                        {showAgents && agent != null &&
                            <td className={style.agent}>
                                {renderPhone(agent.phone)}
                            </td>
                        }
                    </tr>

                    <tr onClick={onRowClick}>
                        <td className={style.user}>
                            {renderEmail(user.email)}
                        </td>

                        {showAgents && agent != null &&
                            <td className={style.agent}>
                                {renderEmail(agent.email)}
                            </td>
                        }
                    </tr>

                    <tr className={style.gap}/>
                </Fragment>
            })}
        </tbody>
    </table>

    function renderStatus(status: UserStatus): ReactNode {
        return <UserStatusColorLegend status={status}/>
    }

    function renderCompanyName(user: User): ReactNode {
        const name = user.company.anyName

        if (name == null)
            return t("domain.company.messages.notSet")

        return <UserLink stopClickPropagation
                         whiteSpace="nowrap"
                         user={user}>
            {name}
        </UserLink>
    }

    function renderName(user: User): ReactNode {
        return <UserLink stopClickPropagation
                         whiteSpace="nowrap"
                         user={user}/>
    }

    function renderPhone(phone: string | null): ReactNode {
        return phone != null
            ? <Phone stopLinkClickPropagation
                     phone={phone}/>

            : t("messageTargets.messages.phoneNotSet")
    }

    function renderEmail(email: string | null): ReactNode {
        return email != null
            ? <Email stopLinkClickPropagation
                     email={email}/>

            : t("messageTargets.messages.emailNotSet")
    }

    function renderAllRights(
        user: User,
        userIndex: number = innerUsers.indexOf(user),
    ): ReactNode {
        return <Flex direction="horizontal"
                     height="160px">
            {renderAllBooleanRights(user, userIndex)}
            {renderVisibleUsers(user, userIndex)}
        </Flex>
    }

    function renderAllBooleanRights(
        user: User,
        userIndex: number = innerUsers.indexOf(user),
    ): ReactNode {
        return <Clickable stopPropagation
                          height="100%"
                          width="100%">
            <Pane backgroundColor="rgba(255, 255, 255, .4)"
                  paneStyle="widget"
                  height="100%"
                  width="100%">
                <Flex align="start">
                    {renderBooleanRight("canManageTransfers", user, userIndex)}
                    {renderBooleanRight("canManageInvites", user, userIndex)}
                    {renderBooleanRight("canSendInvites", user, userIndex)}
                    {renderBooleanRight("canManageUsers", user, userIndex)}
                    {renderBooleanRight("canManageProviders", user, userIndex)}
                    {renderBooleanRight("canSeeAllUsers", user, userIndex, true, t("domain.userRights.labels.canNotSeeAllUsers"))}
                </Flex>
            </Pane>
        </Clickable>
    }

    function renderBooleanRight(
        right: BooleanUserRight,
        user: User,
        userIndex: number = innerUsers.indexOf(user),
        inverse: boolean = false,
        label: string = t(`domain.userRights.labels.${right}`, ""),
    ): ReactNode {
        const value = user[right]
        const loading = changingUserRightsByUserId.get(user.id)?.has(right)

        return <Clickable stopPropagation>
            <CheckBox onChange={checked => onChange(inverse ? !checked : checked)}
                      checked={inverse ? !value : value}

                      label={label}

                      loading={loading}
                      readonly={readonly}

                      key={Math.random()}/>
        </Clickable>

        async function onChange(newValue: boolean) {
            return onUserRightChange(newValue, right, user, userIndex)
        }
    }

    function renderVisibleUsers(
        user: User,
        userIndex: number = innerUsers.indexOf(user),
    ): ReactNode {
        if (user.canSeeAllUsers)
            return

        const loading = changingUserRightsByUserId.get(user.id)?.has("visibleUserIds")

        return <Clickable stopPropagation
                          height="100%"
                          width="100%">
            <Pane backgroundColor="rgba(255, 255, 255, .4)"
                  paneStyle="widget"
                  height="100%"
                  width="100%">
                {(() => {
                    if (allUsersLoadingError != null)
                        return <ErrorDisplay error={allUsersLoadingError}/>

                    if (allUsersById == null)
                        return <Loading centerType="flex"/>

                    return <UserCheckList onChange={onChange}
                                          checked={user.visibleUserIds}

                                          users={allUsersById}

                                          loading={loading}
                                          readonly={readonly}

                                          whiteSpace="nowrap"/>
                })()}
            </Pane>
        </Clickable>

        async function onChange(visibleUsers: User[]) {
            const visibleUserIds = visibleUsers.map(({ id }) => id)

            onUserRightChange(visibleUserIds, "visibleUserIds", user, userIndex)
        }
    }

    // Events

    function onUserClick(user: User) {
        navigate(createUserPagePath(user.id))
    }

    async function onUserRightChange(
        newValue: JsonPatchValue,
        right: UserRight,
        user: User,
        userIndex: number = innerUsers.indexOf(user),
    ) {
        updateChangingUserRightsByUserId({
            userId: user.id,
            newValue: true,
            right,
        })

        try {
            const response = await patchUserById(user.id, [{
                op: "replace",
                path: `/${right}`,
                value: newValue,
            }])

            if (response.status === VERIFICATION_NEEDED_MFA_RESPONSE_STATUS) {
                await cancelMfa()
                throw new Error("MFA required but shouldn't")
            }

            if (userIndex < 0)
                return

            const newUser = new User(response.body)
            const newUsers = splicedArray(innerUsers, userIndex, 1, newUser)

            setInnerUsers(newUsers)
            onUsersChange?.(newUsers)
        } catch (error) {
            onError?.(error)
        } finally {
            updateChangingUserRightsByUserId({
                userId: user.id,
                newValue: false,
                right,
            })
        }
    }

    // Util

    function sortUsers(users: Iterable<User>): User[] {
        const userArray = [...users]

        if (toSorted != null)
            return toSorted(userArray)

        if (comparator != null)
            return userArray.sort(comparator)

        return userArray.sort((lhs, rhs) => {
            const lhsCompanyName = lhs.company.anyName
            const rhsCompanyName = rhs.company.anyName

            if (lhsCompanyName == null && rhsCompanyName == null)
                return 0

            if (lhsCompanyName == null)
                return -1

            if (rhsCompanyName == null)
                return 1

            return lhsCompanyName.localeCompare(rhsCompanyName)
        })
    }
})

UserTable.displayName = "UserTable"

export default UserTable
