import { WithCreatorId } from "model/interfaces"
import { DeepReadonly, Nullable, tryNormalizeNullableUuid } from "my-util"

import ChatMessageBase,
       { ChatMessageBaseCopyOptions, ChatMessageBaseCreationOptions } from "./ChatMessageBase"

export interface ChatMessageOptionsBase {
    creatorId?: string | null
    recipientId?: string | null
}

export interface ChatMessageCreationOptions
    extends
        ChatMessageBaseCreationOptions,
        ChatMessageOptionsBase
{}

export interface ChatMessageCopyOptions
    extends
        ChatMessageBaseCopyOptions,
        ChatMessageOptionsBase
{}

export default class ChatMessage
    extends
        ChatMessageBase<ChatMessageCopyOptions>

    implements
        Readonly<Nullable<WithCreatorId>>
{
    static createOrPass(arg: ChatMessage | DeepReadonly<ChatMessageCreationOptions>): ChatMessage {
        return arg instanceof ChatMessage
            ? arg
            : new ChatMessage(arg)
    }

    // Fields

    readonly creatorId: string | null
    readonly recipientId: string | null

    // Constructor

    constructor(options: DeepReadonly<ChatMessageCreationOptions>) {
        super(options)

        this.creatorId = tryNormalizeNullableUuid(options.creatorId)
        this.recipientId = tryNormalizeNullableUuid(options.recipientId)
    }

    // Copy

    protected override createCopy(options: DeepReadonly<ChatMessageCopyOptions> = {}): ChatMessage {
        return new ChatMessage({
            // IDs

            creatorId: "creatorId" in options
                ? options.creatorId
                : this.creatorId,

            recipientId: "recipientId" in options
                ? options.recipientId
                : this.recipientId,

            // Payload

            text: "text" in options
                ? options.text
                : this.text,

            documentIds: options.documentIds ?? this.documentIds,

            // Meta

            readByIds: options.readByIds ?? this.readByIds,
            edited: options.edited ?? this.edited,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
