import { ForwardedRef, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { EXPORT_TRANSFER_DIRECTION, IMPORT_TRANSFER_DIRECTION, TransferDirection } from "model"

import { EXPORT_ICON_ALT, EXPORT_ICON_FILTER, EXPORT_ICON_SRC,
         IMPORT_ICON_ALT, IMPORT_ICON_FILTER, IMPORT_ICON_SRC } from "ui/ui/icon"

import Radio from "ui/ui/Radio"

export interface TransferDirectionRadioProps {
    onChange?: (type: TransferDirection) => void
    checked?: TransferDirection

    readonly?: boolean
    loading?: boolean
    disabled?: boolean

    name?: string

    importHidden?: boolean
    importDisabled?: boolean

    exportHidden?: boolean
    exportDisabled?: boolean

    hideButton?: boolean

    width?: string
}

const TransferDirectionRadio = forwardRef((
    {
        onChange, checked,
        readonly, loading, disabled,
        name,
        importHidden, importDisabled,
        exportHidden, exportDisabled,
        hideButton,
        width,
    }: Readonly<TransferDirectionRadioProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    return <Radio
        onChange={onChange as (value: string) => void}
        checked={checked ?? "export" satisfies TransferDirection}

        readonly={readonly}
        loading={loading}
        disabled={disabled}

        name={name}

        hideButton={hideButton}

        width={width}

        items={{
            [IMPORT_TRANSFER_DIRECTION]: {
                hidden: importHidden,
                disabled: importDisabled,

                label: t("domain.transfers.directions.import"),

                iconSrc: IMPORT_ICON_SRC,
                iconAlt: IMPORT_ICON_ALT,
                iconFilter: IMPORT_ICON_FILTER,
            },

            [EXPORT_TRANSFER_DIRECTION]: {
                hidden: exportHidden,
                disabled: exportDisabled,

                label: t("domain.transfers.directions.export"),

                iconSrc: EXPORT_ICON_SRC,
                iconAlt: EXPORT_ICON_ALT,
                iconFilter: EXPORT_ICON_FILTER,
            },
        }}

        ref={ref}
    />
})

TransferDirectionRadio.displayName = "TransferDirectionRadio"

export default TransferDirectionRadio
