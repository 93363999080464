import { AnonymousUser, AnonymousUserCopyOptions, AnonymousUserCreationOptions } from "model/domain"
import { WithNamedCreator } from "model/interfaces"
import { DeepReadonly, Nullable } from "my-util"

import ChatMessageBase,
       { ChatMessageBaseCreationOptions, ChatMessageBaseCopyOptions } from "./ChatMessageBase"

export interface AnonymousChatMessageCreationOptions extends ChatMessageBaseCreationOptions {
    creator?: AnonymousUser | AnonymousUserCreationOptions | null
}

export interface AnonymousChatMessageCopyOptions extends ChatMessageBaseCopyOptions {
    creator?: AnonymousUser | AnonymousUserCopyOptions | null
}

export default class AnonymousChatMessage
    extends
        ChatMessageBase<AnonymousChatMessageCopyOptions>

    implements
        DeepReadonly<Nullable<WithNamedCreator>>
{
    static createOrPass(
        arg: AnonymousChatMessage | Readonly<AnonymousChatMessageCreationOptions>,
    ): AnonymousChatMessage {
        return arg instanceof AnonymousChatMessage
            ? arg
            : new AnonymousChatMessage(arg)
    }

    // Fields

    readonly creator: AnonymousUser | null

    // Constructor

    constructor(options: DeepReadonly<AnonymousChatMessageCreationOptions>) {
        super(options)

        this.creator = options.creator != null
            ? AnonymousUser.createOrPass(options.creator)
            : null
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<AnonymousChatMessageCopyOptions> = {},
    ): AnonymousChatMessage {
        return new AnonymousChatMessage({
            // Creator

            creator: AnonymousUser.copyOrPass(this.creator, options.creator),

            // Payload

            text: "text" in options
                ? options.text
                : this.text,

            documentIds: options.documentIds ?? this.documentIds,

            // Meta

            readByIds: options.readByIds ?? this.readByIds,
            edited: options.edited ?? false,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
