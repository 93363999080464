import { ReactNode, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { plusIconUrl } from "image"
import { deleteInviteById, getAllInvites, getAllMyInvites } from "api"
import { Invite } from "model"
import { useInvites } from "ui/hook"
import { UserContext } from "ui/context"
import { Error403Page, SessionExpiredErrorPage } from "ui/page/error"
import { InviteTable, Page } from "ui/component"

import { Button, InviteStatusColorLegendList, Center, Flex,
         Loading, Padding, ErrorDisplay, Link, ActionModal } from "ui/ui"

import { ALL_CLIENTS_PAGE_PATH } from "../../AllClientsPage/path"
import { ALL_INVITE_APPLICATIONS_PAGE_PATH } from "../applications/AllInviteApplicationsPage/path"
import { INVITE_CREATION_PAGE_PATH } from "../InviteCreationPage/path"

export default function AllInvitesPage() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const storedInvites = useInvites()

    const navigate = useNavigate()

    // State

    const [invites, setInvites] = useState(new Array<Invite>())
    const [loading, setLoading] = useState(localUser?.isAgent ?? false)
    const [error, setError] = useState(undefined as unknown)

    const [deletingId, setDeletingId] = useState(undefined as string | undefined)

    const [, setExpiredCount] = useState(0)

    // Effects

    useEffect(() => {
        if (!loading)
            return

        const controller = new AbortController()

        const load = localUser?.isManager
            ? getAllInvites
            : getAllMyInvites

        load(controller.signal)
            .then(invites => {
                storedInvites.addAll(invites)
                setInvites(invites)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoading(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    if (!localUser.isAgent)
        return <Error403Page/>

    return <Page compactTitle={renderCompactTitle()}
                 title={renderTitle()}

                 noCompactTitle={!localUser.hasRightToSendInvites}
                 autoMoveCompactTitle

                 type="main">
        {renderContent()}
    </Page>

    function renderCompactTitle(): ReactNode {
        return <Flex direction="horizontal"
                     width="fit-content"
                     gap="8px">
            {localUser?.hasRightToSendInvites &&
                <Button onClick={() => navigate(INVITE_CREATION_PAGE_PATH)}
                        iconSrc={plusIconUrl}
                        iconAlt="Plus icon"
                        width="32px"/>
            }

            <div style={{ height: "1em" }}>
                {t("sections.clients.invites.header").toUpperCase()}
            </div>
        </Flex>
    }

    function renderTitle(): ReactNode {
        return <Flex direction="horizontal"
                     justify="end"
                     gap="32px">
            <Flex direction="horizontal"
                  width="fit-content">
                <Link text={t("sections.clients.link")}
                      to={ALL_CLIENTS_PAGE_PATH}
                      whiteSpace="nowrap"
                      fontSize="16px"/>

                <Link text={t("sections.clients.invites.applications.link")}
                      to={ALL_INVITE_APPLICATIONS_PAGE_PATH}
                      whiteSpace="nowrap"
                      fontSize="16px"/>
            </Flex>
        </Flex>
    }

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (error)
            return <ErrorDisplay error={error}/>

        if (invites.length === 0)
            return <Center>
                <div style={{ whiteSpace: "pre" }}>
                    {localUser?.isManager
                        ? t("sections.clients.invites.messages.no")
                        : t("sections.clients.invites.messages.noYours")
                    }
                </div>
            </Center>

        return <Flex width="fit-content"
                     align="start"
                     gap="8px">
            <Padding paddingLeft="8px">
                <InviteStatusColorLegendList fontSize="12px"
                                             showLegend
                                             gap="8px"/>

            </Padding>

            <InviteTable onExpired={() => setExpiredCount(oldExpiredCount => ++oldExpiredCount)}
                         onDelete={({ id }) => setDeletingId(id)}

                         invites={invites}

                         showStatuses/>

            {deletingId != null &&
                <ActionModal onNo={() => setDeletingId(undefined)}
                             onYes={onDelete}
                             closeOnSuccess
                             critical>
                    {t("domain.invites.messages.warnings.deletion")}
                </ActionModal>
            }
        </Flex>
    }

    // Events

    async function onDelete() {
        if (deletingId == null)
            return

        await deleteInviteById(deletingId)

        setInvites(oldInvites => oldInvites.filter(({ id }) => id !== deletingId))
    }
}
