import assert from "assert"
import { ForwardedRef, ReactNode, forwardRef, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { arrowsLeftRightIconUrl } from "image"
import { getUserById } from "api"
import { Money, Provider, User } from "model"
import { isNullOrBlank, DeepReadonly, normalizeUrl } from "my-util"
import { useStateWithDeps, useUsers } from "ui/hook"
import { copyTransferFields, SignableDocumentFields, TransferFields } from "ui/fields"

import { DocumentListUpload, UserLink, DocumentUpload,
         SignableDocumentListEditor, ProductListEditor,
         RoutePointListEditor, UiDocument, isFieldDocument } from "ui/component"

import { Form, FormControls, FormControlsButton, Link, ErrorText,
         Label, Flex, TransferDirectionRadio, MoneyOutput, Icon, Output,
         Subheader, TimeOutput, DecimalOutput, DateOutput, LoadingIndicator } from "ui/ui"

import { TransferProviderListEditor } from "../provider"
import style from "./style.module.css"

export interface TransferViewFormProps {
    onSubmit?: () => void
    onReset?: () => void
    onChange?: (fields: TransferFields) => void

    fields: TransferFields
    buttons?: FormControlsButton[]

    users?: Map<string, User> | Iterable<User>
    providers?: Map<string, Provider> | Iterable<Provider>

    loading?: boolean
    disabled?: boolean
    error?: unknown

    width?: string
    height?: string
}

const TransferViewForm = forwardRef((
    {
        onSubmit, onReset, onChange,
        fields, buttons,
        users, providers,
        loading, disabled, error,
        width, height,
    }: DeepReadonly<TransferViewFormProps>,
    ref: ForwardedRef<HTMLFormElement>,
) => {
    const [t] = useTranslation()

    const usersStorage = useUsers()

    // State

    // - Users

    const [innerUsersById, setInnerUsersById] = useStateWithDeps<Map<string, User>>(
        oldUsersById => {
            const newUsersById = new Map(oldUsersById)
            const propsUsersById = User.groupByIdOrPassOrCreate(users)

            for (const [id, user] of propsUsersById)
                newUsersById.set(id, user)

            return newUsersById
        },

        [users],
    )

    // -- Creator

    const [loadingCreator, setLoadingCreator] = useStateWithDeps(
        () => fields.creatorId == null || !innerUsersById.has(fields.creatorId),
        [innerUsersById, fields],
    )

    const [creatorLoadingError, setCreatorLoadingError] = useState(undefined as unknown)

    // -- Agent

    const [loadingAgent, setLoadingAgent] = useStateWithDeps(
        () => {
            const { creatorId } = fields

            if (creatorId == null)
                return false

            const creator = innerUsersById.get(creatorId)

            if (creator?.creatorId == null)
                return

            return !innerUsersById.has(creator.creatorId)
        },

        [innerUsersById, fields],
    )

    const [agentLoadingError, setAgentLoadingError] = useState(undefined as unknown)

    // - RequisitesSource

    const [loadingRequisitesSource, setLoadingRequisitesSource] = useStateWithDeps(
        () => {
            const { requisitesSourceId } = fields

            if (requisitesSourceId == null)
                return false

            return !innerUsersById.has(requisitesSourceId)
        },

        [innerUsersById, fields],
    )

    const [requisitesSourceLoadingError, setRequisitesSourceLoadingError] = useState(undefined as unknown)

    // - Stopper

    const [loadingStopper, setLoadingStopper] = useStateWithDeps(
        () => {
            const { stopperId } = fields

            if (stopperId == null)
                return false

            return !innerUsersById.has(stopperId)
        },

        [innerUsersById, fields],
    )

    const [stopperLoadingError, setStopperLoadingError] = useState(undefined as unknown)

    // - Providers

    const [innerProvidersById, setInnerProvidersById] = useStateWithDeps<Map<string, Provider>>(
        oldProvidersById => {
            const newProvidersById = new Map(oldProvidersById)
            const propsProvidersById = Provider.groupByIdOrPassOrCreate(providers)

            for (const [id, provider] of propsProvidersById)
                newProvidersById.set(id, provider)

            return newProvidersById
        },

        [providers],
    )

    // Effects

    // - Creator loading

    useEffect(() => {
        if (!loadingCreator)
            return

        const { creatorId } = fields

        if (creatorId == null) {
            setLoadingCreator(false)
            return
        }

        const controller = new AbortController()

        getUserById(creatorId, controller.signal)
            .then(creator => {
                setInnerUsersById(oldUsersById => {
                    const newUsersById = new Map(oldUsersById)
                    newUsersById.set(creator.id, creator)
                    return newUsersById
                })

                usersStorage.add(creator)

                setLoadingAgent(creator.creatorId != null)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setCreatorLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingCreator(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCreator])

    // - Agent loading

    useEffect(() => {
        if (!loadingAgent)
            return

        const { creatorId } = fields

        const creator = creatorId != null
            ? innerUsersById.get(creatorId)
            : undefined

        const agentId = creator?.creatorId

        if (agentId == null) {
            setLoadingAgent(false)
            return
        }

        const controller = new AbortController()

        getUserById(agentId, controller.signal)
            .then(agent => {
                setInnerUsersById(oldUsersById => {
                    const newUsersById = new Map(oldUsersById)
                    newUsersById.set(agent.id, agent)
                    return newUsersById
                })

                usersStorage.add(agent)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setAgentLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingAgent(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAgent])

    // - RequisitesSource loading

    useEffect(() => {
        if (!loadingRequisitesSource)
            return

        const { requisitesSourceId } = fields

        if (requisitesSourceId == null) {
            setLoadingRequisitesSource(false)
            return
        }

        const controller = new AbortController()

        getUserById(requisitesSourceId, controller.signal)
            .then(requisitesSource => {
                setInnerUsersById(oldUsersById => {
                    const newUsersById = new Map(oldUsersById)
                    newUsersById.set(requisitesSource.id, requisitesSource)
                    return newUsersById
                })

                usersStorage.add(requisitesSource)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setRequisitesSourceLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingRequisitesSource(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingRequisitesSource])

    // - Stopper loading

    useEffect(() => {
        if (!loadingStopper)
            return

        const { stopperId } = fields

        if (stopperId == null) {
            setLoadingStopper(false)
            return
        }

        const controller = new AbortController()

        getUserById(stopperId, controller.signal)
            .then(stopper => {
                setInnerUsersById(oldUsersById => {
                    const newUsersById = new Map(oldUsersById)
                    newUsersById.set(stopper.id, stopper)
                    return newUsersById
                })

                usersStorage.add(stopper)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setStopperLoadingError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingStopper(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingStopper])

    // Render

    return <Form onSubmit={onSubmit}
                 onReset={onReset}

                 loading={loading}

                 width={width}
                 height={height}

                 ref={ref}>
        <Flex justify="space-between"
              height="100%">
            <div className={style.fields}>
                {renderCreator()}
                {renderAgent()}
                {renderRequisitesSource()}
                {renderStopper()}

                {renderDirection()}
                {renderMoment()}

                {renderRecipientSection()}

                {renderProductsSection()}
                {renderRouteSection()}
                {renderCurrencyRateSection()}
                {renderSharesAndExpensesSection()}
                {renderProvidersSection()}
                {renderDocumentsSection()}
                {renderOrdersSection()}
                {renderCommentsSection()}
            </div>

            {renderControls()}
        </Flex>
    </Form>

    // - Applicant

    function renderCreator(): ReactNode {
        const { creatorId } = fields

        if (creatorId == null)
            return null

        const creator = innerUsersById.get(creatorId)

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.applicant")}/>
            </div>

            <div className={style.column2To4Value}>
                {renderValue()}
            </div>
        </>

        function renderValue(): ReactNode {
            if (creator == null) {
                if (creatorLoadingError != null)
                    <ErrorText error={creatorLoadingError}
                               apiErrorMessageMapping={{
                                   403: t("domain.users.messages.errors.accessDenied"),
                                   404: t("domain.users.messages.errors.notFound"),
                               }}/>

                return <LoadingIndicator/>
            }

            return <Output>
                <UserLink user={creator}
                          showCompany/>
            </Output>
        }
    }

    // - Agent

    function renderAgent(): ReactNode {
        const { creatorId } = fields

        if (creatorId == null)
            return null

        const creator = innerUsersById.get(creatorId)

        if (creator?.creatorId == null)
            return null

        const agent = innerUsersById.get(creator.creatorId)

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.users.labels.agent")}/>
            </div>

            <div className={style.column2To4Value}>
                {renderValue()}
            </div>
        </>

        function renderValue(): ReactNode {
            if (agent == null) {
                if (agentLoadingError != null)
                    <ErrorText error={agentLoadingError}
                               apiErrorMessageMapping={{
                                   403: t("domain.users.messages.errors.accessDenied"),
                                   404: t("domain.users.messages.errors.notFound"),
                               }}/>

                return <LoadingIndicator/>
            }

            if (!agent.isAgent)
                return null

            return <Output>
                <UserLink user={agent}
                          showCompany/>
            </Output>
        }
    }

    // - RequisitesSource

    function renderRequisitesSource(): ReactNode {
        const { requisitesSourceId } = fields

        if (requisitesSourceId == null)
            return null

        const requisitesSource = innerUsersById.get(requisitesSourceId)

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.requisitesSource")}/>
            </div>

            <div className={style.column2To4Value}>
                {renderValue()}
            </div>
        </>

        function renderValue(): ReactNode {
            if (requisitesSource == null) {
                if (requisitesSourceLoadingError != null)
                    <ErrorText error={requisitesSourceLoadingError}
                               apiErrorMessageMapping={{
                                   403: t("domain.users.messages.errors.accessDenied"),
                                   404: t("domain.users.messages.errors.notFound"),
                               }}/>

                return <LoadingIndicator/>
            }

            return <Output>
                <UserLink user={requisitesSource}
                          showCompany/>
            </Output>
        }
    }

    // - Stopper

    function renderStopper(): ReactNode {
        const { stopperId } = fields

        if (stopperId == null)
            return null

        const stopper = innerUsersById.get(stopperId)

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.stopper")}/>
            </div>

            <div className={style.column2To4Value}>
                {renderValue()}
            </div>
        </>

        function renderValue(): ReactNode {
            if (stopper == null) {
                if (stopperLoadingError != null)
                    <ErrorText error={stopperLoadingError}
                               apiErrorMessageMapping={{
                                   403: t("domain.users.messages.errors.accessDenied"),
                                    404: t("domain.users.messages.errors.notFound"),
                               }}/>

                return <LoadingIndicator/>
            }

            return <Output>
                <UserLink user={stopper}
                          showCompany/>
            </Output>
        }
    }

    // - Direction

    function renderDirection(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.direction")}/>
            </div>

            <div className={style.direction}>
                <TransferDirectionRadio checked={fields.direction}
                                        exportHidden={fields.direction === "import"}
                                        importHidden={fields.direction === "export"}
                                        hideButton/>
            </div>
        </>
    }

    // - Moment

    function renderMoment(): ReactNode {
        const { moment } = fields

        if (moment == null)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.date")}/>
            </div>

            <div className={style.column2Value}>
                <Flex direction="horizontal"
                      width="fit-content">
                    <DateOutput date={moment}/>

                    <Label text={t("datetime.labels.time")}/>

                    <TimeOutput date={moment}/>
                </Flex>
            </div>
        </>
    }

    // - Recipient

    function renderRecipientSection(): ReactNode {
        return <>
            {renderRecipientSubheader()}
            {renderCountry()}
            {renderRecipient()}
            {renderMoney()}
            {renderRecipientRequisites()}
        </>
    }

    function renderRecipientSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.recipient")}/>
        </div>
    }

    function renderCountry(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.country")}/>
            </div>

            <div className={style.column2To4Value}>
                <Output>{fields.country}</Output>
            </div>
        </>
    }

    function renderRecipient(): ReactNode {
        return <>
            {/* Name */}

            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.recipient.name.full")}/>
            </div>

            <div className={style.column2Value}>
                <Output>{fields.recipient.name}</Output>
            </div>

            {/* ITN */}

            <div className={style.column3Label}>
                <Label text={t("domain.transfers.labels.recipient.itn")}/>
            </div>

            <div className={style.column4Value}>
                <Output>{fields.recipient.itn}</Output>
            </div>
        </>
    }

    function renderMoney(): ReactNode {
        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.money")}/>
            </div>

            <div className={style.column2To4Value}>
                <MoneyOutput value={fields.money}/>
            </div>
        </>
    }

    function renderRecipientRequisites(): ReactNode {
        return <>
            {/* Name */}

            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.recipient.bank.name")}/>
            </div>

            <div className={style.column2Value}>
                <Output>{fields.recipient.bank.name}</Output>
            </div>

            {/* Swift */}

            <div className={style.column3Label}>
                <Label text={t("domain.transfers.labels.recipient.bank.swift")}/>
            </div>

            <div className={style.column4Value}>
                <Output>{fields.recipient.bank.swift}</Output>
            </div>

            {/* Address */}

            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.recipient.bank.address")}/>
            </div>

            <div className={style.column2Value}>
                <Output>{fields.recipient.bank.address}</Output>
            </div>

            {/* IBAN */}

            <div className={style.column3Label}>
                <Label text={t("domain.transfers.labels.recipient.iban")}/>
            </div>

            <div className={style.column4Value}>
                <Output>{fields.recipient.iban}</Output>
            </div>

            {/* Correspondent account */}

            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.recipient.correspondentAccount.short")}/>
            </div>

            <div className={style.column2Value}>
                <Output>{fields.recipient.correspondentAccount}</Output>
            </div>

            {/* Payment account */}

            <div className={style.column3Label}>
                <Label text={t("domain.transfers.labels.recipient.paymentAccount.short")}/>
            </div>

            <div className={style.column4Value}>
                <Output>{fields.recipient.paymentAccount}</Output>
            </div>
        </>
    }

    // - Products

    function renderProductsSection(): ReactNode {
        return <>
            {renderProductsSubheader()}
            {renderProducts()}
        </>
    }

    function renderProductsSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.products")}/>
        </div>
    }

    function renderProducts(): ReactNode {
        return <div className={style.column1To4Value}>
            <ProductListEditor values={fields.products}
                               output/>
        </div>
    }

    // - Route

    function renderRouteSection(): ReactNode {
        return <>
            {renderRouteSubheader()}
            {renderRoute()}
        </>
    }

    function renderRouteSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.route")}/>
        </div>
    }

    function renderRoute(): ReactNode {
        const { routePoints } = fields

        if (routePoints.length === 0)
            return null

        return <div className={style.column1To4Value}>
            <RoutePointListEditor values={routePoints}
                                  output/>
        </div>
    }

    // - Currency rate

    function renderCurrencyRateSection() {
        if (fields.currencyRate == null)
            return

        return <>
            {renderCurrencyRateSubheader()}
            {renderCurrencyRate()}
        </>
    }

    function renderCurrencyRateSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.currencyRate")}/>
        </div>
    }

    function renderCurrencyRate(): ReactNode {
        const { currencyRate, money } = fields

        if (currencyRate == null)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.currencyRate")}/>
            </div>

            <div className={style.column2To4Value}>
                <Flex direction="horizontal"
                      width="fit-content">
                    <MoneyOutput value={currencyRate.money}
                                 precision={4}/>

                    <Icon src={arrowsLeftRightIconUrl}
                          alt="Left and right arrows"
                          filter="brightness(0) invert(50%)"/>

                    <MoneyOutput value={new Money({ currency: money.currency, amount: 1 })}
                                 precision={4}/>
                </Flex>
            </div>

            <div className={style.column1Label}>
                <Label text={t("domain.currencyRates.labels.date")}/>
            </div>

            <div className={style.column2Value}>
                <Flex direction="horizontal"
                      width="fit-content">
                    <DateOutput date={currencyRate.moment}/>

                    <Label text={t("datetime.labels.time")}/>

                    <TimeOutput date={currencyRate.moment}/>
                </Flex>
            </div>

            {currencyRate.evidence != null && <>
                <div className={style.column1Label}>
                    <Label text={t("domain.currencyRates.labels.evidence")}/>
                </div>

                <div className={style.column2To4Value}>
                    <DocumentUpload document={currencyRate.evidence}
                                    onChange={onCurrencyRateEvidenceChange}
                                    readonly/>
                </div>
            </>}

            {currencyRate.evidenceLink && <>
                <div className={style.column1Label}>
                    <Label text={t("domain.currencyRates.labels.evidenceLink")}/>
                </div>

                <div className={style.column2Value}>
                    <Link to={normalizeUrl(currencyRate.evidenceLink)}
                          text={currencyRate.evidenceLink}/>
                </div>
            </>}
        </>

        function onCurrencyRateEvidenceChange(newEvidence: UiDocument) {
            if (onChange == null || fields.currencyRate == null || !isFieldDocument(newEvidence))
                return

            const newFields = copyTransferFields(fields)

            assert(newFields.currencyRate != null)

            newFields.currencyRate.evidence = newEvidence

            onChange(newFields)
        }
    }

    // - Render shares and expenses section

    function renderSharesAndExpensesSection(): ReactNode {
        if (fields.attorneyFee == null && fields.agentPercent == null && fields.expenses == null)
            return null

        return <>
            {renderSharesAndExpensesSubheader()}
            {renderExpenses()}
            {renderAttorneyFee()}
            {renderAgentPercent()}
        </>
    }

    function renderSharesAndExpensesSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.sharesAndExpenses")}/>
        </div>
    }

    function renderExpenses(): ReactNode {
        const { expenses } = fields

        if (expenses == null)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.expenses")}/>
            </div>

            <div className={style.column2Value + " " + style.decimalOutput}>
                <MoneyOutput value={expenses}/>
            </div>
        </>
    }

    function renderAttorneyFee(): ReactNode {
        const { attorneyFee } = fields

        if (attorneyFee == null)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.attorneyFee")}/>
            </div>

            <div className={style.column2Value + " " + style.decimalOutput}>
                <DecimalOutput value={attorneyFee}
                               right="₽"/>
            </div>
        </>
    }

    function renderAgentPercent(): ReactNode {
        const { agentPercent } = fields

        if (agentPercent == null)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.agentPercent")}/>
            </div>

            <div className={style.column2Value + " " + style.decimalOutput}>
                <DecimalOutput value={agentPercent}
                               precision={4}
                               right="%"/>
            </div>
        </>
    }

    // - Providers

    function renderProvidersSection(): ReactNode {
        if (!fields.providers?.length)
            return null

        return <>
            {renderProvidersSubheader()}
            {renderProviders()}
        </>
    }

    function renderProvidersSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.providers")}/>
        </div>
    }

    function renderProviders(): ReactNode {
        const { providers } = fields

        if (providers == null)
            return

        return <div className={style.column1To4Value}>
            <TransferProviderListEditor values={providers}

                                        onUsersChange={onUsersChange}
                                        users={innerUsersById}

                                        onProvidersChange={onProvidersChange}
                                        providers={innerProvidersById}

                                        allowEmpty
                                        output/>
        </div>

        function onUsersChange(newUsers: User[]) {
            setInnerUsersById(oldUsersById => {
                const newUsersById = new Map(oldUsersById)

                for (const user of newUsers)
                    newUsersById.set(user.id, user)

                return newUsersById
            })
        }

        function onProvidersChange(newProviders: Provider[]) {
            setInnerProvidersById(oldProvidersById => {
                const newProvidersById = new Map(oldProvidersById)

                for (const provider of newProviders)
                    newProvidersById.set(provider.id, provider)

                return newProvidersById
            })
        }
    }

    // - Documents

    function renderDocumentsSection(): ReactNode {
        return <>
            {renderDocumentsSubheader()}
            {renderInvoice()}
            {renderContract()}
            {renderDocuments()}
        </>
    }

    function renderDocumentsSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.documents")}/>
        </div>
    }

    function renderInvoice(): ReactNode {
        return renderSpecialDocument("invoice")
    }

    function renderContract(): ReactNode {
        return renderSpecialDocument("contract")
    }

    function renderSpecialDocument(type: "invoice" | "contract"): ReactNode {
        return <>
            {/* Date and number */}

            <div className={style.column1Label}>
                <Label text={t(`domain.transfers.labels.${type}.number`)}/>
            </div>

            <div className={style.column2To4Value}>
                <Flex direction="horizontal"
                      width="fit-content">
                    <Output>{fields[type].number}</Output>

                    <Label text={t(`domain.transfers.labels.${type}.date`)}/>

                    <DateOutput date={fields[type].date}/>
                </Flex>
            </div>

            {/* Document */}

            {fields[type].document != null && <>
                <div className={style.column1Label}>
                    <Label text={t(`domain.transfers.labels.${type}.document`)}/>
                </div>

                <div className={style.column2To4Value}>
                    <DocumentUpload onChange={onDocumentChange}
                                    document={fields[type].document!}
                                    readonly/>
                </div>
            </>}
        </>

        function onDocumentChange(newDocument: UiDocument) {
            if (onChange == null || !isFieldDocument(newDocument))
                return

            const newFields = copyTransferFields(fields)

            newFields[type].document = newDocument

            onChange(newFields)
        }
    }

    function renderDocuments(): ReactNode {
        const { documents } = fields

        if (documents.length === 0)
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.documents")}/>
            </div>

            <div className={style.column2To4Value}>
                <DocumentListUpload onChange={onDocumentsChange}
                                    documents={documents}
                                    readonly/>
            </div>
        </>

        function onDocumentsChange(newDocuments: UiDocument[]) {
            if (onChange == null)
                return

            const newFields = copyTransferFields(fields)

            newFields.documents = newDocuments.filter(isFieldDocument)

            onChange(newFields)
        }
    }

    // - Orders

    function renderOrdersSection(): ReactNode {
        if (fields.orders.length === 0)
            return null

        return <>
            {renderOrdersSubheader()}
            {renderOrders()}
        </>
    }

    function renderOrdersSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.orders")}/>
        </div>
    }

    function renderOrders(): ReactNode {
        return <div className={style.column1To4Value}>
            <SignableDocumentListEditor onChange={onOrdersChange}
                                        values={fields.orders}

                                        width="fit-content"

                                        allowEmpty
                                        output/>
        </div>

        function onOrdersChange(newOrders: SignableDocumentFields[]) {
            if (onChange == null)
                return

            const newFields = copyTransferFields(fields)

            newFields.orders = newOrders

            onChange(newFields)
        }
    }

    // - Comments

    function renderCommentsSection(): ReactNode {
        if (isNullOrBlank(fields.comment) && isNullOrBlank(fields.adminComment))
            return null

        return <>
            {renderCommentsSubheader()}
            {renderComment()}
            {renderAdminComment()}
            {renderLawyerComment()}
            {renderAccountantComment()}
        </>
    }

    function renderCommentsSubheader(): ReactNode {
        return <div className={style.subheader}>
            <Subheader text={t("domain.transfers.subheaders.comments")}/>
        </div>
    }

    function renderComment(): ReactNode {
        const { comment } = fields

        if (isNullOrBlank(comment))
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.comment")}/>
            </div>

            <div className={style.column2To4Value}>
                <Output wordBreak="break-all"
                        resize="vertical">
                    {comment}
                </Output>
            </div>
        </>
    }

    function renderAdminComment(): ReactNode {
        const { adminComment } = fields

        if (isNullOrBlank(adminComment))
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.adminComment")}
                       wrap/>
            </div>

            <div className={style.column2To4Value}>
                <Output wordBreak="break-all"
                        resize="vertical">
                    {adminComment}
                </Output>
            </div>
        </>
    }

    function renderLawyerComment(): ReactNode {
        const { lawyerComment } = fields

        if (isNullOrBlank(lawyerComment))
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.lawyerComment")}
                       wrap/>
            </div>

            <div className={style.column2To4Value}>
                <Output wordBreak="break-all"
                        resize="vertical">
                    {lawyerComment}
                </Output>
            </div>
        </>
    }

    function renderAccountantComment(): ReactNode {
        const { accountantComment } = fields

        if (isNullOrBlank(accountantComment))
            return null

        return <>
            <div className={style.column1Label}>
                <Label text={t("domain.transfers.labels.accountantComment")}
                       wrap/>
            </div>

            <div className={style.column2To4Value}>
                <Output wordBreak="break-all"
                        resize="vertical">
                    {accountantComment}
                </Output>
            </div>
        </>
    }

    // - Controls

    function renderControls(): ReactNode {
        return <FormControls buttons={buttons}
                             disabled={disabled}
                             loading={loading}
                             error={error}/>
    }
})

TransferViewForm.displayName = "TransferViewForm"

export default TransferViewForm
