import ViolationType from "./ViolationType"

export const CORRESPONDENT_ACCOUNT_LENGTH = 20

export function validateCorrespondentAccount(account: string): ViolationType | null {
    if (account.length < CORRESPONDENT_ACCOUNT_LENGTH)
        return "too-short"

    if (account.length > CORRESPONDENT_ACCOUNT_LENGTH)
        return "too-long"

    if (account.match(/^\d*$/g) == null)
        return "bad-syntax"

    return null
}
