import { z } from "zod"
import { MoneyMessageSchema } from "api/message"
import { UuidSchema } from "my-util"
import { WithCreatorIdResponseSchema } from "./with-creator-id"

export const TransferAgentPaymentResponseSchema = z.object({
    transferId: UuidSchema.nullish(),

    agentId: UuidSchema.nullish(),
    agentPayedAt: z.coerce.date().nullish(),
    agentPayment: MoneyMessageSchema.nullish(),
}).merge(WithCreatorIdResponseSchema)

export type TransferAgentPaymentResponse = z.infer<typeof TransferAgentPaymentResponseSchema>
