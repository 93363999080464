import { ForwardedRef, forwardRef, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { patchTransferById } from "api"
import { Transfer } from "model"
import { collapseSpacesToNull, DeepReadonly, isBlank } from "my-util"
import { MAX_MEDIUM_TEXT_LENGTH } from "validation"
import { UserContext } from "ui/context"
import { ActionModal, TextArea } from "ui/ui"

export interface TransferStopModalProps {
    onStopped?: (transfer: Transfer) => void
    onClose?: () => void
    closeOnSuccess?: boolean

    transferId: string

    width?: string
}

const TransferStopModal = forwardRef((
    {
        onStopped, onClose, closeOnSuccess,
        transferId,
        width,
    }: DeepReadonly<TransferStopModalProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    // State

    const [comment, setComment] = useState("")
    const [loading, setLoading] = useState(false)

    // Render

    return <ActionModal onYes={onInnerStop}
                        onNo={onClose}
                        closeOnSuccess={closeOnSuccess}

                        header={t("domain.transfers.headers.stopping")}
                        yes={t("domain.transfers.buttons.stop")}
                        no={t("misc.buttons.cancel")}

                        yesDisabled={isBlank(comment)}
                        critical

                        width={width}

                        ref={ref}>
        <TextArea onChange={setComment}
                  value={comment}

                  label={t("domain.transfers.labels.comment")}
                  placeholder={t("domain.transfers.placeholders.comment")}

                  loading={loading}
                  required

                  max={MAX_MEDIUM_TEXT_LENGTH}/>
    </ActionModal>

    // Events

    async function onInnerStop() {
        if (!localUser?.hasSpecialization)
            return

        setLoading(true)

        try {
            let commentPath = "/adminComment"

            if (!localUser.isAdmin)
                if (localUser.isLawyer)
                    commentPath = "/lawyerComment"
                else if (localUser.isAccountant)
                    commentPath = "/accountantComment"

            const transfer = await patchTransferById(transferId, [
                {
                    op: "replace",
                    path: commentPath,
                    value: collapseSpacesToNull(comment),
                },

                {
                    op: "replace",
                    path: "/isStopped",
                    value: true,
                },
            ])

            onStopped?.(transfer)
        } finally {
            setLoading(false)
        }
    }
})

TransferStopModal.displayName = "TransferStopModal"

export default TransferStopModal
