import { ReactNode, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { plusIconUrl } from "image"
import { getAllUsersByIds, getAllTransfersByStatusNot, getAllMyTransfersByStatusNot } from "api"

import { getTransfersByGroupSortingOrderComparator,
         Transfer, TransferDirection, TransferGroup, User, DONE_TRANSFER_STATUS,
         IMPORT_TRANSFER_DIRECTION, NEW_TRANSFER_GROUP, EXPORT_TRANSFER_DIRECTION } from "model"

import { DATE_COMPARATOR, DateRange, findMin, findMax,
         uniqueArray, createOrCopyDate, ReadonlyDate } from "my-util"

import { useTransfers, useUsers } from "ui/hook"
import { UserContext } from "ui/context"
import { SessionExpiredErrorPage } from "ui/page/error"
import { DownloadTransfersReportLink, Page, TransferTable } from "ui/component"

import { Button, Center, Padding, Loading, Flex,
         TransferDirectionRadio, TransferGroupColorLegendList,
         TransferPhaseIndicator, ErrorDisplay, DateRangeInput } from "ui/ui"

import { createTransferPagePath } from "../TransferPage/path"
import { TRANSFER_CREATION_PAGE_PATH } from "../TransferCreationPage/path"
import style from "./style.module.css"

export default function AllTransfersPage() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const storedUsers = useUsers()
    const storedTransfers = useTransfers()

    const navigate = useNavigate()

    // State

    // - Transfers

    const [transfers, setTransfers] = useState(new Array<Transfer>())
    const [loadingTransfers, setLoadingTransfers] = useState(localUser != null)

    // -- Direction filtering

    const importTransfers = useMemo(
        () => transfers.filter(({ direction }) => direction === IMPORT_TRANSFER_DIRECTION),
        [transfers],
    )

    const exportTransfers = useMemo(
        () => transfers.filter(({ direction }) => direction === EXPORT_TRANSFER_DIRECTION),
        [transfers],
    )

    const [selectedTransfer, setSelectedTransfer] = useState(undefined as Transfer | undefined)
    const [transferDirection, setTransferDirection] = useState<TransferDirection>(IMPORT_TRANSFER_DIRECTION)

    const directionTransfers = useMemo(() => {
        switch (transferDirection) {
            case "import":
                return importTransfers

            case "export":
                return exportTransfers
        }
    }, [importTransfers, exportTransfers, transferDirection])

    // -- Moment filtering

    const [dateRange, setDateRange] = useState(undefined as DateRange | undefined)

    const shownTransfers = useMemo(() => directionTransfers.filter(({ moment }) => {
        if (moment == null || dateRange == null)
            return true

        if (moment < dateRange.from)
            return false

        if (moment > dateRange.to)
            return false

        return true
    }), [directionTransfers, dateRange])

    const shownTransferIds = useMemo(() => shownTransfers.map(({ id }) => id), [shownTransfers])

    // -- Group sorting

    const [sortGroup, setSortGroup] = useState<TransferGroup>(NEW_TRANSFER_GROUP)

    const comparator = useMemo(
        () => getTransfersByGroupSortingOrderComparator(sortGroup),
        [sortGroup],
    )

    // - Users

    const [creators, setCreators] = useState(new Array<User>())
    const [loadingCreators, setLoadingCreators] = useState(false)

    const [agents, setAgents] = useState(new Array<User>())
    const [loadingAgents, setLoadingAgents] = useState(false)

    const usersById = useMemo(
        () => User.groupById([...creators, ...agents]),
        [creators, agents],
    )

    // - Loading

    const loading =
        loadingTransfers ||
        loadingCreators ||
        loadingAgents

    const [error, setError] = useState(undefined as any)

    // Refs

    const tableRef = useRef(null as HTMLTableElement | null)

    // Effects

    // - Click handling

    useEffect(() => {
        document.addEventListener("click", handleClick)

        return () => document.removeEventListener("click", handleClick)

        function handleClick(event: MouseEvent) {
            const table = tableRef.current

            if (table == null)
                return

            const { target } = event

            if (target instanceof Node && !table!.contains(target))
                setSelectedTransfer(undefined)
        }
    }, [])

    // - Transfers loading

    useEffect(() => {
        if (!loadingTransfers)
            return

        const controller = new AbortController()

        const promise = localUser?.isManager
            ? getAllTransfersByStatusNot(DONE_TRANSFER_STATUS, controller.signal)
            : getAllMyTransfersByStatusNot(DONE_TRANSFER_STATUS, controller.signal)

        promise
            .then(transfers => {
                storedTransfers.addAll(transfers)

                setTransfers(transfers)
                setLoadingCreators(localUser?.isManager ?? false)

                const moments = transfers
                    .map(({ moment }) => moment)
                    .filter(moment => moment != null) as ReadonlyDate[]

                setDateRange({
                    from: createOrCopyDate(findMin(moments, DATE_COMPARATOR)),
                    to: createOrCopyDate(findMax(moments, DATE_COMPARATOR)),
                })
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingTransfers(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingTransfers])

    // - Creators loading

    useEffect(() => {
        if (!loadingCreators)
            return

        const creatorIds = uniqueArray(
            transfers
                .map(({ creatorId }) => creatorId)
                .filter(id => id != null) as string[]
        )

        if (creatorIds.length === 0) {
            setLoadingCreators(false)
            return
        }

        const controller = new AbortController()

        getAllUsersByIds({ ids: creatorIds }, controller.signal)
            .then(creators => {
                storedUsers.addAll(creators)
                setCreators(creators)
                setLoadingAgents(true)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingCreators(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCreators])

    // - Agents loading

    useEffect(() => {
        if (!loadingAgents)
            return

        const agentIds = uniqueArray(
            creators
                .map(({ creatorId }) => creatorId)
                .filter(id => id != null) as string[]
        )

        if (agentIds.length === 0) {
            setLoadingAgents(false)
            return
        }

        const controller = new AbortController()

        getAllUsersByIds({ ids: agentIds }, controller.signal)
            .then(agents => {
                storedUsers.addAll(agents)
                setAgents(agents)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingAgents(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAgents])

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    return <Page compactTitle={renderCompactTitle}
                 title={renderTitle()}
                 autoMoveCompactTitle
                 type="main">
        {renderContent()}
    </Page>

    function renderCompactTitle(compact?: boolean): ReactNode {
        const content = renderContent()

        return compact
            ? content

            : <Padding paddingLeft="8px">
                {content}
            </Padding>

        function renderContent(): ReactNode {
            return <Flex direction="horizontal"
                         gap="8px">
                <Button onClick={() => navigate(TRANSFER_CREATION_PAGE_PATH)}
                        iconSrc={plusIconUrl}
                        iconAlt="Plus icon"
                        width="32px"/>

                <div style={{ height: "1em" }}>
                    {(
                        compact
                            ? t("sections.transfers.header.short")
                            : t("sections.transfers.header.full")
                    ).toLocaleUpperCase()}
                </div>
            </Flex>
        }
    }

    function renderTitle(): ReactNode {
        if (loading || transfers.length === 0)
            return null

        return <Padding paddingLeft="14px">
            <Flex justify="space-between"
                  direction="horizontal">
                <Flex direction="horizontal"
                      width="fit-content"
                      gap="40px">
                    <TransferDirectionRadio onChange={setTransferDirection}
                                            checked={transferDirection}
                                            width="fit-content"/>

                    <DateRangeInput onChange={setDateRange}
                                    value={dateRange}/>
                </Flex>

                {localUser?.isManager &&
                    <DownloadTransfersReportLink ids={shownTransferIds}
                                                 whiteSpace="nowrap"
                                                 fontSize="16px"/>
                }
            </Flex>
        </Padding>
    }

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (error != null)
            return <ErrorDisplay error={error}/>

        if (transfers.length === 0)
            return <Center>
                <div style={{ whiteSpace: "pre" }}>
                    {localUser?.isManager
                        ? t("sections.transfers.messages.no")
                        : t("sections.transfers.messages.noYours")
                    }
                </div>
            </Center>

        return <div className={style.layout}>
            {renderLegend()}
            {renderTable()}
            {renderSelectionIndicator()}
            {renderStatus()}
        </div>
    }

    function renderLegend(): ReactNode {
        return <div className={style.legend}>
            <TransferGroupColorLegendList onClick={setSortGroup}
                                          width="100%"
                                          showLegend
                                          gap="8px"
                                          hideDone/>
        </div>
    }

    function renderTable(): ReactNode {
        return <div className={style.table}>
            <TransferTable onTransferClick={onTransferClick}
                           transfers={shownTransfers}
                           comparator={comparator}

                           users={usersById}

                           showAgents={localUser?.isManager}
                           showGroups

                           createFirstRowId={transferToFirstTableRowId}

                           ref={tableRef}/>
        </div>
    }

    function renderSelectionIndicator(): ReactNode {
        if (selectedTransfer == null)
            return null

        const table = tableRef.current

        if (table == null)
            return null

        const firstRowId = transferToFirstTableRowId(selectedTransfer)
        const firstRow = document.getElementById(firstRowId)

        if (firstRow == null)
            return null

        const top = firstRow.getBoundingClientRect().top - table.getBoundingClientRect().top

        return <div className={style.selectionIndicatorWrapper}>
            <div className={style.selectionIndicator}
                 style={{ top }}/>
        </div>
    }

    function renderStatus(): ReactNode {
        if (selectedTransfer == null)
            return null

        return <div className={style.status}>
            <TransferPhaseIndicator status={selectedTransfer.status}
                                    indicatorStyle="mini"
                                    size="small"
                                    sepLength="20px"
                                    direction="vertical"/>
        </div>
    }

    // Events

    function onTransferClick(transfer: Transfer) {
        if (selectedTransfer?.id === transfer.id) {
            navigate(createTransferPagePath(transfer.id))
            return
        }

        setSelectedTransfer(transfer)
    }

    // Util

    function transferToFirstTableRowId(transfer: Transfer): string {
        return transfer.id
    }
}
