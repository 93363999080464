import { ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { getAllUsersByRole } from "api"

import { ACTIVE_USER_STATUS, ADMIN_USER_ROLE, MANAGER_USER_ROLE,
         getUsersByStatusSortingOrderComparator, User, UserStatus } from "model"

import { useUsers } from "ui/hook"
import { UserContext } from "ui/context"
import { Error403Page, SessionExpiredErrorPage } from "ui/page/error"
import { Page, UserTable } from "ui/component"

import { Center, ErrorDisplay, FlexItem, Flex, Padding,
         Loading, ErrorText, UserStatusColorLegendList } from "ui/ui"

import style from "./style.module.css"

export default function AllAdminsPage() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const storedUsers = useUsers()

    // State

    const [admins, setAdmins] = useState(new Array<User>())
    const [loadingAdmins, setLoadingAdmins] = useState(localUser?.isManager ?? false)

    const [managers, setManagers] = useState(new Array<User>())
    const [loadingManagers, setLoadingManagers] = useState(localUser?.isManager ?? false)

    const onlyManagersPresent = admins.length === 0 && managers.length > 0

    const loading = loadingAdmins || loadingManagers
    const [error, setError] = useState(undefined as unknown)
    const [managersUpdateError, setManagersUpdateError] = useState(undefined as unknown)

    const [adminsSortStatus, setAdminsSortStatus] = useState<UserStatus>(ACTIVE_USER_STATUS)

    const adminsComparator = useMemo(
        () => getUsersByStatusSortingOrderComparator(adminsSortStatus),
        [adminsSortStatus],
    )

    const [managersSortStatus, setManagersSortStatus] = useState<UserStatus>(ACTIVE_USER_STATUS)

    const managersComparator = useMemo(
        () => getUsersByStatusSortingOrderComparator(managersSortStatus),
        [managersSortStatus],
    )

    // Effects

    // - Admins loading

    useEffect(() => {
        if (!loadingAdmins)
            return

        const controller = new AbortController()

        getAllUsersByRole(ADMIN_USER_ROLE, controller.signal)
            .then(admins => {
                setAdmins(admins)
                storedUsers.addAll(admins)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingAdmins(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAdmins])

    // - Managers loading

    useEffect(() => {
        if (!loadingManagers)
            return

        const controller = new AbortController()

        getAllUsersByRole(MANAGER_USER_ROLE, controller.signal)
            .then(managers => {
                setManagers(managers)
                storedUsers.addAll(managers)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingManagers(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingManagers])

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    if (!localUser.isManager)
        return <Error403Page/>

    return <Page title={renderTitle()}
                 type="main">
        {renderContent()}
    </Page>

    function renderTitle(): ReactNode {
        return (
            onlyManagersPresent
                ? t("sections.admins.headers.managers")
                : t("sections.admins.headers.admins")
        ).toUpperCase()
    }

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (error)
            return <ErrorDisplay error={error}/>

        if (admins.length === 0 && managers.length === 0)
            return <Center>
                {t("sections.admins.messages.no")}
            </Center>

        return <Flex align="start"
                     height="100%">
            {admins.length > 0 &&
                renderAdmins()
            }

            {managers.length > 0 &&
                renderManagers()
            }
        </Flex>
    }

    function renderAdmins(): ReactNode {
        return <FlexItem grow={1}>
            <Flex width="fit-content"
                  align="start"
                  gap="8px">
                <Padding paddingLeft="16px">
                    <UserStatusColorLegendList onClick={setAdminsSortStatus}
                                               fontSize="12px"
                                               showLegend
                                               gap="8px"/>
                </Padding>

                <UserTable comparator={adminsComparator}
                        users={admins}
                        showStatuses/>
            </Flex>
        </FlexItem>
    }

    function renderManagers(): ReactNode {
        return <FlexItem grow={1}>
            <Flex justify="space-between"
                  align="start"
                  height="100%">
                {!onlyManagersPresent &&
                    <div className={style.title}>
                        {t("sections.admins.headers.managers").toUpperCase()}
                    </div>
                }

                <Flex width="fit-content"
                      align="start"
                      gap="8px">
                    <Padding paddingLeft="16px">
                        <UserStatusColorLegendList onClick={setManagersSortStatus}
                                                   fontSize="12px"
                                                   showLegend
                                                   gap="8px"/>
                    </Padding>

                    <UserTable onUsersChange={onManagersChange}
                               users={managers}
                               comparator={managersComparator}

                               onError={setManagersUpdateError}

                               readonly={!localUser?.isAdmin}
                               showRights
                               showStatuses/>
                </Flex>

                <ErrorText error={managersUpdateError}/>
            </Flex>
        </FlexItem>
    }

    // Events

    function onManagersChange(newManagers: User[]) {
        setManagers(newManagers)
        setManagersUpdateError(false)
    }
}
