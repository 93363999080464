import { getLang } from "i18n"
import { WithCompanyNameFields, WithNullableCompanyName } from "model/interfaces"
import { Constructor, Nullable } from "my-util"

export default function NullableCompanyNameMixin<
    BaseConstructor extends Constructor<Nullable<WithCompanyNameFields>>
>(Base: BaseConstructor) {
    return class extends Base implements WithNullableCompanyName {
        get anyCompany(): string | null {
            return getLang() === "ru"
                ? this.ruCompany ?? this.enCompany
                : this.enCompany ?? this.ruCompany
        }

        get company(): string | null {
            return getLang() === "ru"
                ? this.ruCompany
                : this.enCompany
        }
    }
}
