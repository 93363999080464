import { ForwardedRef, forwardRef } from "react"

import { TransferGroup,
         IN_WORK_TRANSFER_GROUP, NEW_TRANSFER_GROUP,
         TODAY_TRANSFER_GROUP, STOPPED_TRANSFER_GROUP,
         DECLINED_TRANSFER_GROUP, DONE_TRANSFER_GROUP } from "model"

import { Flex, FlexProps } from "ui/ui/layout"
import TransferGroupColorLegend from "../TransferGroupColorLegend"

export interface TransferGroupColorLegendListProps extends Omit<FlexProps, "children"> {
    onClick?: (group: TransferGroup) => void

    hideNew?: boolean
    hideInWork?: boolean
    hideToday?: boolean
    hideDone?: boolean
    hideDeclined?: boolean
    hideStopped?: boolean

    showLegend?: boolean
}

const TransferGroupColorLegendList = forwardRef((
    props: Readonly<TransferGroupColorLegendListProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const {
        onClick,
        hideNew, hideInWork, hideToday, hideDone, hideDeclined, hideStopped,
        showLegend,
    } = props

    return <Flex direction="horizontal"
                 width="fit-content"
                 { ...props }
                 ref={ref}>
        {!hideNew &&
            <TransferGroupColorLegend group={NEW_TRANSFER_GROUP}
                                      showLegend={showLegend}
                                      onClick={onClick}/>
        }

        {!hideInWork &&
            <TransferGroupColorLegend group={IN_WORK_TRANSFER_GROUP}
                                      showLegend={showLegend}
                                      onClick={onClick}/>
        }

        {!hideToday &&
            <TransferGroupColorLegend group={TODAY_TRANSFER_GROUP}
                                      showLegend={showLegend}
                                      onClick={onClick}/>
        }

        {!hideDone &&
            <TransferGroupColorLegend group={DONE_TRANSFER_GROUP}
                                      showLegend={showLegend}
                                      onClick={onClick}/>
        }

        {!hideDeclined &&
            <TransferGroupColorLegend group={DECLINED_TRANSFER_GROUP}
                                      showLegend={showLegend}
                                      onClick={onClick}/>
        }

        {!hideStopped &&
            <TransferGroupColorLegend group={STOPPED_TRANSFER_GROUP}
                                      showLegend={showLegend}
                                      onClick={onClick}/>
        }
    </Flex>
})

TransferGroupColorLegendList.displayName = "TransferGroupColorLegendList"

export default TransferGroupColorLegendList
