import { getLang } from "i18n"
import Copyable from "model/Copyable"

import { collapseWhiteSpaceToNull, DeepReadonly,
         Immutable, IsImmutable, removeWhiteSpaceToNull } from "my-util"

import Bank, { BankCopyOptions, BankCreationOptions } from "./Bank"

export interface CompanyCreationOptions {
    itn?: string | null

    ruName?: string | null
    enName?: string | null

    ruAddress?: string | null
    enAddress?: string | null

    bank?: Bank | BankCreationOptions | null

    correspondentAccount?: string | null
    paymentAccount?: string | null
}

export interface CompanyCopyOptions extends Omit<CompanyCreationOptions, "bank"> {
    bank?: Bank | BankCopyOptions | null
}

export default class Company
    extends
        Copyable<CompanyCopyOptions>

    implements
        Immutable
{
    static createOrPass(arg?: Company | DeepReadonly<CompanyCreationOptions> | null): Company {
        return arg instanceof Company
            ? arg
            : new Company(arg ?? {})
    }

    // Fields

    readonly [IsImmutable] = true

    readonly itn: string | null

    readonly ruName: string | null
    readonly enName: string | null

    readonly ruAddress: string | null
    readonly enAddress: string | null

    readonly bank: Bank

    readonly correspondentAccount: string | null
    readonly paymentAccount: string | null

    // Constructor

    constructor(options: DeepReadonly<CompanyCreationOptions> = {}) {
        super()

        this.itn = removeWhiteSpaceToNull(options.itn ?? "")

        this.ruName = collapseWhiteSpaceToNull(options.ruName ?? "")
        this.enName = collapseWhiteSpaceToNull(options.enName ?? "")

        this.ruAddress = collapseWhiteSpaceToNull(options.ruAddress ?? "")
        this.enAddress = collapseWhiteSpaceToNull(options.enAddress ?? "")

        this.bank = Bank.createOrPass(options.bank)

        this.correspondentAccount = removeWhiteSpaceToNull(options.correspondentAccount ?? "")
            ?.toUpperCase() ?? null

        this.paymentAccount = removeWhiteSpaceToNull(options.paymentAccount ?? "")
    }

    // Name

    get anyName(): string | null {
        return getLang() === "ru"
            ? this.ruName ?? this.enName
            : this.enName ?? this.ruName
    }

    get name(): string | null {
        return getLang() === "ru"
            ? this.ruName
            : this.enName
    }

    // Address

    get anyAddress(): string | null {
        return getLang() === "ru"
            ? this.ruAddress ?? this.enAddress
            : this.enAddress ?? this.ruAddress
    }

    get address(): string | null {
        return getLang() === "ru"
            ? this.ruAddress
            : this.enAddress
    }

    // Copy

    protected override createCopy(options: DeepReadonly<CompanyCopyOptions> = {}): Company {
        return new Company({
            // ITN

            itn: "itn" in options
                ? options.itn
                : this.itn,

            // Name

            ruName: "ruName" in options
                ? options.ruName
                : this.ruName,

            enName: "enName" in options
                ? options.enName
                : this.enName,

            // Address

            ruAddress: "ruAddress" in options
                ? options.ruAddress
                : this.ruAddress,

            enAddress: "enAddress" in options
                ? options.enAddress
                : this.enAddress,

            // Bank

            bank: this.bank.copyOrPass(options.bank),

            // Accounts

            correspondentAccount: "correspondentAccount" in options
                ? options.correspondentAccount
                : this.correspondentAccount,

            paymentAccount: "paymentAccount" in options
                ? options.paymentAccount
                : this.paymentAccount,
        })
    }
}
