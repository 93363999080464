import { DeepReadonly, Nullish, tryNormalizeNullableUuid } from "my-util"

import AbstractNotification,
       { AbstractNotificationOptionsBase,
         AbstractNotificationCopyOptions,
         AbstractNotificationCreationOptions } from "./AbstractNotification"

import NotificationType,
       { NewInviteNotificationType,
         NEW_INVITE_NOTIFICATION_TYPE } from "./NotificationType"

export interface NewInviteNotificationOptionsBase extends AbstractNotificationOptionsBase {
    inviteId?: string | null
}

export interface NewInviteNotificationCreationOptions
    extends
        Omit<AbstractNotificationCreationOptions<NotificationType>, "type">,
        NewInviteNotificationOptionsBase
{}

export interface NewInviteNotificationCopyOptions
    extends
        Omit<AbstractNotificationCopyOptions, "type">,
        Nullish<NewInviteNotificationOptionsBase>
{}

export default class NewInviteNotification extends AbstractNotification<
    NewInviteNotificationType,
    NewInviteNotificationCopyOptions
> {
    static createOrPass(
        arg?: NewInviteNotification
            | DeepReadonly<NewInviteNotificationCreationOptions>
            | null
    ): NewInviteNotification {
        return arg instanceof NewInviteNotification
            ? arg
            : new NewInviteNotification(arg ?? {})
    }

    // Fields

    readonly inviteId: string | null

    // Constructor

    constructor(options: DeepReadonly<NewInviteNotificationCreationOptions> = {}) {
        super({ ...options, type: NEW_INVITE_NOTIFICATION_TYPE })

        this.inviteId = tryNormalizeNullableUuid(options.inviteId)
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<NewInviteNotificationCopyOptions> = {},
    ): NewInviteNotification {
        return new NewInviteNotification({
            // Recipient ID

            recipientId: "recipientId" in options
                ? options.recipientId
                : this.recipientId,

            // Content

            inviteId: "inviteId" in options
                ? options.inviteId
                : this.inviteId,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
