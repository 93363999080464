import { useTranslation } from "react-i18next"
import { ForwardedRef, forwardRef, useEffect, useRef } from "react"
import { arrayOfNonNull, DeepReadonly } from "my-util"
import { copySignableDocumentFields, SignableDocumentFields } from "ui/fields"
import { useStateWithDeps } from "ui/hook"
import { CheckBox, Flex, Group, Label } from "ui/ui"
import UiDocument, { copyUiDocument, isFieldDocument } from "../../UiDocument"
import DocumentListUpload from "../../DocumentListUpload"
import style from "./style.module.css"

export interface SignableDocumentEditorProps {
    onChange?: (value: SignableDocumentFields) => void
    value?: SignableDocumentFields

    noDocumentDelete?: boolean

    loading?: boolean
    disabled?: boolean
    output?: boolean
    readonly?: boolean
    readonlyInitial?: boolean
    readonlySigned?: boolean
    readonlyLocked?: boolean

    width?: string
}

const SignableDocumentEditor = forwardRef((
    {
        onChange, value,
        noDocumentDelete,
        loading, disabled, output,
        readonly, readonlyInitial, readonlyLocked, readonlySigned,
        width,
    }: DeepReadonly<SignableDocumentEditorProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    // Refs

    const changedRef = useRef(false)

    // State

    const [innerValue, setInnerValue] = useStateWithDeps<SignableDocumentFields>(
        oldValue => {
            if (changedRef.current && oldValue)
                return oldValue

            if (value == null)
                return { initial: null, signed: null, locked: false }

            return copySignableDocumentFields(value)
        },

        [value],
    )

    const [initial, setInitial] = useStateWithDeps<UiDocument[]>(
        () => arrayOfNonNull(value?.initial).map(copyUiDocument),
        [value?.initial],
    )

    const [signed, setSigned] = useStateWithDeps<UiDocument[]>(
        () => arrayOfNonNull(value?.signed).map(copyUiDocument),
        [value?.signed],
    )

    const [locked, setLocked] = useStateWithDeps(
        () => value?.locked ?? false,
        [value?.locked],
    )

    // Effects

    useEffect(() => {
        if (changedRef.current) {
            onChange?.(copySignableDocumentFields(innerValue))
            changedRef.current = false
        }
    }, [innerValue, onChange])

    // Render

    const immutable = output || readonly

    return <Group width={width}
                  ref={ref}>
        <div className={style.layout}>
            {/* Initial */}

            {(!output || initial.length > 0) && <>
                <div className={style.label}>
                    <Label text={t("domain.signableDocument.labels.initial")}/>
                </div>

                <div className={style.value}>
                    <DocumentListUpload onChange={onInitialChange}
                                        documents={initial}

                                        noDelete={noDocumentDelete}

                                        disabled={disabled}
                                        readonly={readonlyInitial || immutable}/>
                </div>
            </>}

            {/* Signed */}

            {(!output || signed.length > 0) && <>
                <div className={style.label}>
                    <Label text={t("domain.signableDocument.labels.signed")}/>
                </div>

                <div className={style.value}>
                    <DocumentListUpload onChange={onSignedChange}
                                        documents={signed}

                                        noDelete={noDocumentDelete}

                                        disabled={disabled}
                                        readonly={readonlySigned || immutable}/>
                </div>
            </>}

            {/* Locked */}

            <div className={style.row}>
                <Flex direction="horizontal">
                    <Label text={t("domain.signableDocument.labels.locked")}/>

                    <CheckBox onChange={onLockedChange}
                              checked={locked}

                              loading={loading}
                              disabled={disabled}
                              readonly={readonlyLocked || immutable}/>
                </Flex>
            </div>
        </div>
    </Group>

    // Events

    function onInitialChange(newInitial: UiDocument[]) {
        const [first] = newInitial

        if (first == null || isFieldDocument(first)) {
            setInnerValue(oldValue => ({ ...oldValue, initial: first }))
            changedRef.current = true
        }

        setInitial(newInitial)
    }

    function onSignedChange(newSigned: UiDocument[]) {
        const [first] = newSigned

        if (first == null || isFieldDocument(first)) {
            setInnerValue(oldValue => ({ ...oldValue, signed: first }))
            changedRef.current = true
        }

        setSigned(newSigned)
    }

    function onLockedChange(newLocked: boolean) {
        setInnerValue(oldValue => ({ ...oldValue, locked: newLocked }))
        changedRef.current = true

        setLocked(newLocked)
    }
})

SignableDocumentEditor.displayName = "SignableDocumentEditor"

export default SignableDocumentEditor
