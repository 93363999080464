import AbstractModelObject,
      { AbstractModelObjectCopyOptions,
        AbstractModelObjectCreationOptions } from "model/AbstractModelObject"

import { WithManyMessageTargets, MandatoryNamedFields,
         WithCompanyNameFields, NamedFields, WithCreatorId } from "model/interfaces"

import { NullableCompanyNameMixin, NameMixin, mixin } from "model/mixins"

import { capitalized, collapseWhiteSpace, Nullable, Nullish, DeepReadonly,
         collapseWhiteSpaceToNull, removeWhiteSpaceToNull, tryNormalizeNullableUuid } from "my-util"

export interface ProviderCreationOptions
    extends
        AbstractModelObjectCreationOptions,
        Nullish<WithCompanyNameFields>,
        Nullish<WithManyMessageTargets>,
        Nullish<WithCreatorId>,
        MandatoryNamedFields
{}


export interface ProviderCopyOptions
    extends
        AbstractModelObjectCopyOptions,
        Nullish<WithCompanyNameFields>,
        Nullish<WithManyMessageTargets>,
        Nullish<WithCreatorId>,
        Nullish<NamedFields>
{}

class Provider
    extends
        AbstractModelObject<ProviderCopyOptions>

    implements
        Readonly<Nullable<WithCompanyNameFields>>,
        Readonly<Nullable<WithCreatorId>>,
        Readonly<WithManyMessageTargets>,
        Readonly<NamedFields>
{
    // Fields

    // - Creator ID

    readonly creatorId: string | null

    // - Message targets

    readonly phone: string | null
    readonly email: string | null

    // - Name

    // -- En

    readonly enFirstname: string
    readonly enLastname: string
    readonly enPatronymic: string | null

    // -- Ru

    readonly ruFirstname: string
    readonly ruLastname: string
    readonly ruPatronymic: string | null

    // - Company

    readonly enCompany: string | null
    readonly ruCompany: string | null

    // Constructor

    constructor(options: DeepReadonly<ProviderCreationOptions>) {
        super(options)

        // Creator ID

        this.creatorId = tryNormalizeNullableUuid(options.creatorId)

        // Message targets

        this.phone = removeWhiteSpaceToNull(options.phone ?? "")
        this.email = removeWhiteSpaceToNull(options.email ?? "")?.toLowerCase() ?? null

        // Name

        // - En

        this.enFirstname = collapseWhiteSpace(capitalized(options.enFirstname))
        this.enLastname = collapseWhiteSpace(capitalized(options.enLastname))
        this.enPatronymic = collapseWhiteSpaceToNull(capitalized(options.enPatronymic ?? ""))

        // - Ru

        this.ruFirstname = collapseWhiteSpace(capitalized(options.ruFirstname))
        this.ruLastname = collapseWhiteSpace(capitalized(options.ruLastname))
        this.ruPatronymic = collapseWhiteSpaceToNull(capitalized(options.ruPatronymic ?? ""))

        // Company

        this.enCompany = collapseWhiteSpaceToNull(options.enCompany ?? "")
        this.ruCompany = collapseWhiteSpaceToNull(options.ruCompany ?? "")
    }

    // Copy

    protected override createCopy(options: DeepReadonly<ProviderCopyOptions> = {}): Provider {
        return new Provider({
            // Creator ID

            creatorId: "creatorId" in options
                ? options.creatorId
                : this.creatorId,

            // Message targets

            phone: "phone" in options
                ? options.phone
                : this.phone,

            email: "email" in options
                ? options.email
                : this.email,

            // Company

            enCompany: "enCompany" in options
                ? options.enCompany
                : this.enCompany,

            ruCompany: "ruCompany" in options
                ? options.ruCompany
                : this.ruCompany,

            // Name

            // - En

            enFirstname: options.enFirstname ?? this.enFirstname,
            enLastname: options.enLastname ?? this.enLastname,

            enPatronymic: "enPatronymic" in options
                ? options.enPatronymic
                : this.enPatronymic,

            // - Ru

            ruFirstname: options.ruFirstname ?? this.ruFirstname,
            ruLastname: options.ruLastname ?? this.ruLastname,

            ruPatronymic: "ruPatronymic" in options
                ? options.ruPatronymic
                : this.ruPatronymic,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}

export default class ProviderWithMixins extends mixin(Provider, [
    NullableCompanyNameMixin,
    NameMixin,
]) {
    // Create

    static createOrPass(
        arg: ProviderWithMixins | DeepReadonly<ProviderCreationOptions>,
    ): ProviderWithMixins {
        return arg instanceof ProviderWithMixins
            ? arg
            : new ProviderWithMixins(arg)
    }
}
