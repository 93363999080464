import { arrayOfNonNull, DeepReadonly, Nullish, tryNormalizeNullableUuid } from "my-util"

import AbstractNotification,
       { AbstractNotificationOptionsBase,
         AbstractNotificationCopyOptions,
         AbstractNotificationCreationOptions } from "./AbstractNotification"

import NotificationType,
       { NewUserNotificationType,
         NEW_USER_NOTIFICATION_TYPE } from "./NotificationType"

export interface NewUserNotificationOptionsBase extends AbstractNotificationOptionsBase {
    userId?: string | null
}

export interface NewUserNotificationCreationOptions
    extends
        Omit<AbstractNotificationCreationOptions<NotificationType>, "type">,
        NewUserNotificationOptionsBase
{}

export interface NewUserNotificationCopyOptions
    extends
        Omit<AbstractNotificationCopyOptions, "type">,
        Nullish<NewUserNotificationOptionsBase>
{}

export default class NewUserNotification extends AbstractNotification<
    NewUserNotificationType,
    NewUserNotificationCopyOptions
> {
    static createOrPass(
        arg?: NewUserNotification
            | DeepReadonly<NewUserNotificationCreationOptions>
            | null,
    ): NewUserNotification {
        return arg instanceof NewUserNotification
            ? arg
            : new NewUserNotification(arg ?? {})
    }

    // Fields

    readonly userId: string | null

    // Constructor

    constructor(options: DeepReadonly<NewUserNotificationCreationOptions> = {}) {
        super({ ...options, type: NEW_USER_NOTIFICATION_TYPE })

        this.userId = tryNormalizeNullableUuid(options.userId)
    }

    // User IDs

    override get userIds(): string[] {
        return arrayOfNonNull(this.recipientId, this.userId)
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<NewUserNotificationCopyOptions> = {},
    ): NewUserNotification {
        return new NewUserNotification({
            // Recipient ID

            recipientId: "recipientId" in options
                ? options.recipientId
                : this.recipientId,

            // Content

            userId: "userId" in options
                ? options.userId
                : this.userId,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
