import { ForwardedRef, forwardRef } from "react"
import { Provider } from "model"
import { DeepReadonly } from "my-util"
import { ALL_PROVIDERS_PAGE_PATH } from "ui/page/sections/providers/AllProvidersPage/path"
import { Link, LinkProps } from "ui/ui"

export interface ProviderLinkProps extends Omit<LinkProps, "to"> {
    provider: Provider

    showCompany?: boolean
}

const ProviderLink = forwardRef((
    props: DeepReadonly<ProviderLinkProps>,
    ref: ForwardedRef<HTMLAnchorElement>,
) => {
    const {
        provider,
        showCompany,
        text, children,
    } = props

    return <Link { ...props }
                 to={ALL_PROVIDERS_PAGE_PATH}
                 ref={ref}>
        {children ?? text ?? (showCompany ? provider.anyCompany ?? provider.name : provider.name)}
    </Link>
})

ProviderLink.displayName = "ProviderLink"

export default ProviderLink
