import AbstractModelObject,
       { AbstractModelObjectCopyOptions,
         AbstractModelObjectCreationOptions } from "model/AbstractModelObject"

import { collapseWhiteSpace, DeepReadonly, map, Nullish, tryNormalizeUuid } from "my-util"
import type RoutePointType from "./RoutePointType"

export interface RoutePointOptionsBase {
    country: string
    type: RoutePointType
    documentIds?: Iterable<string> | null
}

export interface RoutePointCreationOptions
    extends
        AbstractModelObjectCreationOptions,
        RoutePointOptionsBase
{}

export interface RoutePointCopyOptions
    extends
        AbstractModelObjectCopyOptions,
        Nullish<RoutePointOptionsBase>
{}

export default class RoutePoint extends AbstractModelObject<RoutePointCopyOptions> {
    static createOrPass(arg: RoutePoint | DeepReadonly<RoutePointCreationOptions>): RoutePoint {
        return arg instanceof RoutePoint
            ? arg
            : new RoutePoint(arg)
    }

    // Fields

    readonly country: string
    readonly type: RoutePointType
    readonly documentIds: string[]

    // Constructor

    constructor(options: DeepReadonly<RoutePointCreationOptions>) {
        super(options)

        this.country = collapseWhiteSpace(options.country)
        this.type = options.type
        this.documentIds = map(options.documentIds ?? [], tryNormalizeUuid)
    }

    // Copy

    protected override createCopy(options: DeepReadonly<RoutePointCopyOptions> = {}): RoutePoint {
        return new RoutePoint({
            // Point

            country: options.country ?? this.country,
            type: options.type ?? this.type,
            documentIds: options.documentIds ?? this.documentIds,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
