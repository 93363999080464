import { isDigits, removeWhiteSpace } from "my-util"
import ViolationType from "./ViolationType"

export const LEGAL_ITN_LENGTH = 10

export function validateLegalItn(itn: string): ViolationType | null {
    itn = removeWhiteSpace(itn)

    if (itn.length > LEGAL_ITN_LENGTH)
        return "too-long"

    if (itn.length < LEGAL_ITN_LENGTH)
        return "too-short"

    if (!isDigits(itn))
        return "illegal-char"

    return null
}
