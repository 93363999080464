import AbstractModelObject,
      { AbstractModelObjectCopyOptions,
        AbstractModelObjectCreationOptions } from "model/AbstractModelObject"

import { collapseWhiteSpace, DeepReadonly, map } from "my-util"
import { normalizeCnfeaCode } from "normalize"

export interface ProductOptionsBase {
    name?: string | null
    cnfeaCodes?: Iterable<string> | null
}

export interface ProductCreationOptions
    extends
        AbstractModelObjectCreationOptions,
        ProductOptionsBase
{}

export interface ProductCopyOptions
    extends
        AbstractModelObjectCopyOptions,
        ProductOptionsBase
{}

export default class Product extends AbstractModelObject<ProductCopyOptions> {
    static createOrPass(
        arg?: Product
            | DeepReadonly<ProductCreationOptions>
            | null,
    ): Product {
        return arg instanceof Product
            ? arg
            : new Product(arg ?? {})
    }

    // Fields

    readonly name: string
    readonly cnfeaCodes: ReadonlySet<string>

    // Constructor

    constructor(options: DeepReadonly<ProductCreationOptions> = {}) {
        super(options)

        this.name = collapseWhiteSpace(options.name ?? "")
        this.cnfeaCodes = new Set(map(options.cnfeaCodes ?? [], normalizeCnfeaCode))
    }

    // Copy

    protected override createCopy(options: DeepReadonly<ProductCopyOptions> = {}): Product {
        return new Product({
            // Info

            name: options.name ?? this.name,
            cnfeaCodes: options.cnfeaCodes ?? this.cnfeaCodes,

            // Basic

            id: options.id ?? this.id,
            createdAt: options.createdAt ?? this.createdAt,
            modifiedAt: options.modifiedAt ?? this.modifiedAt,
        })
    }
}
