import Copyable from "model/Copyable"

import { collapseWhiteSpaceToNull, createOrCopyDate, ReadonlyDate,
         DeepReadonly, Immutable, IsImmutable, tryNormalizeNullableUuid } from "my-util"

import Money, { MoneyCreationOptions, MoneyCopyOptions } from "./Money"

export interface CurrencyRateCreationOptions {
    moment?: Date | null
    money?: Money | MoneyCreationOptions | null
    evidenceId?: string | null
    evidenceLink?: string | null
}

export interface CurrencyRateCopyOptions extends Omit<CurrencyRateCreationOptions, "money"> {
    money?: Money | MoneyCopyOptions | null
}

export default class CurrencyRate
    extends
        Copyable<CurrencyRateCopyOptions>

    implements
        Immutable
{
    static createOrPass(
        arg?: CurrencyRate
            | DeepReadonly<CurrencyRateCreationOptions>
            | null,
    ): CurrencyRate {
        return arg instanceof CurrencyRate
            ? arg
            : new CurrencyRate(arg ?? {})
    }

    // Fields

    readonly [IsImmutable] = true

    readonly moment: ReadonlyDate
    readonly money: Money
    readonly evidenceId: string | null
    readonly evidenceLink: string | null

    // Constructor

    constructor(options: DeepReadonly<CurrencyRateCreationOptions> = {}) {
        super()

        this.moment = createOrCopyDate(options.moment)
        this.money = Money.createOrPass(options.money)
        this.evidenceId = tryNormalizeNullableUuid(options.evidenceId)
        this.evidenceLink = collapseWhiteSpaceToNull(options.evidenceLink ?? "")
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<CurrencyRateCopyOptions> = {},
    ): CurrencyRate {
        return new CurrencyRate({
            moment: options.moment ?? this.moment,
            money: this.money.copyOrPass(options.money),

            evidenceId: "evidenceId" in options
                ? options.evidenceId
                : this.evidenceId,

            evidenceLink: "evidenceLink" in options
                ? options.evidenceLink
                : this.evidenceLink,
        })
    }
}
