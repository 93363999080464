import { Expirable, ExpirableFields, WithCreationMoment } from "model/interfaces"
import { Constructor, DeepReadonly, Duration, millisToDuration } from "my-util"
import ExpirableMixin from "./ExpirableMixin"

export default function ExpirableWithCreationDateMixin<
    BaseConstructor extends Constructor<DeepReadonly<ExpirableFields & WithCreationMoment>>
>(Base: BaseConstructor) {
    return class
        extends ExpirableMixin(Base)
        implements DeepReadonly<Expirable & WithCreationMoment>
    {
        get duration(): Duration {
            return millisToDuration(this.durationMillis)
        }

        get durationMillis(): number {
            return this.expiresAt.getTime() - this.createdAt.getTime()
        }
    }
}
