import { ForwardedRef, forwardRef } from "react"
import { getTransferGroupColors, getTransferGroupName, TransferGroup } from "model"
import Badge from "ui/ui/Badge"

export interface TransferGroupBadgeProps {
    group: TransferGroup
}

const TransferGroupBadge = forwardRef((
    { group }: Readonly<TransferGroupBadgeProps>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    const colors = getTransferGroupColors(group)

    return <Badge { ...colors }
                  ref={ref}>
        {getTransferGroupName(group)}
    </Badge>
})

TransferGroupBadge.displayName = "TransferGroupBadge"

export default TransferGroupBadge
