import getSymbolFromCurrency from "currency-symbol-map"
import Decimal from "decimal.js"
import { ForwardedRef, forwardRef, Fragment, ReactNode, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { fileIconUrl } from "image"
import { getLang } from "i18n"
import { createDocumentDownloadUrlById, getDocumentById } from "api"
import { Money, Transfer, User, UserRole, Document, getTransferGroup } from "model"

import { DeepReadonly, ReadonlyDate, ZERO_DECIMAL,
         formatDecimal, dateToDateString, Comparator} from "my-util"

import { createUserPagePath } from "ui/page/sections/users/UserPage/path"
import { UserLink } from "ui/component/user"

import { Icon, Clickable, Tooltip, UserRoleAbbr, ErrorDisplay, Flex,
         TransferDirectionIcon, TransferGroupColorLegend, Loading, Link } from "ui/ui"

import style from "./style.module.css"

export interface TransferTableProps {
    onTransferClick?: (transfer: Transfer) => void
    transfers: Transfer[]

    showGroups?: boolean
    showAgents?: boolean

    users?: Map<string, User> | Iterable<User>

    createFirstRowId?: (transfer: Transfer) => string

    width?: string

    comparator?: Comparator<Transfer>
    toSorted?: (users: readonly Transfer[]) => Transfer[]
}

const TransferTable = forwardRef((
    {
        onTransferClick, transfers,
        showGroups, showAgents,
        users,
        createFirstRowId,
        width,
        comparator, toSorted,
    }: DeepReadonly<TransferTableProps>,
    ref: ForwardedRef<HTMLTableElement>,
) => {
    const [t] = useTranslation()

    const lang = getLang()

    // State

    const [documentsById, setDocumentsById] = useState(new Map<string, Document>())
    const [loadingDocumentIds, setLoadingDocumentIds] = useState(new Set<string>())
    const [documentLoadingErrorsById, setDocumentLoadingErrorsById] = useState(new Map<string, unknown>())

    const usersById = useMemo(() => User.groupByIdOrPassOrCreate(users), [users])

    const innerTransfers = useMemo(
        () => {
            if (toSorted != null)
                return toSorted(transfers)

            if (comparator != null)
                return ([...transfers]).sort(comparator)

            return ([...transfers]).sort((lhs, rhs) => {
                if (lhs.creatorId == null && rhs.creatorId == null)
                    return lhs.country.localeCompare(rhs.country)

                if (lhs.creatorId == null)
                    return -1

                if (rhs.creatorId == null)
                    return 1

                const lhsCreator = usersById.get(lhs.creatorId)
                const rhsCreator = usersById.get(rhs.creatorId)

                if (lhsCreator == null && rhsCreator == null)
                    return lhs.createdAt.getTime() - rhs.createdAt.getTime()

                if (lhsCreator == null)
                    return -1

                if (rhsCreator == null)
                    return 1

                const lhsApplicant = lhsCreator.company.name ?? lhsCreator.name
                const rhsApplicant = rhsCreator.company.name ?? rhsCreator.name

                return lhsApplicant.localeCompare(rhsApplicant)
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [usersById, transfers, comparator, toSorted, lang],
    )

    // Render

    return <table className={style.table}
                  width={width}
                  ref={ref}>
        <tbody>
            {innerTransfers.map(transfer =>
                showAgents
                    ? renderWithAgent(transfer)
                    : renderWithoutAgent(transfer)
            )}
        </tbody>
    </table>

    function renderWithAgent(transfer: Transfer): ReactNode {
        const onRowClick = () => onTransferClick?.(transfer)

        const creator = transfer.creatorId != null
            ? usersById.get(transfer.creatorId)
            : undefined

        const agent = creator?.creatorId != null
            ? usersById.get(creator.creatorId)
            : undefined

        const documentIds = transfer.allDocumentIds

        return <Fragment key={transfer.id}>
            {/* First row */}

            <tr id={createFirstRowId?.(transfer)}
                className={style.firstRow}
                onClick={onRowClick}>
                {showGroups &&
                    <td>{renderTransferGroup(transfer)}</td>
                }

                <td colSpan={3}>
                    {renderUserCompany(creator)}
                </td>

                <td>{renderDirectionWithCountry(transfer)}</td>

                <td className={style.moment}>
                    {renderMoment(transfer.moment)}
                </td>
            </tr>

            {/* Second row */}

            <tr className={style.secondRow}
                onClick={onRowClick}>
                {showGroups &&
                    <td className={style.client}/>
                }

                <td className={style.bigText + " " + style.client}>
                    {renderUserRole("client")}
                </td>

                <td className={style.client}>
                    {renderCreator(creator)}
                </td>

                <td className={style.client}/>
                <td>{renderMoney(transfer.money)}</td>
                <td>{renderCost(transfer)}</td>
            </tr>

            {/* Third row */}

            <tr className={style.thirdRow}
                onClick={onRowClick}>
                {showGroups &&
                    <td className={style.agent}/>
                }

                <td className={style.bigText + " " + style.agent}>
                    {renderUserRole("agent")}
                </td>

                <td className={style.agent}>
                    {renderAgent(agent)}
                </td>

                <td className={style.agent}>
                    {renderAgentPercent(transfer.agentPercent)}
                </td>

                <td>{renderCurrencyRate(transfer)}</td>
                <td/>
            </tr>

            {/* Fourth row */}

            <tr className={style.fourthRow}
                onClick={onRowClick}>
                {showGroups &&
                    <td className={style.documents}/>
                }

                <td className={style.documents}/>

                <td className={style.documents}
                    colSpan={2}>
                    {renderDocuments(documentIds)}
                </td>

                <td colSpan={2}/>
            </tr>

            {/* Gap */}

            <tr className={style.gap}/>
        </Fragment>
    }

    function renderWithoutAgent(transfer: Transfer): ReactNode {
        const onRowClick = () => onTransferClick?.(transfer)

        return <Fragment key={transfer.id}>
            {/* First row */}

            <tr id={createFirstRowId?.(transfer)}
                className={style.firstRow}
                onClick={onRowClick}>
                {showGroups &&
                    <td>{renderTransferGroup(transfer)}</td>
                }

                <td>{renderDirectionWithCountry(transfer)}</td>
                <td>{t("domain.transfers.labels.date") + ":"}</td>
            </tr>

            {/* Second row */}

            <tr className={style.secondRow}
                onClick={onRowClick}>
                {showGroups &&
                    <td className={style.agent}/>
                }

                <td className={style.agent}>
                    {renderMoney(transfer.money)}
                </td>

                <td className={style.momentLabel}>
                    {renderMoment(transfer.moment)}
                </td>
            </tr>

            {/* Third row */}

            <tr className={style.thirdRow}
                onClick={onRowClick}>
                {showGroups &&
                    <td className={style.documents}/>
                }

                <td className={style.documents}>
                    {renderDocuments(transfer.allDocumentIds)}
                </td>

                <td/>
            </tr>

            {/* Gap row */}

            <tr className={style.gap}/>
        </Fragment>
    }

    // - Parts

    function renderUserCompany(user?: User | null): ReactNode {
        if (user == null)
            return null

        const name = user.company?.anyName

        if (name == null)
            return t("domain.company.messages.notSet")

        return <Link to={createUserPagePath(user.id)}
                     text={name}
                     stopClickPropagation/>
    }

    function renderTransferGroup(transfer: Transfer): ReactNode {
        return <Flex width="16px">
            <TransferGroupColorLegend group={getTransferGroup(transfer)}/>
        </Flex>
    }

    function renderDirectionWithCountry(transfer: Transfer): ReactNode {
        return <Flex direction="horizontal"
                     gap="4px">
            <div className={style.directionIcon}>
                <TransferDirectionIcon direction={transfer.direction}
                                       width="8px"
                                       height="20px"/>
            </div>

            {transfer.country}
        </Flex>
    }

    function renderUserRole(role: UserRole) {
        return <UserRoleAbbr role={role}
                             colorful/>
    }

    function renderCreator(creator?: User | null): ReactNode {
        return renderUser(creator)
    }

    function renderAgent(agent?: User | null): ReactNode {
        return renderUser(agent)
    }

    function renderUser(user?: User | null): ReactNode {
        return user != null
            ? <UserLink stopClickPropagation
                        user={user}/>

            : "-"
    }

    function renderAgentPercent(percent?: Decimal | null): ReactNode {
        return `${formatDecimal(percent ?? ZERO_DECIMAL, 4)}%`
    }

    function renderMoney(money: DeepReadonly<Money>): ReactNode {
        return <>
            {getSymbolFromCurrency(money.currency)}
            {" "}
            {formatDecimal(money.amount)}
        </>
    }

    function renderMoment(moment?: ReadonlyDate | null): ReactNode {
        return moment != null
            ? dateToDateString(moment)
            : t("domain.transfers.messages.dateNotSet")
    }

    function renderCurrencyRate(transfer: Transfer): ReactNode {
        const { currencyRate } = transfer

        if (currencyRate == null)
            return

        return <>
            {currencyRate.money.toString(4)}
            {"/"}
            {getSymbolFromCurrency(transfer.money.currency)}
        </>
    }

    function renderCost(transfer: Transfer): ReactNode {
        return transfer.cost?.toString(4)
    }

    function renderDocuments(documentIds: readonly string[]): ReactNode {
        return <Flex direction="horizontal"
                     wrap>
            {documentIds.map(id =>
                <div style={{ position: "relative" }}
                     key={id}>
                    <Clickable stopPropagation>
                        {renderDocument(id)}
                    </Clickable>

                    <Tooltip onShow={shown => onShow(id, shown)}>
                        {renderTooltipDocument(id)}
                    </Tooltip>
                </div>
            )}
        </Flex>

        function renderDocument(documentId: string): ReactNode {
            const document = documentsById.get(documentId)

            if (document == null)
                return renderDocumentIcon()

            return <Link to={createDocumentDownloadUrlById(document.id)}
                         download={document.name}
                         target="_blank">
                {renderDocumentIcon()}
            </Link>
        }

        function renderDocumentIcon(): ReactNode {
            return <Icon height="20px"
                         width="fit-content"

                         src={fileIconUrl}
                         alt="File icon"
                         filter="brightness(0)"/>
        }

        function renderTooltipDocument(documentId: string): ReactNode {
            const document = documentsById.get(documentId)

            if (document != null)
                return document.name

            const error = documentLoadingErrorsById.get(documentId)

            if (error != null)
                return <ErrorDisplay centerType="flex"
                                     error={error}/>

            return <Loading centerType="flex"/>
        }

        async function onShow(documentId: string, shown: boolean) {
            if (!shown ||
                documentsById.has(documentId) ||
                loadingDocumentIds.has(documentId) ||
                documentLoadingErrorsById.has(documentId))
                return

            setLoadingDocumentIds(oldLoadingDocumentIds => {
                const newLoadingDocumentIds = new Set(oldLoadingDocumentIds)
                newLoadingDocumentIds.add(documentId)
                return newLoadingDocumentIds
            })

            try {
                const document = await getDocumentById(documentId)

                setDocumentsById(oldDocumentsById => {
                    const newDocumentsById = new Map(oldDocumentsById)
                    newDocumentsById.set(document.id, document)
                    return newDocumentsById
                })
            } catch (error) {
                setDocumentLoadingErrorsById(oldDocumentLoadingErrorsById => {
                    const newDocumentLoadingErrorsById = new Map(oldDocumentLoadingErrorsById)
                    newDocumentLoadingErrorsById.set(documentId, error)
                    return newDocumentLoadingErrorsById
                })
            } finally {
                setLoadingDocumentIds(oldLoadingDocumentIds => {
                    const newLoadingDocumentIds = new Set(oldLoadingDocumentIds)
                    newLoadingDocumentIds.delete(documentId)
                    return newLoadingDocumentIds
                })
            }
        }
    }
})

TransferTable.displayName = "TransferTable"

export default TransferTable
