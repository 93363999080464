import { ForwardedRef, forwardRef } from "react"
import { getInviteStatusColors, InviteStatus, getInviteStatusName } from "model"
import Badge from "ui/ui/Badge"

export interface InviteStatusBadgeProps {
    status: InviteStatus
}

const InviteStatusBadge = forwardRef((
    { status }: Readonly<InviteStatusBadgeProps>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    const colors = getInviteStatusColors(status)

    return <Badge { ...colors }
                  ref={ref}>
        {getInviteStatusName(status)}
    </Badge>
})

InviteStatusBadge.displayName = "InviteStatusBadge"

export default InviteStatusBadge
