import { ForwardedRef, forwardRef, ReactNode } from "react"
import { DeepReadonly } from "my-util"
import style from "./style.module.css"

export interface GroupProps {
    children?: ReactNode

    width?: string
    height?: string
}

const Group = forwardRef((
    {
        children,
        width, height,
    }: DeepReadonly<GroupProps>,
    ref: ForwardedRef<HTMLDivElement>,
) =>
    <div style={{ width, height }}
         className={style.Group}
         ref={ref}>
        {children}
    </div>
)

Group.displayName = "Group"

export default Group
