import { collapseWhiteSpace } from "my-util"
import ViolationType from "./ViolationType"

export const MAX_MEDIUM_TEXT_LENGTH = 65_535

export function validateMediumText(text: string): ViolationType | null {
    text = collapseWhiteSpace(text)

    if (text.length > MAX_MEDIUM_TEXT_LENGTH)
        return "too-long"

    return null
}
