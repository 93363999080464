import { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { EARTH_ROUTE_POINT_TYPE, getRoutePointTypeName, RoutePointType } from "model"
import { DeepReadonly, isBlank } from "my-util"
import { MAX_SHORT_TEXT_LENGTH, validateShortText, ViolationType } from "validation"
import { useStateWithDeps } from "ui/hook"
import { copyRoutePointFields, RoutePointFields } from "ui/fields"

import { copyUiDocument, UiDocument,
         isFieldDocument, DocumentListUpload } from "ui/component/document"

import { Flex, Group, Input, Label, OptionallyRequired, Output, RoutePointTypeSelect } from "ui/ui"
import RoutePointViolations from "./RoutePointViolations"

export interface RoutePointEditorProps {
    onValidate?: (violations: RoutePointViolations) => void
    onChange?: (value: RoutePointFields) => void
    value?: RoutePointFields | null

    noDocumentDelete?: boolean

    loading?: boolean
    disabled?: boolean
    readonly?: boolean
    required?: boolean
    output?: boolean

    width?: string
}

const RoutePointEditor = forwardRef((
    {
        onValidate, onChange, value,
        noDocumentDelete,
        loading, disabled, readonly, required, output,
        width,
    }: DeepReadonly<RoutePointEditorProps>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    // Refs

    const valueChangedRef = useRef(true)
    const violationsChangedRef = useRef(true)

    // State

    const [country, setCountry] = useStateWithDeps(
        () => value?.country ?? "",
        [value?.id],
    )

    const [touchedCountry, setTouchedCountry] = useState(false)

    const [type, setType] = useStateWithDeps(
        () => value?.type ?? EARTH_ROUTE_POINT_TYPE,
        [value?.id],
    )

    const [documents, setDocuments] = useStateWithDeps<UiDocument[]>(
        () => value?.documents?.map(copyUiDocument) ?? [],
        [value?.id],
    )

    const [violations, setViolations] = useState(new RoutePointViolations({
        country: validateCountry(country),
    }))

    // Effects

    // - Value propagation

    useEffect(() => {
        if (!valueChangedRef.current)
            return

        valueChangedRef.current = false

        if (onChange == null || !violations.isValid)
            return

        const newValue: RoutePointFields = value != null
            ? {
                ...copyRoutePointFields(value),
                documents: documents.filter(isFieldDocument),
                country, type,
            }

            : {
                documents: documents.filter(isFieldDocument),
                country, type,
            }

        onChange(newValue)
    }, [onChange, value, country, type, documents, violations.isValid])

    // - Violations propagation

    useEffect(() => {
        if (violationsChangedRef.current) {
            violationsChangedRef.current = false
            onValidate?.(violations)
        }
    }, [onValidate, violations])

    // Render

    const immutable = readonly || output
    const showDocuments = !immutable || documents.length > 0

    return <Group width={width}
                  ref={ref}>
        <Flex>
            <Flex direction="horizontal">
                <Flex direction="horizontal">
                    <OptionallyRequired required={required}>
                        <Label text={t("domain.routePoints.labels.country")}/>
                    </OptionallyRequired>

                    {output
                        ? <Output>{country}</Output>

                        : <Input placeholder={t("domain.routePoints.placeholders.country")}

                                 onChange={onCountryChange}
                                 value={country}

                                 onFocus={() => setTouchedCountry(true)}

                                 max={MAX_SHORT_TEXT_LENGTH}

                                 invalid={touchedCountry && violations.country != null}
                                 loading={loading}
                                 disabled={disabled}
                                 readonly={readonly}/>
                    }
                </Flex>

                <Flex direction="horizontal">
                    <Label text={t("domain.routePoints.labels.type")}/>

                    {output
                        ? <Output>{getRoutePointTypeName(type)}</Output>

                        : <RoutePointTypeSelect onSelect={onTypeChange}
                                                selected={type}

                                                loading={loading}
                                                disabled={disabled}
                                                readonly={readonly}/>
                    }
                </Flex>
            </Flex>

            {showDocuments &&
                <DocumentListUpload onChange={onDocumentsChange}
                                    documents={documents}

                                    buttonText={t("domain.routePoints.buttons.addDocuments")}

                                    disabled={disabled}
                                    readonly={immutable}
                                    noDelete={noDocumentDelete}
                                    multiple/>
            }
        </Flex>
    </Group>

    // Events

    function onCountryChange(newCountry: string) {
        setViolations(oldViolations => {
            violationsChangedRef.current = true
            return oldViolations.copy({ country: validateCountry(newCountry) })
        })

        setCountry(() => {
            valueChangedRef.current = true
            return newCountry
        })
    }

    function onTypeChange(newType: RoutePointType) {
        setType(() => {
            valueChangedRef.current = true
            return newType
        })
    }

    function onDocumentsChange(newDocuments: UiDocument[]) {
        setDocuments(() => {
            valueChangedRef.current = true
            return newDocuments
        })
    }

    // Util

    function validateCountry(country: string): ViolationType | null {
        return required && isBlank(country)
            ? "too-short"
            : validateShortText(country)
    }
})

RoutePointEditor.displayName = "RoutePointEditor"

export default RoutePointEditor
