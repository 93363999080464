import { Copyable } from "model"
import { DeepReadonly, every, Immutable, IsImmutable } from "my-util"

import { ProductViolations,
         ProductViolationsCopyOptions,
         ProductViolationsCreationOptions } from "../ProductEditor"

export interface ProductListViolationsOptionsBase {
    deleteValid?: boolean
}

export interface ProductListViolationsCreationOptions extends ProductListViolationsOptionsBase {
    violationsByProductId?: Map<string, ProductViolations | ProductViolationsCreationOptions> | null
}

export interface ProductListViolationsCopyOptions extends ProductListViolationsOptionsBase {
    violationsByProductId?: Map<string, ProductViolations | ProductViolationsCopyOptions> | null
}

export default class ProductListViolations
    extends
        Copyable<ProductListViolationsCopyOptions>

    implements
        Immutable
{
    static createOrPass(
        arg?: ProductListViolations
            | DeepReadonly<ProductListViolationsCreationOptions>
            | null,
    ): ProductListViolations {
        return arg instanceof ProductListViolations
            ? arg
            : new ProductListViolations(arg ?? {})
    }

    // Fields

    readonly [IsImmutable] = true

    readonly violationsByProductId: ReadonlyMap<string, ProductViolations>

    private cachedIsValid: boolean | null = null

    // Constructor

    constructor(options: DeepReadonly<ProductListViolationsCreationOptions> = {}) {
        super()

        const newViolationsByProductId = new Map<string, ProductViolations>()

        if (options.violationsByProductId != null)
            for (const [id, violations] of options.violationsByProductId)
                newViolationsByProductId.set(id, ProductViolations.createOrPass(violations))

        if (options.deleteValid) {
            const idsToDelete: string[] = []

            for (const [id, violations] of newViolationsByProductId.entries())
                if (violations.isValid)
                    idsToDelete.push(id)

            for (const id of idsToDelete)
                newViolationsByProductId.delete(id)

            this.cachedIsValid = newViolationsByProductId.size === 0
        }

        this.violationsByProductId = newViolationsByProductId
    }

    // Validity check

    get isValid(): boolean {
        if (this.cachedIsValid == null)
            this.cachedIsValid = every(
                this.violationsByProductId.values(),
                violations => violations.isValid,
            )

        return this.cachedIsValid
    }

    // With

    withNew(productId: string, violations: ProductViolations): ProductListViolations {
        const newViolationsByProductId = new Map(this.violationsByProductId)

        newViolationsByProductId.set(productId, violations)

        return this.copy({ violationsByProductId: newViolationsByProductId })
    }

    withDeleted(productId: string): ProductListViolations {
        const newViolationsByProductId = new Map(this.violationsByProductId)

        newViolationsByProductId.delete(productId)

        return this.copy({ violationsByProductId: newViolationsByProductId })
    }

    withoutValid(): ProductListViolations {
        return this.copy({ deleteValid: true })
    }

    // Copy

    protected override createCopy(
        options: DeepReadonly<ProductListViolationsCopyOptions> = {},
    ): ProductListViolations {
        return new ProductListViolations({
            violationsByProductId: options.violationsByProductId ?? this.violationsByProductId,
        })
    }
}
