import { ForwardedRef, forwardRef } from "react"
import { getUserStatusColors, UserStatus, getUserStatusName } from "model"
import Badge from "ui/ui/Badge"

export interface UserStatusBadgeProps {
    status: UserStatus
}

const UserStatusBadge = forwardRef((
    { status }: Readonly<UserStatusBadgeProps>,
    ref: ForwardedRef<HTMLSpanElement>,
) => {
    const colors = getUserStatusColors(status)

    return <Badge { ...colors }
                  ref={ref}>
        {getUserStatusName(status)}
    </Badge>
})

UserStatusBadge.displayName = "UserStatusBadge"

export default UserStatusBadge
