import { getLang } from "i18n"
import Copyable from "model/Copyable"
import { collapseWhiteSpaceToNull, Immutable, IsImmutable, removeWhiteSpaceToNull } from "my-util"

export interface BankCreationOptions {
    ruName?: string | null
    enName?: string | null

    ruAddress?: string | null
    enAddress?: string | null

    swift?: string | null
    itn?: string | null
    bic?: string | null
}

export interface BankCopyOptions extends BankCreationOptions {}

export default class Bank
    extends
        Copyable<BankCopyOptions>

    implements
        Immutable
{
    static createOrPass(arg?: Bank | Readonly<BankCreationOptions> | null): Bank {
        return arg instanceof Bank
            ? arg
            : new Bank(arg ?? {})
    }

    // Fields

    readonly [IsImmutable] = true

    readonly itn: string | null

    readonly ruName: string | null
    readonly enName: string | null

    readonly ruAddress: string | null
    readonly enAddress: string | null

    readonly swift: string | null
    readonly bic: string | null

    // Constructor

    constructor(options: Readonly<BankCreationOptions> = {}) {
        super()

        this.itn = removeWhiteSpaceToNull(options.itn ?? "")

        this.ruName = collapseWhiteSpaceToNull(options.ruName ?? "")
        this.enName = collapseWhiteSpaceToNull(options.enName ?? "")

        this.ruAddress = collapseWhiteSpaceToNull(options.ruAddress ?? "")
        this.enAddress = collapseWhiteSpaceToNull(options.enAddress ?? "")

        this.swift = removeWhiteSpaceToNull(options.swift ?? "")?.toUpperCase() ?? null
        this.bic = removeWhiteSpaceToNull(options.bic ?? "")
    }

    // Name

    get anyName(): string | null {
        return getLang() === "ru"
            ? this.ruName ?? this.enName
            : this.enName ?? this.ruName
    }

    get name(): string | null {
        return getLang() === "ru"
            ? this.ruName
            : this.enName
    }

    // Address

    get anyAddress(): string | null {
        return getLang() === "ru"
            ? this.ruAddress ?? this.enAddress
            : this.enAddress ?? this.ruAddress
    }

    get address(): string | null {
        return getLang() === "ru"
            ? this.ruAddress
            : this.enAddress
    }

    // Copy

    protected override createCopy(options: Readonly<BankCopyOptions> = {}): Bank {
        return new Bank({
            // ITN

            itn: "itn" in options
                ? options.itn
                : this.itn,

            // Name

            ruName: "ruName" in options
                ? options.ruName
                : this.ruName,

            enName: "enName" in options
                ? options.enName
                : this.enName,

            // Address

            ruAddress: "ruAddress" in options
                ? options.ruAddress
                : this.ruAddress,

            enAddress: "enAddress" in options
                ? options.enAddress
                : this.enAddress,

            // SWIFT

            swift: "swift" in options
                ? options.swift
                : this.swift,

            // BIC

            bic: "bic" in options
                ? options.bic
                : this.bic,
        })
    }
}
